import tokenActions, { AccessItem } from '../../../actions/tokenActions';
import { RecordStatus, TableRecord } from '../../canvas_table/components/TableRecord';
import { getTableColumnKey } from '../getTableColumnKey';
import { getWData } from '../getWData';
import { getPassportCache } from '../loadData';

export function checkOpenModalInvoice(col: number, row: number, currentRow: TableRecord) {
  const columnKey = getTableColumnKey(col, getWData().body);
  const columns: (keyof TableRecord)[] = [
    'cns_invoice_count',
    'cns_invoice_date',
    'cns_invoice_size',
    'cns_invoice_price',
    'cns_invoice_sum',
    'cns_invoice_status',
  ];

  return (
    (getPassportCache().status == 'active' &&
      columns.includes(columnKey) &&
      (tokenActions.invoices === AccessItem.EDIT || tokenActions.invoices === AccessItem.VIEW) &&
      currentRow?.cns_row_type == 'nomenclature' &&
      currentRow?.cns_status === RecordStatus.BUDGET_PLAN_APPROVED) ??
    false
  );
}
