import moment from "moment/moment";
import {ContractsProjectStatusType} from "../../../actions/useContractsProject";
import {Setting} from "../../../ApiEpo";
import {SignatureResponse} from "../type/types";

export function formatDateCard(date: string | undefined) {
  if (!date) return '';
  return moment(date).format('DD.MM.YYYY в HH:mm');
}
export function helperSignatures(status: ContractsProjectStatusType | undefined | string, setting: Setting | undefined): SignatureResponse {
  switch (status) {
    case 'on_work':
      return {
        status: 'on_approval_by_spm',
        message: 'Успешно согласован СРП'
      };
    case 'on_approval_by_spm':
      // on_approval_by_spm -> on_approval_by_dk если включена настройка
      // on_approval_by_spm -> signing если выключена настройка
      const isApprovalBk = (Boolean(setting?.value === '1'));
      return {
        status: isApprovalBk ? 'on_approval_by_dk' : 'signing',
        message: isApprovalBk ? 'Успешно согласован ХД' : 'Успешно подписан РП'
      }
    case 'on_approval_by_dk':
      return {
        status: 'signing',
        message: 'Успешно подписан РП'
      }
    case 'signing':
      return {
        status: 'signed',
        message: 'Успешно подписан'
      }
    default:
      throw new Error(`Invalid status: ${status}`);
  }
}

export function helperParserSignatures(status: ContractsProjectStatusType | undefined | string) {
  switch (status) {
    case 'on_approval_by_spm':
      return {
        status,
        value: 'Согласован СРП'
      }
    case 'on_approval_by_dk':
      return {
        status,
        value: 'Согласован ХД'
      }
    case 'signing':
      return {
        status,
        value: 'Подписан РП'
      }
    case 'rejected':
      return {
        status,
        value: 'Отклонен'
      }
    default:
      throw new Error(`Invalid status: ${status}`);
  }
}
