import {directoryApi} from '../shared/api_client';
import {
  getContractorsDb,
  getCountContractorsDb,
  getFiltersDb,
  syncContragents,
  syncFiltersDb
} from "../indexedb";
import {useState} from "react";
import {ContractorShort} from "../ApiDirectory";
import {useDb} from "./useDb";

interface IContragentSearch {
  page?: number
  perPage?: number
  search?: string
  tags?: string[]
  locations?: string[]
}

export function useContragentsDb() {
  const {initDb} = useDb()
  const [isLoadingContragents, setIsLoadingContragents] = useState(false);
  const [pageContragents, setPageContragents] = useState<ContractorShort[]>([]);
  const [totalCountContragents, setTotalCountContragents] = useState(0);
  const [filterTypes, setFilterTypes] = useState<{ value: string, label: string }[]>([]);
  const [filterRating, setFilterRating] = useState<{ value: string, label: string }[]>([]);

  async function loadAllContragents() {
    setIsLoadingContragents(true);
    try {
      await initDb();

      const page = await directoryApi.contractors.contractorsList({per_page: 1000000000})

      await syncContragents(page.data.data ?? []);
      await syncFiltersDb({
        key: 'rating',
        items: (page.data.filters?.rating ?? [])
          .map((item) => ({value: `${item.value}`, label: `${item.label}`}))
      });
      await syncFiltersDb({
        key: 'types',
        items: (page.data.filters?.types ?? [])
          .map((item) => ({value: `${item.value}`, label: `${item.label}`}))
      });
    } catch (e) {
      console.log(e)
    }
    setIsLoadingContragents(false);
  }


  async function getAllContragents({page: number = 1, perPage = 10, search = '', tags = [], locations = []}: IContragentSearch) {
    const res = await getContractorsDb({page: number, perPage, search, tags, locations});

    setPageContragents(res);

    return res;
  }

  async function getFiltersTypes() {
    const res = await getFiltersDb('types');
    setFilterTypes(res);

    return res;
  }

  async function getFiltersRating() {
    const res = await getFiltersDb('rating');
    setFilterRating(res);

    return res;
  }

  async function getCountContragents({search = '', tags, locations}: IContragentSearch) {
    const res = await getCountContractorsDb({search, tags, locations});
    setTotalCountContragents(res);
  }

  return {
    isLoadingContragents,
    pageContragents,
    totalCountContragents,
    filterTypes,
    filterRating,

    loadAllContragents,
    getAllContragents,
    getCountContragents,
    getFiltersTypes,
    getFiltersRating,
  };
}
