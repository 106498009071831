import React, {FunctionComponent, useState, useEffect} from "react";
import {CNCard} from "./components/CNCard";
import {CNCardListProps, CNCardViewProps, TypeContract} from "./type";
import {useContractsList} from "../../actions/useContractsList";
import {ContractsProjectStatusType} from "../../actions/useContractsProject";
import {localeKeys} from "../../i18n/localeKeys";
import {useTranslation} from "react-i18next";
import {Pagination} from "antd";

// Просмотр контракта
export const CNCardView: FunctionComponent<CNCardViewProps> = ({ ...props }) => {
    // Pagination;
    const [perPage, setPerPage] = useState<number>(10)
    const [page, setPage] = useState<number>(1)
    const {t} = useTranslation();

    // Получить список контрактов;
    const {contractsList, contractsListError, contractsListLoading, refetch} = useContractsList(
        {
            project_id: props.project?.project_id ?? '',
            section_id: props.project?.section_id ?? '',
            status: props.status as ContractsProjectStatusType,
            page: page,
            per_page: perPage,
            search: props.searchValue
        }
    );

    const hasMeta = contractsList?.meta && (contractsList.meta?.total ?? 0) > perPage;

    const handleDownload = async (record: TypeContract) => {
        try {
            await props.onDownload?.(record);
        }catch (e) {
            console.log('handleDownload',e);
        }
    }

    const handleApprove = async (record: TypeContract) => {
        try {
            await props.onApprove?.(record);
        }catch (e) {
            console.log('approve error',e);
        }
    }

    const handleReject = async (record: any) => {
        try {
            await props.onReject?.(record);
        }catch (e) {
            console.log('Reject error',e);
        }
    }

    const handleUpload = async (record: any) => {
        try {
            await props.onUpload?.(record);
        }catch (e) {
            console.log('Reject error',e);
        }
    }

    useEffect(() => {
         if (props.refreshData) refetch();
    }, [props.refreshData]);


    if (contractsList?.data && contractsList?.data?.length > 0 && !contractsListError && !contractsListLoading) {
        return (
            <div className="cn-card-list">
                {contractsList?.data?.map((contract, key) => (
                    <CNCard
                        setting={props.setting}
                        agreed={key !== 0}
                        projectTitle={key  === 0 ? props.project?.project_name : undefined}
                        sectionTitle={key  === 0 ? props.project?.section_name : undefined}
                        key={key}
                        contract={contract}
                        onDownload={handleDownload}
                        onApprove={handleApprove}
                        onReject={handleReject}
                        onUpload={handleUpload}
                    />
                ))}
                {hasMeta ? <Pagination
                    className="fin-controller_table__pagination"
                    defaultCurrent={1}
                    total={contractsList?.meta?.total}
                    pageSize={perPage}
                    onChange={setPage}
                /> : null}

                {props.searchValue ?<div className={'search_result'}>{t('contractNegotiation.resultSearch')} «{props.searchValue}»</div> : ''}

            </div>
        )
    } else {
        return (
            <div className={'cn-empty-content'}>{contractsListLoading ? t(localeKeys.shared.loading) :  t(localeKeys.contragentsListScreen.noData)}</div>
        )
    }
}
