import React, {useState} from 'react';
import { Space, Button, Popconfirm, Tooltip } from 'antd';
import {
    IssuesCloseOutlined,
    StopOutlined,
    UploadOutlined,
    DeleteOutlined,
    EditOutlined,
    ContainerOutlined,
    ContainerFilled,
} from '@ant-design/icons';
import {useTranslation} from "react-i18next";
import {localeKeys} from "../../../i18n/localeKeys";
import {DTActionProps} from "../types";

export const GridAction: React.FC<DTActionProps> = ({
                                                        onDelete,
                                                        onEdit,
                                                        onArchive,
                                                        onView,
                                                        optionBtn,
                                                        record,
                                                        view

}) => {

    const {t} = useTranslation();

    return (
        <Space style={{ gap: 8 }} className={'action-grid'}>
                {!optionBtn?.hideEdit ?
                    <Tooltip title={!optionBtn?.disabledEdit ? t(localeKeys.tooltip.edit) : ''}>
                        <Button
                            loading={optionBtn?.loadingEdit && record?.id === view?.id}
                            disabled={optionBtn?.disabledEdit}
                            className={'brn-edit'}
                            icon={<EditOutlined />}
                            type="link"
                            onClick={onEdit}>
                        </Button>
                    </Tooltip>
                        : ''
                }
              {!optionBtn?.hideArchive ?
                  <Tooltip title={!optionBtn?.disabledArchive ? ( record?.status == 'archive' ? t(localeKeys.tooltip.unArchive) : t(localeKeys.tooltip.archive)) : ''}>
                    <Button
                        loading={optionBtn?.loadingArchive && record?.id === view?.id}
                        disabled={optionBtn?.disabledArchive}
                        onClick={onArchive}
                        className={'btn-archive'}
                        icon={ record?.status == 'archive' ? <ContainerFilled /> : <ContainerOutlined />}
                        type="link">
                    </Button>
                  </Tooltip>
                  : ''
                }
                {!optionBtn?.hideDelete ?
                    <Popconfirm
                        title={t(localeKeys.popConfirmDelete.title)}
                        description={t(localeKeys.popConfirmDelete.description)}
                        onConfirm={onDelete}
                    >
                        <Tooltip title={!optionBtn?.disabledDelete ? t(localeKeys.tooltip.delete) : ''}>
                            <Button
                                loading={optionBtn?.loadingDelete && record?.id === view?.id}
                                disabled={optionBtn?.disabledDelete}
                                className={'btn-delete'}
                                icon={<DeleteOutlined/>} type="link">
                            </Button>
                        </Tooltip>
                    </Popconfirm> : ''
                }
            </Space>
    );
};
