import { resetApi } from './api_client';
import parseJwt from './parseJwt';
import { getValueStore, removeValueStore, setValueStore } from './store';

export function checkTimeRefresh() {
  return getValueStore('refresh_token') && Number(getValueStore('time_token')) - new Date().getTime() / 1000 < 30;
}

export function checkTimeRefreshFull() {
  console.log(`Проверка на время жизни рефреш токена: ${checkTimeRefresh2()}`);
  if (checkTimeRefresh2()) {
    console.log('Не прошла проверка на время жизни рефреш токена');

    if (location.pathname !== '/auth/' && location.pathname !== '/auth') {
      /*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
      location.href = '/auth';
    }
  }
}

export function checkTimeRefresh2() {
  const json = parseJwt(getValueStore('refresh_token') ?? '');
  const exp = json.exp;
  const now = new Date().getTime() / 1000;
  return exp - now < 30;
}

export async function refreshTokenFun() {
  console.log('Refresh token');

  if (getValueStore('token_refresh_lock') == '1') {
    return new Promise((resolve, reject) => setTimeout(resolve, 5000));
  }

  if (checkTimeRefresh()) {
    setValueStore('token_refresh_lock', '1');

    try {
      const res = await fetch(process.env.REACT_APP_API_RBAC_API_URL + '/api/refresh-token', {
        headers: {
          authorization: `Bearer ${getValueStore('token')}`,
          'content-type': 'application/json',
        },
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          refresh_token: getValueStore('refresh_token'),
        }),
        method: 'POST',
      }).then((e) => e.json());

      setValueStore('token', res.access_token ?? '');
      setValueStore('refresh_token', res.refresh_token ?? '');
      setValueStore('time_token', `${res.expires_in}`);

      resetApi(res.access_token);
    } catch (e) {}

    removeValueStore('token_refresh_lock');
  }
}
