import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { getWData } from '../../../table_extends/getWData';
import { Button, Modal } from 'antd';
import { ProjectError } from '../../../../ApiEpo';
import { dateFormatter, numberFormatter } from '../../../../shared/dateFormat';
import { getLoadDataInfo, toggleSubscribeError } from '../../../table_extends/loadData';
import ModalErrorConfirm from './modal_error_confirm';
import {getErrorFieldPrefics, getTypeError, TypeModalEnum} from "../utils/getErrorFieldPrefics";
import {getEmptyTableRecord} from "../TableRecord";
import {ErrorTypeEnum} from "../utils/error_type";
import ModalErrorLines from "./modal_error_lines";
import { useTranslation } from 'react-i18next';
import { localeKeys } from '../../../../i18n/localeKeys';

const ModalErrorView: FunctionComponent<{
    isOpen: boolean,
    open: boolean,
    onOk: () => void,
    onCancel: () => void,
    onAction: (type: 'confirm' | 'cancel') => void,
    errors: ProjectError[],
    typeError: TypeModalEnum
}> = (props) => {
    const [refresh, setRefresh] = useState(0);
    const loadData = useMemo(() => getLoadDataInfo(), [props.isOpen, refresh]);

    useEffect(() => {
        console.log('ModalErrorView', props.errors);

        toggleSubscribeError(setRefresh)

        return () => {
            toggleSubscribeError(setRefresh)
        }
    }, [props.errors]);

    const isErrorWithCompleted = useMemo(() => {
        return props.errors.find(e => e.type == ErrorTypeEnum.CONTRACT_NOT_COMPLETED_ERROR)
    }, [props.errors]);

    const isErrorWithPlaneDates = useMemo(() => {
        return props.errors.find(e => e.type == ErrorTypeEnum.CONTRACT_PLANNING_DATE_ERROR)
    }, [props.errors]);

    const isErrorWithDates = useMemo(() => {
        return props.errors.find(e => e.type == ErrorTypeEnum.CONTRACT_WORK_START_DATE_ERROR || e.type == ErrorTypeEnum.CONTRACT_WORK_END_DATE_ERROR)
    }, [props.errors]);

    const isErrorSize = useMemo(() => {
        return props.errors.find(e => [ErrorTypeEnum.CONTRACT_OVER_ERROR, ErrorTypeEnum.INVOICE_OVER_ERROR, ErrorTypeEnum.WAYBILL_OVER_ERROR, ErrorTypeEnum.BILL_OVER_ERROR].includes(e.type as ErrorTypeEnum))
    }, [props.errors]);

    const nomenclature = useMemo(() => {
        const error = props.errors[0];
        return getWData().rows3.find(row => row.cns_nomenclature_id == error?.nomenclature_id) ?? getEmptyTableRecord()
    }, [props.errors]);

    const rowSection = getWData().rows.find(rowSection => rowSection.cns_section_id == nomenclature.cns_section_id);
    const rowWorkType = getWData().rows2.find(rowGroup => rowGroup.cns_group_id == nomenclature.cns_group_id);


    (window as any).modalErrorView = {
        ...props,
        isErrorWithDates,
        isErrorWithCompleted,
        isErrorSize,
    }

    const {t} = useTranslation();

    return <Modal title={loadData.passport?.title ?? t(localeKeys.errors.view.error)}
                  open={props.open}
                  onCancel={
                      () => {
                          props.onCancel();
                      }}
                  destroyOnClose={true}
                  footer={<></>}
                  className="modal-error-view"
    >
        {rowWorkType && <div className={'modal-error-view__subtitle'}>{rowSection?.cns_title} / {rowWorkType?.cns_title} &nbsp; </div>}

        <div className={'modal-error-view__title'}>{t(localeKeys.errors.view.budgetPlan)}</div>
        {loadData.rowsTable?.rows3
            ?.filter(row => row.cns_status == 'BUDGET_PLAN_APPROVED')
            ?.filter(row => props.errors.map(e => e.nomenclature_id).includes(row.cns_nomenclature_id ?? ''))
            .map(row => {
                return <div className={'modal-error-view__bp_line'} key={`modal-error-view__${row.cns_id}`}>
                    <span>{row.cns_title} &nbsp;</span>
                    <span>{numberFormatter(row.cns_budget_plan_size)} {row.cns_ed_izm}</span>
                    <span>× {numberFormatter(row.cns_budget_plan_price)}</span>
                    <span> = {numberFormatter(row.cns_budget_plan_sum_wat)}</span>
                </div>;
            })}
        <div className={'modal-error-view__delimiter'}></div>

        {isErrorWithCompleted && <>
            <div className={' modal-error-view__desc'}>{t(localeKeys.errors.view.contractEndDate)}: <span>{dateFormatter(isErrorWithCompleted.error?.contract_data?.work_end_date ?? '')}</span>
            </div>
            <div className={' modal-error-view__desc'}>{t(localeKeys.errors.view.objectExecution)}: <span>{isErrorWithCompleted.error?.delivered_completed?.completed_percent ?? 0}%</span></div>
        </>}

        {isErrorWithPlaneDates && <>
            <div className={' modal-error-view__desc'}>
                {t(localeKeys.errors.view.planeContractDate)} {dateFormatter(isErrorWithPlaneDates.error?.contract_data?.planning_date ?? '')}
            </div>
        </>}

        <ModalErrorLines
            error={isErrorSize ?? isErrorWithCompleted ?? props.errors[0]}
            hasErrorSizeWithDates={!!isErrorSize && !!isErrorWithDates}
            typeError={props.typeError}
        />


        {isErrorWithPlaneDates && <>
            {/*Ошибка с плановыми датами, не знаю как показать ее (нет контракта)*/}
        </>}

        {isErrorWithCompleted && <>

            {/*Ошибка с неполным объемом контраков*/}
        </>}

        {!isErrorWithCompleted && !isErrorSize && isErrorWithDates && <>
            <ModalErrorConfirm error={isErrorWithDates} hasErrorSizeWithDates={false} onAction={props.onAction}/>

            {/*Ошибка с датами*/}
        </>}

        {!isErrorWithCompleted && isErrorSize && isErrorWithDates && <>
            <ModalErrorConfirm error={isErrorSize} hasErrorSizeWithDates={true} onAction={props.onAction}/>

            {/*Ошибка объем + даты*/}
        </>}

        {!isErrorWithCompleted && isErrorSize && !isErrorWithDates && <>
            <ModalErrorConfirm error={isErrorSize} hasErrorSizeWithDates={false} onAction={props.onAction}/>

            {/*Ошибка объем*/}
        </>}

        {/*{JSON.stringify(props.errors)}*/}



    </Modal>;
};

export default ModalErrorView;

export function ModalErrorViewBind(props: {
    isOpen: boolean,
    errors: ProjectError[],
    onClose: () => void,
    onAction: (type: 'confirm' | 'cancel') => void,
    typeError: TypeModalEnum
}) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleClose = () => {
        setIsModalOpen(false);
        if (getWData().globalActions) {
            getWData().globalActions.isModalErrors = false;
        }
        props.onClose();
    };

    useEffect(() => {
        if (props.isOpen) {
            setIsModalOpen(true);
        }
    }, [props.isOpen]);


    return <ModalErrorView isOpen={isModalOpen}
                           open={isModalOpen}
                           onOk={handleClose}
                           onCancel={handleClose}
                           errors={props.errors}
                           onAction={(type) => {
                               props.onAction(type);
                               if (type == 'cancel') {
                                   props.onClose();
                                   setIsModalOpen(false)
                               }
                           }}
                           typeError={props.typeError}
    />;

}
