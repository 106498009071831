import {PlusOutlined} from '@ant-design/icons';
import {Button, Table} from 'antd';
import {FunctionComponent, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';
import tokenActions, {AccessItem} from '../../actions/tokenActions';
import {usePassportList} from '../../actions/usePassportList';
import {localeKeys} from '../../i18n/localeKeys';
import {api} from '../../shared/api_client';
import LayoutComponent from '../../shared/layout_component';
import TableLoading from '../../shared/table_loading';
import PassportListColumns from './PassportListColumns';
import PassportListModal from './PassportListModal';
import {ModalErrorsBind} from "../canvas_table/components/modals/modal_errors";

const PassportList: FunctionComponent<{}> = (props) => {
    const [params, setParams] = useState<{ status: 'active' | 'archive' }>({
        status: 'active',
    });
    const {passportsLoading, passportsError, passports, passportsKey} = usePassportList({status: 'active'});
    const passportArchive = usePassportList({status: 'archive'});
    const [isOpenModalView, setIsModalOpenView] = useState<string | number>(0);
    const [isOpenModal, setIsModalOpen] = useState<string | number>(0);
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [isModalErrors, setIsModalErrors] = useState('');

    (window as any).projectLists = {
        queryClient,
        passports,
        isOpenModal,
        isOpenModalView,
        passportsLoading,
        passportsError,
        passportArchive,
    };

    const showModal = () => {
        setIsModalOpen(-1);
    };

    const totals = {
        planAmount: Math.round(passports?.data?.reduce((acc, item) => acc + (item.plan_amount ?? 0), 0) || 0),
        factPaymentsAmount: Math.round(passports?.data?.reduce((acc, item) => acc + (item.fact_payments_amount ?? 0), 0) || 0),
        factPaymentsPercent: '',
        factPaymentsBalance: Math.round(passports?.data?.reduce((acc, item) => acc + (item.fact_payments_balance ?? 0), 0) || 0),
        errorsTotal: Math.round(passports?.data?.reduce((acc, item) => acc + (item.errors_total ?? 0), 0) || 0),
        errorsUnclosed: Math.round(passports?.data?.reduce((acc, item) => acc + (item.errors_unclosed ?? 0), 0) || 0),
    }

    // const planFacktAmount = Math.round(passports?.data?.reduce((acc, item) => acc + (item.fact_amount ?? 0), 0) || 0);
    // const planAmount2 = Math.round(
    //     passportArchive?.passports?.data?.reduce((acc, item) => acc + (item.plan_amount ?? 0), 0) || 0,
    // );
    // const planFacktAmount2 = Math.round(
    //     passportArchive?.passports?.data?.reduce((acc, item) => acc + (item.fact_amount ?? 0), 0) || 0,
    // );

    async function handleArchive(id: string) {
        await api.projects.projectArchive(id);
        queryClient.invalidateQueries([passportsKey]);
        queryClient.invalidateQueries([passportArchive.passportsKey]);
    }

    async function handleRestore(id: string) {
        await api.projects.projectActivate(id);
        queryClient.invalidateQueries([passportsKey]);
        queryClient.invalidateQueries([passportArchive.passportsKey]);
    }

    const {t} = useTranslation();

    function onClickErrors(id: string) {
        setIsModalErrors(id);
    }

    const columnsActive = useMemo(() => PassportListColumns({
        onView: setIsModalOpenView,
        onEdit: setIsModalOpen,
        onArchive: handleArchive,
        onRestore: handleRestore,
        onClickErrors: onClickErrors,
        status: 'active',
        t: t,
    }), [t])

    const columnsArchive = useMemo(() => PassportListColumns({
        onView: setIsModalOpenView,
        onEdit: setIsModalOpen,
        onArchive: handleArchive,
        onRestore: handleRestore,
        onClickErrors: onClickErrors,
        status: 'archive',
        t: t,
    }), [t])

    return (
        <LayoutComponent
            title={t(localeKeys.passportListScreen.title)}
            className="PassportList"
            onAction={() => {
                showModal();
            }}
        >
            {/*{window.innerWidth > 800 && <div className="wrap_table">*/}
            {
                <div className="wrap_table">
                    {passportsLoading && <TableLoading animate={true} offsetTop={false}/>}

                    {!passportsLoading && passports?.data?.length != 0 && (
                        <Table
                            className={'table_active'}
                            style={{maxWidth: tokenActions.projectListDetails == AccessItem.NONE ? '1250px' : '100%'}}
                            rowKey={'id'}
                            columns={columnsActive as any}
                            dataSource={[
                                {
                                    id: '',
                                    title: t(localeKeys.passportListScreen.table.totalByProjects),
                                    members: '',
                                    total_area: '',
                                    plan_amount: totals.planAmount,
                                    fact_payments_amount: totals.factPaymentsAmount,
                                    fact_payments_balance: totals.factPaymentsBalance,
                                    fact_payments_percent: totals.factPaymentsPercent,
                                    errors_total: totals.errorsTotal,
                                    errors_unclosed: totals.errorsUnclosed,
                                },
                                ...(passports?.data?.map((e, index) => ({...e, number: index + 1})) ?? []),
                            ]}
                            pagination={false}
                            loading={passportsLoading}
                            onRow={(row) => ({
                                onClick: () => {
                                    if (row.id) navigate(`/passport/${row.id}`);
                                },
                            })}
                        />
                    )}
                </div>
            }

            {/*{window.innerWidth <= 800 && <>*/}
            {/*    <PassportMbHeader planAmount={planAmount} planFacktAmount={planFacktAmount}/>*/}
            {/*    <PassportMbTable passports={passports ?? {}} onEdit={setIsModalOpen} onArchive={handleArchive}*/}
            {/*                     onRestore={handleRestore} status={'active'}*/}
            {/*                     onView={setIsModalOpenView}*/}
            {/*    />*/}
            {/*</>}*/}

            {!passportsLoading && passports?.data?.length == 0 && (
                <>
                    <div className={'no_projects'}>
                        <div
                            style={{
                                background: 'url(./../images/no_projects.svg), 50% / cover no-repeat',
                                backgroundSize: '100%',
                                width: '54px',
                                height: '62px',
                                mixBlendMode: 'multiply',
                            }}
                        ></div>
                        {tokenActions.createProject == AccessItem.ALLOW && (
                            <>
                                <div className={'text'}>{t(localeKeys.passportListScreen.emptyCreate)}</div>
                                <Button
                                    type={'primary'}
                                    onClick={() => showModal()}
                                    data-test={localeKeys.passportListScreen.addPassport}
                                >
                                    {' '}
                                    <PlusOutlined/> {t(localeKeys.passportListScreen.addPassport)}
                                </Button>
                            </>
                        )}
                        {tokenActions.createProject == AccessItem.NONE && (
                            <>
                                <div className={'text'}>{t(localeKeys.passportListScreen.empty)}</div>
                            </>
                        )}
                    </div>
                </>
            )}

            {params.status == 'active' && !passportsLoading && passportArchive.passports?.data?.length != 0 && (
                <Button type="link" className={'btn_list_archive'} onClick={() => setParams({status: 'archive'})}>
                    {t(localeKeys.passportListScreen.showArchiveProjects)}
                </Button>
            )}
            {params.status == 'archive' && !passportsLoading && (
                <Button type="link" className={'btn_list_archive'} onClick={() => setParams({status: 'active'})}>
                    {t(localeKeys.passportListScreen.hideArchiveProjects)}
                </Button>
            )}

            {/*{window.innerWidth > 800 && <div className="wrap_table">*/}
            {
                <div className="wrap_table">
                    {params.status == 'archive' && (
                        <Table
                            style={{maxWidth: tokenActions.projectListDetails == AccessItem.NONE ? '1250px' : '100%'}}
                            rowKey={'id'}
                            columns={columnsArchive}
                            dataSource={passportArchive.passports?.data}
                            pagination={false}
                            loading={passportsLoading}
                            onRow={(row, col) => ({
                                onClick: () => {
                                    navigate(`/passport/${row.id}`);
                                },
                            })}
                        />
                    )}
                </div>
            }

            {/*{window.innerWidth <= 800 && params.status == 'archive' && <>*/}
            {/*    <PassportMbHeader planAmount={planAmount2} planFacktAmount={planFacktAmount2}/>*/}

            {/*    <PassportMbTable passports={passportArchive.passports ?? {}} onEdit={setIsModalOpen}*/}
            {/*                     onArchive={handleArchive}*/}
            {/*                     onRestore={handleRestore} status={'archive'}*/}
            {/*                     onView={setIsModalOpenView}*/}
            {/*    />*/}
            {/*</>}*/}

            <PassportListModal
                id={isOpenModal || isOpenModalView}
                close={() => {
                    setIsModalOpen(0);
                    setIsModalOpenView(0);
                }}
                isOpen={isOpenModal != 0 || isOpenModalView != 0}
                onlyView={
                    isOpenModalView != 0 ||
                    [AccessItem.VIEW_ONLY, AccessItem.VIEW, AccessItem.VIEW_ALL].includes(tokenActions.listProjects)
                }
                onSuccess={() => {
                    queryClient.invalidateQueries(passportsKey);
                    queryClient.invalidateQueries([passportArchive.passportsKey]);
                }}
            />
            <ModalErrorsBind isOpen={isModalErrors != ''} passportId={isModalErrors} onClose={() => setIsModalErrors('')} />
        </LayoutComponent>
    );
};

export default PassportList;
