import { useQuery } from "react-query";
import { api } from "../shared/api_client";
import {addDataToIndexedDB, getDataFromIndexedDB} from "../screens/contract_negotiation/db/db";
import {ContractHelperValueList} from "../ApiEpo";
export const useContractStatusesList = (filters = '') => {
  const {
    data: contractStatusesList,
    error: contractStatusesListError,
    isLoading: contractStatusesListLoading,
    refetch: refetchHelperContractStatuses
  } = useQuery({
    queryKey: ['contractStatusesList'],
    queryFn: () => api.helpers.contractStatusesList().then(async (e) => {
      const response = e.data;
      if(response) await addDataToIndexedDB('contractStatusesList', response.data);
      return response;
    }).catch(async (error: any): Promise<ContractHelperValueList> => {
      if (!navigator.onLine) {
        const offlineData = await getDataFromIndexedDB('contractStatusesList');
        return { data: offlineData };
      }
      if (error.response) {
        throw error;
      } else {
        throw error;
      }
    }),
  })
  if (filters && contractStatusesList?.data)
    contractStatusesList.data = contractStatusesList?.data?.filter(item => item.value !== filters);

  return {
    contractStatusesList,
    contractStatusesListError,
    contractStatusesListLoading,
    refetchHelperContractStatuses
  };
}
