import { Tree, TreeDataNode, TreeProps } from 'antd';
import { FunctionComponent, useEffect, useState } from 'react';
import {ContractProjectItem} from "../../../ApiEpo";
import {CNTreeTitle} from "./CNTreeTitle";
import {TypeProject} from "../type/types";

interface CNProjectListTreeProps {
    data: ContractProjectItem[]
    selectProject: (val: TypeProject )=>void
}

const CNProjectListTree: FunctionComponent<CNProjectListTreeProps> = ({ ...props }) => {
    const [tree, setTree] = useState<TreeDataNode[]>();
    const getProjectListTree = (projects: ContractProjectItem[]): TreeDataNode[] => {
        if (projects)
            return [...projects?.map((item, index1) => {
                if (item.sections && Array.isArray(item.sections)) {
                    return {
                        project_name: item.title ?? '' ,
                        section_name: '',
                        title: (<CNTreeTitle title={item.title} count={item.sections?.length ?? 0} />),
                        key: `index-${index1}-item-${item.id}`,
                        children: item.sections.map((item2, index2) => {
                            return {
                                project_name: item.title ?? '' ,
                                section_name: item2.section_name ?? '',
                                title: (<CNTreeTitle title={item2.section_name} count={item2.contracts_count} />),
                                key: `index-${index1}-${index2}`,
                                section_id: item2.section_id,
                                project_id: item2.project_id
                            }
                        })
                    }
                } else {
                    return {
                        project_name: item.title ?? '',
                        section_name: '',
                        title: (<CNTreeTitle title={item.title} count={0} />),
                        key: `index-${index1}-item-${item.id}`,
                        children: []
                    };
                }

            })]
        return []
    }
    const onSelect: TreeProps['onSelect'] = (selectedKeys, info: any) => {
        props.selectProject({
            section_id: info.node.section_id,
            project_id: info.node.project_id,
            project_name: info.node.project_name,
            section_name: info.node.section_name,
        })
    };

    useEffect(() => {
        if (props.data && props.data.length > 0) {
            const firstItem = props.data[0];
            if(firstItem.sections && firstItem.sections.length > 0){

                const project_id = firstItem.sections[0].project_id;
                const section_id = firstItem.sections[0].section_id;
                const project_name = firstItem.title ?? '';
                const section_name = firstItem.sections[0]?.section_name ?? '';

                if ( project_id && section_id ) {
                    props.selectProject({
                        section_id: section_id,
                        project_id: project_id,
                        project_name: project_name,
                        section_name: section_name,
                    })
                }
            }
        }
        if (props.data) {
            setTree(getProjectListTree(props.data))
        }
    }, [props.data])

    return (
        <div className='FC-tree'>
            {tree ?
                <Tree
                    defaultExpandedKeys={tree && tree.length > 0 ? [tree[0].key] : []}
                    defaultSelectedKeys={['index-0-0']}
                    onSelect={onSelect}
                    treeData={tree}
                />

                : null}
        </div>
    );
};

export default CNProjectListTree;
