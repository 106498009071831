import { UploadRequestOption } from 'rc-upload/lib/interface';
import { getValueStore } from '../../shared/store';
import {message, notification} from "antd";

export const uploadFile = async (options: UploadRequestOption, onResponse: (val: any) => void) => {
  const { onSuccess, onError, file, onProgress } = options;

  const fmData = new FormData();
  fmData.append('image', file);
  try {
    const formData = new FormData();
    formData.append('file', file);
    const res = await fetch(`${process.env.REACT_APP_API_DIRECTORY}/api/files/upload`, {
      method: 'POST',
      body: formData,
      headers: { Authorization: `Bearer ${getValueStore('token')}` },
    }).then((e) => e.json());

    onSuccess?.('Ok');
    console.log('server res: ', res);

    onResponse(res);
  } catch (err) {
    console.log('Eroor: ', err);
    const error = new Error('Some error');
    onError?.({ error } as any);
    message.error('Не удалось загрузить файл');
  }
};

export const uploadFileAsync = async (file: File) => {
  const fmData = new FormData();
  fmData.append('image', file);

  const formData = new FormData();
  formData.append('file', file);
  return await fetch(`${process.env.REACT_APP_API_DIRECTORY}/api/files/upload`, {
    method: 'POST',
    body: formData,
    headers: { Authorization: `Bearer ${getValueStore('token')}` },
  }).then((e) => e.json());
}

export async function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as any);
    reader.onerror = error => reject(error);
  })
}

export function base642file(base64: string, filename: string) {
  var arr = base64.split(','),
    mime = (arr[0] as any).match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}
