import React, {useState} from 'react';
import {Card, Typography, Space, Button, Divider, Tag, Popconfirm, message} from 'antd';
import { CloseCircleOutlined, StopOutlined, CheckCircleOutlined, CommentOutlined } from '@ant-design/icons';
import {CNCardProps, TypeContract} from "../type";
import {CNAction} from "./CNAction";
import {ButtonLoadingState} from "../type/types";
import {api} from "../../../shared/api_client";
import ModalReasonRejected from "../../FinController/ModalReasonRejected";
import {formatDateCard, helperParserSignatures, helperSignatures} from "../lib/CNHelper";
const { Text } = Typography;

export const CNCard: React.FC<CNCardProps> = ({ setting, contract, projectTitle, sectionTitle, agreed, onReject, onApprove, onDownload, onUpload }) => {
    const isNomenclatures = Boolean(contract.nomenclatures?.length);
    const [isOpenReject, setIsOpenReject] = useState<boolean>(false)
    // TODO доделать;
    const signatures = contract.approvals?.filter((it) => it.user_id) || [];
    const [btnLoading, setBtnLoading] = useState<ButtonLoadingState>({
        downloadBtn: false,
        rejectBtn: false,
        approveBtn: false,
        uploadBtn: false
    })
    // Скачать документ;
    const handleDownload = async (record: TypeContract) => {
        if (!record.id) return;
        let blob: any;
        try {
            setBtnLoading({downloadBtn: true});
            const res = await api.contracts.documentsFilesArchiveDetail(record.id);
            blob = await res.blob();
            if (!blob) return;
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `${record.contractor_name}_${record.contract_number}_archive.zip`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            setBtnLoading({downloadBtn: false});
            await onDownload?.(record);
        }catch (error) {
            setBtnLoading({downloadBtn: false});
            console.log(error);
            message.error(`Ошибка не удалось скачать`)
        }
    };

    const handleRejectShowModal = () => {
        setIsOpenReject(true);
    }
    // Отклонить;
    const handleReject = async (record: any) => {
        if (!record?.ids) return;
        if(!record?.reason) {
            message.error('Укажите причину откланения');
            return;
        }
        setBtnLoading({rejectBtn: true});
        try {
            await api.contracts.statusCreate({
                id: record.ids,
                status: 'rejected',
                reason: record.reason,
                file_ids: []
            });
            await onReject?.(record);
            setBtnLoading({rejectBtn: false});
            message.success(`Успешно отклонен`)
        }catch (error) {
            setBtnLoading({rejectBtn: false});
            const e = error as any
            console.log(e);
            if (e?.error?.message) {
                console.log(e);
                message.error(e?.error?.message);
            } else {
                message.error(`Error approved`);
            }
        }

    };

    // Согласовать;
    const handleApprove = async (record: TypeContract) => {
        console.log('Approved', record);
        if (!record?.id) return;
        setBtnLoading({approveBtn: true});
        try {
            await api.contracts.statusCreate({
                id: record.id,
                status: helperSignatures(record.status, setting).status,
                file_ids: []
            });
            await onApprove?.(record);
            message.success(`Успешно согласован`)
            setBtnLoading({approveBtn: false});
        }catch (error) {
            setBtnLoading({approveBtn: false});
            const e = error as any
            if (e?.error?.message) {
                console.log(e);
                message.error(e?.error?.message);
            } else {
                message.error(`Error approved`);
            }
        }
    };
    // Загрузить документ;
    const handleUpload = async (fileIds: string[], record: TypeContract) => {
        if (!record?.id && !fileIds.length) return;
        setBtnLoading({uploadBtn: true});
        try {
            await api.contracts.statusCreate({
                id: record.id ?? '',
                status: helperSignatures(record.status, setting)?.status,
                file_ids: fileIds
            });
            await onUpload?.(record);
            message.success(`Успешно подписан`)
            setBtnLoading({uploadBtn: false});
        }catch (error) {
            setBtnLoading({uploadBtn: false});
            const e = error as any
            if (e?.error?.message) {
                console.log(e);
                message.error(e?.error?.message);
            } else {
                message.error(`Error file`);
            }
        }
    };

    const isShowAction = (status: string | undefined) => {
        return ['rejected', 'signing', undefined].includes(status)
    }
    const isShowActionUpload = (status: string | undefined) => {
        return !['signing', undefined].includes(status)
    }

    return (
        <>
            <Card className={`cn_card ${agreed ? 'cn_card_agreed' : isNomenclatures ? '' : 'border_bt'}`} style={{background: "transparent"}}>
                {( agreed ?  <Divider/> : '')}
                {(!agreed && projectTitle ? <Text type="secondary" className={'text-card'}>{projectTitle}</Text> : '')}
                {(!agreed && sectionTitle ?  <div className={'title'}>{sectionTitle}</div> : '')}
                <Space className={'cn_block'}>
                    <div className={'item_project'}>
                        <Text className={'project_name'} strong >{contract.contractor_name}, № {contract.contract_number} от {contract.contract_date} </Text>
                        {contract.status === 'rejected' ?
                            <div className={'tag_name danger'}>{contract.status_label}</div>  :
                            <div className={'tag_name'}>{contract.status_label}</div>
                        }
                    </div>
                    <div className={'total_cost'} ><Text strong>{contract.amount}</Text></div>
                </Space>
                {isNomenclatures ?
                    <Space direction="vertical" style={{ width: '100%' }}>
                        {contract.nomenclatures?.map((item, index) => (
                            <div key={index} className={'cn_items'}>
                                <Text type="secondary">{item.nomenclature_title}</Text>
                                <Space>
                                    <div className={'cn_quantity'}><Text type="secondary">{item.volume}</Text></div>
                                    <div className={'cn_price'}><Text type="secondary">* {item.price}</Text></div>
                                    <div className={'cn_total'}><Text type="secondary">= {item.amount}</Text></div>
                                </Space>
                            </div>
                        ))}
                    </Space>
                : ''}

                {(signatures?.length ? <Space className={'cn_agreed'}>
                    {signatures?.map((it, key) => (
                        (it.status === 'rejected' ?
                            <React.Fragment key={key}>
                                <div className={'tag_name danger'}>
                                    <span>{<StopOutlined style={{ color: 'red' }} />} {helperParserSignatures(it.status).value}
                                        ({it.user_fullname}) {formatDateCard(it?.moved_at)}
                                    </span>
                                </div>
                                <div className={'tag_name comment'}>
                                    <span>{<CommentOutlined style={{ color: 'black' }} />} {it.reject_reason}</span>
                                </div>
                            </React.Fragment>
                            :
                            <div key={key} className={'tag_name'}>
                                <span>{<CheckCircleOutlined style={{ color: 'black' }} />} {helperParserSignatures(it.status).value}
                                   ({it.user_fullname}) {formatDateCard(it?.moved_at)}
                                </span>
                            </div>)
                    ))}
                </Space> : '')}
             <CNAction
                    key={contract.id}
                    hideReject={isShowAction(contract.status)}
                    hideDownload={false}
                    hideApprove={isShowAction(contract.status)}
                    hideUpload={isShowActionUpload(contract.status)}
                    btnLoading={btnLoading}
                    contract={contract}

                    onReject={handleRejectShowModal}
                    onApprove={() => handleApprove(contract)}
                    onDownload={() => handleDownload(contract)}
                    onUpload={handleUpload}
                />

            </Card>
            <ModalReasonRejected
                key={contract.id}
                isOpen={isOpenReject}
                modalData={contract.id ?? ''}
                onCancel={setIsOpenReject}
                onClose={() => {
                    setIsOpenReject(false)
                }}
                onRejerect={handleReject}
            />
        </>

    );
};
