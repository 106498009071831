import {useQuery} from 'react-query';
import {api} from '../shared/api_client';
import tokenActions, {AccessItem} from './tokenActions';
import {
  getProjectsArchiveListDb,
  getProjectsListDb,
  isOnline,
  syncProjectsArchiveListDb,
  syncProjectsListDb
} from "../indexedb";
import {useDb} from "./useDb";
import {message, notification} from "antd";

export function usePassportList(params: { status: 'active' | 'archive' }) {
  const passportsKey = `passports_${JSON.stringify(params)}`;
  const {initDb} = useDb()

  const {
    data: passports,
    error: passportsError,
    isLoading: passportsLoading,
  } = useQuery({
    queryKey: [passportsKey],
    queryFn: async function () {
      if (tokenActions.listProjects == AccessItem.NONE) {
        return Promise.reject('No access to projects');
      }

      try {
        const res = await api.projects.projectsList({...params}).then((e) => e.data ?? []);
        // await initDb();
        if (params.status == 'active') {
          await syncProjectsListDb(res?.data ?? []);
        } else if (params.status == 'archive') {
          await syncProjectsArchiveListDb(res?.data ?? []);
        }
        return {data: res.data ?? []}

      } catch (e) {

        message.error('Не удалось получить актуальный список проектов');

        if (params.status == 'active') {
          return {data: await getProjectsListDb()};
        } else if (params.status == 'archive') {
          return {data: await getProjectsArchiveListDb()};
        }
      }
    },
  });

  return {
    passports,
    passportsError,
    passportsLoading,
    passportsKey,
  };
}
