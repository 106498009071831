import moment from "moment/moment";
import { isRu, localeCode } from '../../../../i18n/config';

export enum TypeModalEnum {
  contracts = 'contracts',
  bills = 'bills',
  way_bills = 'way_bills',
  invoices = 'invoices',
}

export function getErrorFieldPrefics(type: TypeModalEnum) {
  let preficsErrorField = 'fact_budget-'
  if (type == 'invoices') {
    preficsErrorField = 'payment_invoices-'
  } else if (type == 'way_bills') {
    preficsErrorField = 'delivered_completed-'
  } else if (type == 'bills') {
    preficsErrorField = 'bills-'
  }

  return preficsErrorField
}

export function getTypeError(errorOver: 'contract_over_error' | 'bill_over_error' | 'waybill_over_error' | 'invoice_over_error' | ''): TypeModalEnum {
  if (errorOver == 'contract_over_error') {
    return TypeModalEnum.contracts
  } else if (errorOver == 'bill_over_error') {
    return TypeModalEnum.bills
  } else if (errorOver == 'waybill_over_error') {
    return TypeModalEnum.way_bills
  } else if (errorOver == 'invoice_over_error') {
    return TypeModalEnum.invoices
  }

  return TypeModalEnum.contracts
}

export function getTimeClosed(createdAt: string, closedAt: string) {
  const timeClosed = moment(closedAt, 'YYYY-MM-DDTHH:mm:ss');
  const timeCreated = moment(createdAt, 'YYYY-MM-DDTHH:mm:ss');

  // console.log('timeClosed', timeClosed.toISOString(), timeCreated.toISOString());


  const timeDiffHours = timeClosed.diff(timeCreated, 'hour');

  if (timeDiffHours <= 0) {
    const timeDiffMinutes = timeClosed.diff(timeCreated, 'minute');

    if (timeDiffMinutes <= 0) {
      if (isRu()) {
        return 'менее минуты';
      }

      return 'less than a minute';
    }

    if (isRu()) {
      return `${timeDiffMinutes} минут`;
    }
    return `${timeDiffMinutes} minutes`;
  }
  if (timeDiffHours > 24) {
    const timeDiffDays = timeClosed.diff(timeCreated, 'day');
    if (isRu()) {
      return `${timeDiffDays} дней`;
    }
    return `${timeDiffDays} days`;
  }

  if (isRu()) {
    return `${timeDiffHours} часа`;
  }
  return `${timeDiffHours} hours`;
}

export function getDateDiff(date: string ) {
  const timeClosed = moment();
  const timeCreated = moment(date, 'YYYY-MM-DDTHH:mm:ss');

  return timeCreated.diff(timeClosed, 'days');
}
