import React, {FunctionComponent, useEffect, useState} from 'react';
import {localeKeys} from "../../i18n/localeKeys";
import {Input, Tabs, TabsProps} from "antd";
import {ContractsProjectStatusType, useContractsProject} from "../../actions/useContractsProject";
import {FilterOutlined} from "@ant-design/icons";
import LayoutComponent from "../../shared/layout_component";
import {useTranslation} from "react-i18next";
import {useContractStatusesList} from "../../actions/contractStatusesList";
import {
    getFinControllerCountTabs
} from "../FinController/FinControllerUtils/FinControllerCollumns";
import {CNCardView} from "./CNCardData";
import CNProjectListTree from "./components/CNProjectListTree";
import {TypeProject} from "./type/types";
import {useSettingsView} from "../../actions/useSettingsView";
import {Setting} from "../../ApiEpo";

const ContractNegotiation: FunctionComponent = () => {

    const {t} = useTranslation();
    // state;
    const [isFilterBtn, setIsFilterBtn] = useState<boolean>(false);
    const [defaultStatus, setDefaultStatus] = useState<ContractsProjectStatusType>() // on_work
    const [statusTabs, setStatusTabs] = useState<ContractsProjectStatusType>();
    const [inputValue, setInputValue] = useState<string>('')
    const [debouncedValue, setDebouncedValue] = useState(inputValue);
    const [refreshData, setRefreshData] = useState<boolean>(false)
    const {settings} = useSettingsView();
    const [setting, setSetting] = useState<Setting>()

    // Запрос хелпер на табы;
    const {contractStatusesList, contractStatusesListError, contractStatusesListLoading, refetchHelperContractStatuses} = useContractStatusesList('signed');

    const [project, setSelectProject] = useState<TypeProject>()


    const {contractsProjectList, contractsProjectListError, contractsProjectListLoading, refetchContractsProject} = useContractsProject(statusTabs ?? defaultStatus)
    const countTabs = getFinControllerCountTabs(contractStatusesList ?? {});
    const [tabsDropDownSelect, setTabsDropDownSelect] = useState<number>(0);

    useEffect(() => {
        const handler = setTimeout(() => setDebouncedValue(inputValue), 1000);
        return () => clearTimeout(handler);
    }, [inputValue]);

    useEffect(() => {
       const _setting = settings?.data?.find((item)=> item.name == 'CONTRACT_DK_APPROVAL_REQUIRED') || null;
       if (_setting) setSetting(_setting);
    }, [settings]);

    const handleUpdateContent = async (record: any) => {
        try {
            console.log('handleUpdateContent',record);
            localStorage.removeItem('statusTab')
            setDefaultStatus(undefined);
            setRefreshData(true);
            await refetchHelperContractStatuses();
            await refetchContractsProject();
            setRefreshData(false);
        }catch (e) {
            console.log('handleUpdateContent error',e);
            setRefreshData(false);
        }
    }

    // Верстка табы;
    const wrapRenderTab = !contractsProjectListLoading && !contractsProjectListError && contractsProjectList?.data?.length
        ? <div className={`FC-wrapper`}>
            <CNProjectListTree data={contractsProjectList.data} selectProject={project => setSelectProject(project)} />
            <div className={`tab_wrap`}>
                <CNCardView
                    setting={setting}
                    project={project}
                    status={statusTabs ?? defaultStatus}
                    refreshData={refreshData}
                    searchValue={debouncedValue}
                    onApprove={(record)=>handleUpdateContent(record)}
                    onReject={(record)=>handleUpdateContent(record)}
                    onUpload={(record)=>handleUpdateContent(record)}
                />
            </div>
        </div>
        : <div className={'empty_result'}>{contractsProjectListLoading ? t(localeKeys.shared.loading) : t(localeKeys.contragentsListScreen.noData)}</div>;

    const totalCount = contractStatusesList?.data?.reduce((acc, item) => acc + (item.count || 0), 0) || 0;
    const tabs: TabsProps['items'] = contractStatusesList?.data?.length
        ? [
            {
                key: '',
                label: `Все (${totalCount})`,
                disabled: totalCount === 0,
                children: wrapRenderTab,
            },
            ...contractStatusesList.data.map((item, index) => {
                return {
                    key: item.value ?? '',
                    label: `${item.text} (${item.count})`,
                    disabled: item.count === 0,
                    children: wrapRenderTab,
                };
            })
        ]
        : [];

    const onChangeTab = (status: ContractsProjectStatusType, dropDownTabs?: boolean) => {
        console.log('onChangeTab',status);
        localStorage.setItem('statusTab', status);
        setStatusTabs(status)
        setDefaultStatus(status);
        if (!dropDownTabs) setTabsDropDownSelect(0)
    };

    //дефолтный таб
    useEffect(() => {
        const getLocalStorageStatusTab = (localStorage.getItem('statusTab') || '') as ContractsProjectStatusType | undefined;
        if(getLocalStorageStatusTab) {
            setStatusTabs(getLocalStorageStatusTab)
            setDefaultStatus(getLocalStorageStatusTab);
        }
    }, [contractStatusesList, contractStatusesListLoading, contractStatusesListError]);

    useEffect(() => {
        if (tabsDropDownSelect !== 0) {
            const newStatus = contractStatusesList?.data?.slice(countTabs).map(item => item.value)
            if (newStatus && newStatus[tabsDropDownSelect - 1]) {
                onChangeTab(newStatus[tabsDropDownSelect - 1] as ContractsProjectStatusType, true)
            }
        }
    }, [tabsDropDownSelect]);

    const onFilterMobileShow = () => {
        setIsFilterBtn((prev) => !prev);
    }

    (window as any).screenContractNegotiation = {
        defaultStatus,
        countTabs,
    }

    return (
        <LayoutComponent title={t(localeKeys.contractNegotiation.title)}
                         className={`contract_negotiation_screen`}
                         onAction={() => {
                         }}>
                 <>
                        <Tabs
                            tabPosition={'top'}
                            moreIcon={''}
                            className={`contract_negotiation_screen_content ${isFilterBtn ? 'show-mobile-nav' : ''}`}
                            activeKey={defaultStatus}
                            defaultActiveKey={defaultStatus}
                            items={tabs}
                            onChange={(e) => onChangeTab(e as ContractsProjectStatusType)}
                            tabBarExtraContent={<>
                                <div className={'filters-search-wrap'}>
                                    <div className={'filter-btn-mobile'} onClick={onFilterMobileShow}><FilterOutlined className={isFilterBtn ? 'filter-btn-mobile-active' : ''}  /></div>
                                    <Input
                                        onChange={(e) => setInputValue(e.target.value)}
                                        className={'contract_negotiation_screen_search'}
                                        placeholder={t(localeKeys.contractNegotiation.search)}
                                        suffix={<img className={`tree-contragent-img`} src="/layout/Serch.svg"/>}/>
                                </div>
                            </>}
                        />
                </>
        </LayoutComponent>
    );
};

export default ContractNegotiation;
