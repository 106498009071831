import { sizes } from '../../../../shared/sizes';

export function checkEndLoading(setShowLoading: (flag: boolean) => void) {
  if (window.innerWidth < sizes.tablet) {
    setShowLoading(false);
    return;
  }

  var work = new Worker(
    'data:text/javascript,setInterval(` dl=Date.now();for(itr=1;itr<1000;itr++){};dl=Date.now()-dl;postMessage(dl);`,1000);',
  );

  work.onmessage = (evt) => {
    console.log('setShowLoading(false)');
    setShowLoading(false);

    work.terminate();
  };
}
