import { Spin, Table, TableProps } from 'antd';
import moment from 'moment';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { HistoryItem, HistoryList } from '../../../../ApiHistory';
import { HistoryRequest, HistoryRow } from '../../../../actions/useHistoryData';
import { historyApi } from '../../../../shared/api_client';
import { DateFormat, DateFormatFull, dateFormatter, numberFormatter } from '../../../../shared/dateFormat';
import { sizes } from '../../../../shared/sizes';
import { getValueStore } from '../../../../shared/store';
import { HistoryContractDataRecord } from '../../../../Models/history_types';
import { localeKeys } from '../../../../i18n/localeKeys';
import { useTranslation } from 'react-i18next';

interface HistoryRecord {
    key: string;
    date: string;
    eventType: string;
    comment: string;
    user: string;
}

const columns: (t: (key: string) => string) => TableProps<HistoryRecord>['columns'] = (t) => [
    {
        title: t(localeKeys.history.table.dateTime),
        dataIndex: 'date',
        key: 'date',
        width: window.innerWidth < sizes.mobile ? 90 : 150,
        render: (text, record) => (record.key ? text : ''),
    },
    {
        title: t(localeKeys.history.table.eventType),
        dataIndex: 'eventType',
        key: 'eventType',
        width: window.innerWidth < sizes.mobile ? 100 : 'auto',
        render: (text, record) => (record.key ? text : ''),
    },
    {
        title: t(localeKeys.history.table.comment),
        dataIndex: 'comment',
        key: 'comment',
        render: (text, record) =>
            record.key ? (
                <span dangerouslySetInnerHTML={{ __html: text }} />
            ) : (
                <div style={{ textAlign: 'center', padding: 20 }}>
                    <Spin size="small"></Spin>
                </div>
            ),
        width: window.innerWidth < sizes.mobile ? 200 : 'auto',
    },
    {
        title: t(localeKeys.history.table.user),
        key: 'user',
        dataIndex: 'user',
        width: window.innerWidth < sizes.mobile ? 150 : 'auto',
        render: (text, record) => (record.key ? text : ''),
    },
];
const ModalHistoryTable: FunctionComponent<{ params: HistoryRequest }> = function(props) {
    const { t } = useTranslation();
    const tableRef = useRef();

    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [paramsOld, setParamsOld] = useState<HistoryRequest | undefined>(undefined);

    const [historyData, setHistoryData] = useState<HistoryList | undefined>();
    const [historyPages, setHistoryPages] = useState<{ [page: number]: HistoryItem[] }>({});

    async function load(page = 1, params: HistoryRequest) {
        (window as any).modalHistoryTable.loading = true;
        setLoading(true);
        const res = await historyApi.changes.changesList({ ...params, page, page_size: 100 });
        setHistoryData(res.data);
        (window as any).modalHistoryTable.historyData = res.data;

        if (res.data?.data?.length && res.data.meta?.current_page) {
            setHistoryPages({
                ...(window as any).modalHistoryTable.historyPages,
                [res.data.meta.current_page]: res.data.data ?? [],
            });
        }

        (window as any).modalHistoryTable.loading = false;
        setLoading(false);
    }

    function getScrollPositionByEnd() {
        const tb = document.querySelector('.ant-table-body');
        return tb ? tb.scrollHeight - tb.scrollTop - tb.clientHeight : 0;
    }

    function loadNext() {
        if ((window as any).modalHistoryTable.loading) return;
        if (
            (window as any).modalHistoryTable?.historyData?.meta?.last_page &&
            (window as any).modalHistoryTable.historyPages &&
            (window as any).modalHistoryTable.historyPages.length >
            ((window as any).modalHistoryTable.historyData.meta.last_page ?? 0)
        )
            return;
        if (
            getScrollPositionByEnd() < window.innerHeight / 2 &&
            !(window as any).modalHistoryTable.loading &&
            (window as any).modalHistoryTable?.page <
            ((window as any).modalHistoryTable?.historyData?.meta?.last_page ?? 1000)
        ) {
            setPage(page + 1);
            (window as any).modalHistoryTable.page++;
            console.log(`load next page ${(window as any).modalHistoryTable.page}`);
            load((window as any).modalHistoryTable.page, (window as any).modalHistoryTable.params);
        }
    }

    useEffect(() => {
        if (JSON.stringify(props.params) == JSON.stringify(paramsOld)) return;
        setHistoryData(undefined);
        setHistoryPages({});
        setPage(1);
        setParamsOld(props.params);
        setTimeout(() => load(1, props.params), 100);

        (window as any).modalHistoryTable.page = 1;
        (window as any).modalHistoryTable.params = props.params;
        (window as any).modalHistoryTable.historyPages = {};

        const tb = document.querySelector('.ant-table-body');
        tb?.removeEventListener('scroll', loadNext);
        tb?.addEventListener('scroll', loadNext);
    }, [props.params]);

    const data: HistoryRecord[] = [];

    for (let item of Object.values(historyPages)) {
        let index = 0;
        for (let row of item) {
            index++;
            const user =
                row.changes?.user?.username ?? row.changes?.user?.login ?? row.user_id ?? t(localeKeys.history.table.unknownUser);

            const obj = row.changes?.object?.repr ?? t(localeKeys.history.table.unknownEntity);

            let eventType = `${row.object_type} ${obj}`;

            const action = (row.changes?.changes as any)?.action;
            const sections = (row.changes?.changes as any)?.sections?.[0]
                ?.map((item: any) => `${item?.title}: ${numberFormatter(item?.guideline_amount ?? 0)}`)
                .join(', ');
            const record: HistoryRow | undefined = (row.changes as any)?.row;
            const recordView = record
                ? t(localeKeys.history.table.nomenclatureView, {
                    title: record.title,
                    unit: record.unit,
                    plan_budget_price: record.plan_budget?.price,
                    plan_budget_volume: record.plan_budget?.volume,
                })
                : '';

            let localeLevel = '';

            if (record) {
                if (record.nomenclature_id) {
                    localeLevel = t(localeKeys.history.table.nomenclature);
                } else if (record.type_id) {
                    localeLevel = t(localeKeys.history.table.workType);
                } else if (record.section_id) {
                    localeLevel = t(localeKeys.history.table.section);
                } else if (record.project_id) {
                    localeLevel = t(localeKeys.history.table.project);
                }
            }

            let debugComment = true;

            let resultComment = [action, sections].filter((e) => e).join('\n');

            const operation = (row as any).operation;

            if (row.object_type == 'Project' && operation == 1) {
                eventType = t(localeKeys.history.table.projectChanged);
                const changeLog: string[] = [];

                if ((row.changes?.changes as any)?.start_date) {
                    changeLog.push(t(localeKeys.history.table.projectStartDateChangedView, {
                        start_date: dateFormatter((row.changes?.changes as any)?.start_date?.[0]),
                        start_date2: dateFormatter((row.changes?.changes as any)?.start_date?.[1]),
                    }));
                }

                if ((row.changes?.changes as any)?.end_date) {
                    changeLog.push(t(localeKeys.history.table.projectEndDateChangedView, {
                        end_date: dateFormatter((row.changes?.changes as any)?.end_date?.[0]),
                        end_date2: dateFormatter((row.changes?.changes as any)?.end_date?.[1]),
                    }));
                }

                if ((row.changes?.changes as any)?.members?.[0]?.length) {
                    changeLog.push(t(localeKeys.history.table.projectMembersRemovedView, {
                        members: (row.changes?.changes as any)?.members?.[0]?.map((member: any) => member?.full_name).join(', '),
                    }));
                }

                if ((row.changes?.changes as any)?.members?.[1]?.length) {
                    changeLog.push(t(localeKeys.history.table.projectMembersAddedView, {
                        members: (row.changes?.changes as any)?.members?.[1]?.map((member: any) => member?.full_name).join(', '),
                    }));
                }

                if ((row.changes?.changes as any)?.title?.[1]?.length) {
                    changeLog.push(t(localeKeys.history.table.projectTitleChangedView, {
                            title: (row.changes?.changes as any)?.title?.[0],
                            title2: (row.changes?.changes as any)?.title?.[1],
                        }),
                    );
                }

                if ((row.changes?.changes as any)?.total_area?.[1]) {
                    changeLog.push(t(localeKeys.history.table.projectTotalAreaChangedView, {
                        total_area: (row.changes?.changes as any)?.total_area?.[0],
                        total_area2: (row.changes?.changes as any)?.total_area?.[1],
                    }));
                }

                if ((row.changes?.changes as any)?.location?.[1]?.length) {
                    changeLog.push(t(localeKeys.history.table.projectLocationChangedView, {
                        location: (row.changes?.changes as any)?.location?.[0],
                        location2: (row.changes?.changes as any)?.location?.[1],
                    }));
                }

                if (!changeLog.length) {
                    changeLog.push(t(localeKeys.history.table.changesNotDefined));
                }

                resultComment = changeLog.join('<br />');
                debugComment = false;
            }

            // Добавление ответственного в номенклатуру
            if (row.object_type == 'ProjectNomenclatureResponsibleUser') {

                const removedUsers = ((row.changes?.changes as any)?.responsible_users?.[0] ?? []) as {
                    name: string
                }[];
                const addedUsers = ((row.changes?.changes as any)?.responsible_users?.[1] ?? []) as { name: string }[];
                let templateAddedUsers = addedUsers.map((user: { name: string }) => `"${user.name}"`).join(', ');
                let templateRemovedUsers = removedUsers.map((user: { name: string }) => `"${user.name}"`).join(', ');

                if (removedUsers.length && addedUsers.length) {
                    eventType = t(localeKeys.history.table.responsibleChanged);
                    resultComment = t(localeKeys.history.table.responsibleChangedView, {
                        templateAddedUsers,
                        templateRemovedUsers,
                    });
                }

                if (removedUsers.length && !addedUsers.length) {
                    eventType = t(localeKeys.history.table.responsibleRemoved);
                    resultComment = `${eventType}: ${templateRemovedUsers}.`;
                }

                if (!removedUsers.length && addedUsers.length) {
                    eventType = t(localeKeys.history.table.responsibleAdded);
                    resultComment = `${eventType}: ${templateAddedUsers}.`;
                }
                debugComment = false;
            }

            // Добавление контрагента в номенклатуру
            if (row.object_type == 'ProjectNomenclatureContractor') {
                let contractors = ((row.changes?.changes as any)?.contractors ?? []) as any[];
                let contractRemoved = (contractors[0] ?? []) as HistoryContractDataRecord[];
                let contractAdded = (contractors[1] ?? []) as HistoryContractDataRecord[];
                let templateAddedContractors = contractAdded.map((contractor: HistoryContractDataRecord) => `"${contractor.name} (${contractor.inn})"`).join(', ');
                let templateRemovedContractors = contractRemoved.map((contractor: HistoryContractDataRecord) => `"${contractor.name} (${contractor.inn})"`).join(', ');

                if (contractAdded.length && contractRemoved.length) {
                    eventType = t(localeKeys.history.table.contragentsChanged);
                    resultComment = t(localeKeys.history.table.contragentsChangedView, {
                        templateAddedContractors,
                        templateRemovedContractors,
                    });
                }

                if (contractAdded.length && !contractRemoved.length) {
                    eventType = t(localeKeys.history.table.contragentsAdded);
                    resultComment = `${eventType}: ${templateAddedContractors}.`;
                }

                if (!contractAdded.length && contractRemoved.length) {
                    eventType = t(localeKeys.history.table.contragentsRemoved);
                    resultComment = `${eventType}: ${templateRemovedContractors}.`;
                }

                debugComment = false;
            }

            // Работа с контрактом
            if (row.object_type == 'ProjectNomenclatureContract') {
                let contract = row.changes?.row?.contract;
                const contractView = contract
                    ? t(localeKeys.history.table.contractView, {
                        contractor: contract?.contractor,
                        contract_date: dateFormatter(contract?.contract_date ?? ''),
                        price: numberFormatter(contract?.price ?? 0),
                        volume: numberFormatter(contract?.volume ?? 0),
                        amount: numberFormatter(contract?.amount ?? 0),
                    })
                    : '';
                if (operation == 0) {
                    eventType = t(localeKeys.history.table.contractAdded);
                    resultComment = t(localeKeys.history.table.contractAddedView, {
                        object_repr: row.changes?.object?.repr,
                        contractView,
                    });
                }
                if (operation == 2) {
                    eventType = t(localeKeys.history.table.contractRemoved);
                    resultComment = t(localeKeys.history.table.contractRemovedView, {
                        object_repr: row.changes?.object?.repr,
                        contractView,
                    });
                }
                debugComment = false;
            }

            // Работа со счетом
            if (row.object_type == 'ProjectNomenclaturePaymentInvoice') {
                let paymentInvoice = row.changes?.row?.payment_invoice;
                const paymentView = paymentInvoice
                    ? t(localeKeys.history.table.paymentView, {
                        invoice_number: paymentInvoice?.invoice_number,
                        invoice_date: dateFormatter(paymentInvoice?.invoice_date ?? ''),
                        price: numberFormatter(paymentInvoice?.price ?? 0),
                        volume: numberFormatter(paymentInvoice?.volume ?? 0),
                        amount: numberFormatter(paymentInvoice?.amount ?? 0),
                    })
                    : '';
                if (operation == 0) {
                    eventType = t(localeKeys.history.table.paymentAdded);
                    resultComment = t(localeKeys.history.table.paymentAddedView, {
                        object_repr: row.changes?.object?.repr,
                        paymentView,
                    });
                }
                if (operation == 2) {
                    resultComment = t(localeKeys.history.table.paymentRemovedView, {
                        object_repr: row.changes?.object?.repr,
                        paymentView,
                    });
                    eventType = t(localeKeys.history.table.paymentRemoved);
                }
                debugComment = false;
            }

            // Работа с ТТН
            if (row.object_type == 'ProjectNomenclatureWaybill') {
                let wayBill = (row.changes?.row as any)?.waybill;
                const paymentView = wayBill
                    ? t(localeKeys.history.table.waybillView, {
                        waybill_number: wayBill?.waybill_number,
                        waybill_date: dateFormatter(wayBill?.waybill_date ?? ''),
                        price: numberFormatter(wayBill?.price ?? 0),
                        volume: numberFormatter(wayBill?.volume ?? 0),
                        amount: numberFormatter(wayBill?.amount ?? 0),
                    })
                    : '';
                if (operation == 0) {
                    eventType = t(localeKeys.history.table.waybillAdded);
                    resultComment = t(localeKeys.history.table.waybillAddedView, {
                        object_repr: row.changes?.object?.repr,
                        waybillView: paymentView,
                    });
                }
                if (operation == 2) {
                    eventType = t(localeKeys.history.table.waybillRemoved);
                    resultComment = t(localeKeys.history.table.waybillRemovedView, {
                        object_repr: row.changes?.object?.repr,
                        waybillView: paymentView,
                    });
                }
                debugComment = false;
            }

            // Работа с СФ
            if (row.object_type == 'ProjectNomenclatureBill') {
                let bill = (row.changes?.row as any)?.bill;
                const paymentView = bill
                    ? t(localeKeys.history.table.billView, {
                        bill_number: bill?.bill_number,
                        bill_date: dateFormatter(bill?.bill_date ?? ''),
                        price: numberFormatter(bill?.price ?? 0),
                        volume: numberFormatter(bill?.volume ?? 0),
                        amount: numberFormatter(bill?.amount ?? 0),
                    })
                    : '';
                if (operation == 0) {
                    eventType = t(localeKeys.history.table.billAdded);
                    resultComment = t(localeKeys.history.table.billAddedView, {
                        object_repr: row.changes?.object?.repr,
                        paymentView,
                    });
                }
                if (operation == 2) {
                    eventType = t(localeKeys.history.table.billRemoved);
                    resultComment = t(localeKeys.history.table.billRemovedView, {
                        object_repr: row.changes?.object?.repr,
                        paymentView,
                    });
                }
                debugComment = false;
            }

            // Изменение дат
            if (row.object_type == 'ProjectNomenclatureDates') {
                const changes = row.changes?.changes as any;
                if (changes?.contract_data?.planning_date?.[1]) {
                    eventType = t(localeKeys.history.table.dateChanged);
                    resultComment = t(localeKeys.history.table.planedDateSet, {
                        object_repr: row.changes?.object?.repr,
                        planning_date: dateFormatter(changes?.contract_data?.planning_date?.[1]),
                    });
                    debugComment = false;
                } else {
                    eventType = t(localeKeys.history.table.dateChanged);
                }
            }

            // Смена статуса
            if ((row.changes?.changes as any)?.status?.[1]) {
                const status = (row.changes?.changes as any)?.status?.[1];
                if (status == 'active') {
                    eventType = t(localeKeys.history.table.projectRestored);
                }
                if (status == 'active' && operation == 0) {
                    eventType = t(localeKeys.history.table.projectCreated);
                }
                if (status == 'archive') {
                    eventType = t(localeKeys.history.table.projectArchived);
                }
                resultComment = t(localeKeys.history.table.projectView, { obj: obj });
                debugComment = false;
            }

            // Удаление номенклатуры
            if (row.object_type == 'ProjectNomenclature' && operation == 2) {
                eventType = t(localeKeys.history.table.nomenclatureRemoved);
                resultComment = t(localeKeys.history.table.nomenclatureRemovedView, { recordView: recordView });
                debugComment = false;
            }

            // Подтверждение БО
            if (row.object_type == 'ProjectGuidelineBudgetApprove') {
                eventType = 'Подтвержден БО';
                resultComment = `${t(localeKeys.history.table.project)} ${obj}: ` + resultComment;
                debugComment = false;
            }

            // БП отправлен на согласование
            if ((row.changes?.changes as any)?.plan_budget?.status) {
                const statuses: string[] = (row.changes?.changes as any)?.plan_budget?.status;

                if (statuses[1] == 'on_approval') {
                    eventType = t(localeKeys.history.table.bpSentToApproval);
                }
                if (statuses[1] == 'approved') {
                    eventType = t(localeKeys.history.table.bpApproved);
                }

                if (statuses[1] == 'rejected') {
                    eventType = t(localeKeys.history.table.bpDeclined);
                }

                if (statuses[1]?.toString() == 'draft') {
                    eventType = t(localeKeys.history.table.allowChange);
                }

                resultComment = `${localeLevel} ${recordView || obj}`;
                debugComment = false;
            }

            if (debugComment || getValueStore('debugHistory')) {
                let changes = JSON.stringify(
                    row.changes?.changes,
                    null,
                    getValueStore('debugHistoryPrint') ? 2 : 0,
                )
                    .replaceAll('\n', '<br>')
                    .replaceAll(' ', '&nbsp;&nbsp;');
                resultComment += `<br><b>changes.row:</b> ${JSON.stringify(row.changes?.row)}<br><b>changes.changes:</b> ${changes}<br><b>object_type:</b> ${row.object_type}<br><b>operation:</b> ${operation}`;
            }

            data.push({
                key: `${index}_${Math.random()}`,
                date:
                    window.innerWidth > sizes.mobile
                        ? moment((row.timestamp ?? 0) * 1000).format(DateFormatFull)
                        : moment((row.timestamp ?? 0) * 1000).format(DateFormat),
                comment: resultComment,
                eventType: eventType,
                user: user,
            });
        }
    }

    (window as any).modalHistoryTable = {
        ...((window as any).modalHistoryTable ?? {}),
        historyPages,
        historyData,
        data,
        params: props.params,
        loadNext,
    };

    useEffect(() => {
        (window as any).modalHistoryTable = {
            ...((window as any).modalHistoryTable ?? {}),
            page: page,
        };
    }, []);

    return (
        <Table
            ref={tableRef.current}
            className={'ModalHistoryTable'}
            columns={columns(t)}
            dataSource={
                (window as any).modalHistoryTable.loading
                    ? [...data, { key: '', user: '', date: '', eventType: '', comment: '' }]
                    : data
            }
            pagination={false}
            scroll={{ y: (document.querySelector('.ModalHistoryTable')?.getBoundingClientRect().height ?? 200) - 80 }}
        />
    );
};

export default ModalHistoryTable;
