/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface CreateContractorRequest {
  /** Наименование */
  name: string;
  /**
   * Идентификатор типа контрагентов
   * @format uuid
   */
  type_id?: string;
  /** Контакты ПНК */
  related_user?: string;
  /** Список локаций */
  location?: string[];
  /** Рейтинг (1 - Черный, 2 - Желтый, 3 - Зеленый) */
  rating?: 1 | 2 | 3;
  /** Работа / материалы */
  tags?: string[];
  /** Комментарий */
  comment?: string;
  /** Контакты контрагента */
  contacts?: string;
  /** ИНН */
  inn: string;
  /** ОГРН */
  ogrn?: string;
  /** Юридический адрес */
  legal_address?: string;
  /** Почтовый адрес */
  postal_address?: string;
  /** Телефон */
  phone?: string;
  /**
   * Эл. адрес
   * @format email
   */
  email?: string;
  /** Номер документа */
  doc_number?: string;
  /**
   * Дата документа
   * @format date
   */
  doc_date: string;
  documents?: {
    /**
     * Идентификатор загруженного файла
     * @format uuid
     */
    file_id?: string;
  }[];
  /** Список реквизитов */
  requisites?: {
    /** Наименование банка */
    bank_name?: string;
    /** Расчетный счет */
    account?: string;
    /** БИК */
    bik?: string;
    /** Корреспондентский счет */
    correspondent_account?: string;
    /** КПП */
    kpp?: string;
  }[];
}

export interface UpdateContractorRequest {
  /** Наименование */
  name?: string;
  /**
   * Идентификатор типа контрагентов
   * @format uuid
   */
  type_id?: string;
  /** Контакты ПНК */
  related_user?: string;
  /** Список локаций */
  location?: string[];
  /** Рейтинг (1 - Черный, 2 - Желтый, 3 - Зеленый) */
  rating?: 1 | 2 | 3;
  /** Работа / материалы */
  tags?: string[];
  /** Комментарий */
  comment?: string;
  /** Контакты контрагента */
  contacts?: string;
  /** ИНН */
  inn?: string;
  /** ОГРН */
  ogrn?: string;
  /** Юридический адрес */
  legal_address?: string;
  /** Почтовый адрес */
  postal_address?: string;
  /** Телефон */
  phone?: string;
  /**
   * Эл. адрес
   * @format email
   */
  email?: string;
  /** Номер документа */
  doc_number?: string;
  /**
   * Дата документа
   * @format date
   */
  doc_date?: string;
  documents?: {
    /**
     * Идентификатор загруженного файла
     * @format uuid
     */
    file_id?: string;
  }[];
  /** Список реквизитов */
  requisites?: {
    /** Наименование банка */
    bank_name?: string;
    /** Расчетный счет */
    account?: string;
    /** БИК */
    bik?: string;
    /** Корреспондентский счет */
    correspondent_account?: string;
    /** КПП */
    kpp?: string;
  }[];
}

export interface Contractor {
  /**
   * Идентификатор контрагента
   * @format uuid
   */
  id?: string;
  /**
   * Номер контрагента
   * @example 1
   */
  row_number?: number;
  /** Наименование */
  name?: string;
  type?: {
    /**
     * Идентификатор типа контрагентов
     * @format uuid
     */
    id?: string;
    /**
     * Название типа контрагента
     * @example "по счету"
     */
    name?: string;
  };
  /** Контакты ПНК */
  related_user?: string;
  /** Список локаций */
  location?: string[];
  /**
   * Рейтинг (1 - Черный, 2 - Желтый, 3 - Зеленый)
   * @example 1
   */
  rating?: number;
  /** @example "Черный" */
  rating_label?: string;
  /** Работа / материалы */
  tags?: string[];
  /** Комментарий */
  comment?: string;
  /** Контакты контрагента */
  contacts?: string;
  /** ИНН */
  inn?: string;
  /** ОГРН */
  ogrn?: string;
  /** Юридический адрес */
  legal_address?: string;
  /** Почтовый адрес */
  postal_address?: string;
  /** Телефон */
  phone?: string;
  /**
   * Эл. адрес
   * @format email
   */
  email?: string;
  /** Номер документа */
  doc_number?: string;
  /**
   * Дата документа
   * @format date
   */
  doc_date?: string;
  documents?: {
    /**
     * Идентификатор документа
     * @format uuid
     */
    id?: string;
    /** @example "Документ 1.pdf" */
    name?: string;
    /**
     * Ссылка на файл
     * @format url
     */
    link?: string;
    /** @format date-time */
    created_at?: string;
    /** @format date-time */
    updated_at?: string;
  }[];
  /** Список реквизитов */
  requisites?: {
    /**
     * Идентификатор реквизита
     * @format uuid
     */
    id?: string;
    /** Наименование банка */
    bank_name?: string;
    /** Расчетный счет */
    account?: string;
    /** БИК */
    bik?: string;
    /** Корреспондентский счет */
    correspondent_account?: string;
    /** КПП */
    kpp?: string;
    /** @format date-time */
    created_at?: string;
    /** @format date-time */
    updated_at?: string;
  }[];
  /** @format date-time */
  created_at?: string;
  /** @format date-time */
  updated_at?: string;
}

export interface ContractorShort {
  /**
   * Идентификатор контрагента
   * @format uuid
   */
  id?: string;
  /**
   * Номер контрагента
   * @example 1
   */
  row_number?: number;
  /** Наименование */
  name?: string;
  type?: {
    /**
     * Идентификатор типа контрагентов
     * @format uuid
     */
    id?: string;
    /**
     * Название типа контрагента
     * @example "по счету"
     */
    name?: string;
  };
  /** Контакты ПНК */
  related_user?: string;
  /** Список локаций */
  location?: string[];
  /**
   * Рейтинг (1 - Черный, 2 - Желтый, 3 - Зеленый)
   * @example 1
   */
  rating?: number;
  /** @example "Черный" */
  rating_label?: string;
  /** Работа / материалы */
  tags?: string[];
  /** Комментарий */
  comment?: string;
  /** Контакты контрагента */
  contacts?: string;
  /** ИНН */
  inn?: string;
  /** ОГРН */
  ogrn?: string;
  /** Юридический адрес */
  legal_address?: string;
  /** Почтовый адрес */
  postal_address?: string;
  /** Телефон */
  phone?: string;
  /**
   * Эл. адрес
   * @format email
   */
  email?: string;
  /** Номер документа */
  doc_number?: string;
  /**
   * Дата документа
   * @format date
   */
  doc_date?: string;
  documents?: {
    /**
     * Идентификатор документа
     * @format uuid
     */
    id?: string;
    /** @example "Документ 1.pdf" */
    name?: string;
    /**
     * Ссылка на файл
     * @format url
     */
    link?: string;
    /** @format date-time */
    created_at?: string;
    /** @format date-time */
    updated_at?: string;
  }[];
  /** Список реквизитов */
  requisites?: {
    /**
     * Идентификатор реквизита
     * @format uuid
     */
    id?: string;
    /** Наименование банка */
    bank_name?: string;
    /** Расчетный счет */
    account?: string;
    /** БИК */
    bik?: string;
    /** Корреспондентский счет */
    correspondent_account?: string;
    /** КПП */
    kpp?: string;
    /** @format date-time */
    created_at?: string;
    /** @format date-time */
    updated_at?: string;
  }[];
}

export interface MetaData {
  /** @example 1 */
  current_page?: number;
  /** @example 10 */
  per_page?: number;
  /** @example 20 */
  total?: number;
  /** @example 2 */
  last_page?: number;
}

export interface SimpleResponse {
  /** @example true */
  success?: boolean;
}

export interface Error {
  message?: string;
  code?: string;
}

export interface UploadedFile {
  /**
   * Идентификтор файла
   * @format uuid
   */
  id?: string;
  /**
   * Имя файла
   * @example "file.pdf"
   */
  name?: string;
  /**
   * Mime-тип файла
   * @example "application/pdf"
   */
  content_type?: string;
  /** Размер файла в байтах */
  size?: number;
  /**
   * Расширение файла (если удалось определить)
   * @example ".pdf"
   */
  ext?: string;
  /** Название бакета в S3 хранилище */
  bucket?: string;
  /** Путь до файла в S3 */
  path?: string;
  /** Ссылка на файл */
  url?: string;
  /**
   * Дата загрузки файла
   * @format date-time
   */
  created_at?: string;
  /**
   * Дата обновления файла
   * @format date-time
   */
  "updated-at"?: string;
}

export interface ContractorList {
  data?: ContractorShort[];
  meta?: MetaData;
  filters?: {
    types?: {
      /** @format uuid */
      value?: string;
      label?: string;
    }[];
    rating?: {
      value?: number;
      label?: string;
    }[];
  };
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "https://directory-api.develop.pnk.itmegastar.com/directory/api";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

export interface DirectoryList {
  data?: Directory[];
  meta?: MetaData;
}
export type DirectoryPlaneList = Directory[];

export interface Directory {
  /**
   * Идентификатор объекта
   * @format uuid
   */
  id?: string;
  /**
   * Наименование объекта
   * @example "Россия"
   */
  title?: string;
  /**
   * Код объекта
   * @example "ru"
   */
  name?: string;
  /**
   * Статус объекта в справочнике
   * @example "active"
   */
  status?: string;
  /** Дополнительные свойства */
  data?: object;
  /**
   * Порядковый номер объекта в справочнике
   * @example 1
   */
  order?: number;
  /**
   * Время добавления объекта в справочник
   * @format date-time
   */
  created_at?: string;
  /**
   * Время последнего обновления объекта в справочнике
   * @format date-time
   */
  updated_at?: string;
}

/**
 * @title PNK Directory Service
 * @version 1.0.0
 * @license Apache 2.0 (http://www.apache.org/licenses/LICENSE-2.0.html)
 * @termsOfService http://swagger.io/terms/
 * @baseUrl https://directory-api.develop.pnk.itmegastar.com/directory/api
 * @externalDocs https://www.notion.so/megastar/v2-3388738db3264bd7b57b4cfe343e1690
 * @contact <d.kornilov@itmbegastar.com>
 *
 * Сервис справочников (Контрагентов) проекта PNK
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  helpers = {
    /**
     * @description Получить список локаций контрагентов для выпадающего списка
     *
     * @tags Helpers
     * @name ContractorLocationsList
     * @summary Список использованных локаций в контрагентах
     * @request GET:/helpers/contractor-locations
     * @secure
     */
    contractorLocationsList: (
      query?: {
        /** Поиск по наименованию */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: string[];
        },
        Error
      >({
        path: `/helpers/contractor-locations`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Получить список тэгов контрагентов для выпадающего списка
     *
     * @tags Helpers
     * @name ContractorTagsList
     * @summary Список использованных тэгов в контрагентах
     * @request GET:/helpers/contractor-tags
     * @secure
     */
    contractorTagsList: (
      query?: {
        /** Поиск по наименованию */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: string[];
        },
        Error
      >({
        path: `/helpers/contractor-tags`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  files = {
    /**
     * @description Загрузка временного файла в S3 хранилище
     *
     * @tags Files
     * @name UploadCreate
     * @summary Загрузка временного файла
     * @request POST:/files/upload
     * @secure
     */
    uploadCreate: (
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<UploadedFile, Error>({
        path: `/files/upload`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Удаление временного файла из S3 хранилища
     *
     * @tags Файлы
     * @name FilesDelete
     * @summary Удаление временного файла
     * @request DELETE:/files/{id}
     * @secure
     */
    filesDelete: (id: string, params: RequestParams = {}) =>
      this.request<SimpleResponse, Error>({
        path: `/files/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  contractors = {
    /**
     * @description Получить постраничный список контрагентов из справочника
     *
     * @tags Contractors
     * @name ContractorsList
     * @summary Список контрагентов
     * @request GET:/contractors
     * @secure
     */
    contractorsList: (
      query?: {
        /** Поиск по наименованию или началу ИНН */
        search?: string;
        /**
         * Фильтр по тэгам, разделитель "|"
         * @example "Арматура;Бетон"
         */
        tags?: string;
        /** Фильтр по рейтингу (0 - Черный, 1 - Желтый, 2 - Зеленый) */
        rating?: "0" | "1" | "2";
        /**
         * Фильтр по типу контрагента
         * @format uuid
         */
        type_id?: string;
        /**
         * Поиск по списку локаций, разделитель "|"
         * @example "Москва;Самара"
         */
        location?: string;
        /**
         * Номер страницы
         * @default 1
         */
        page?: number;
        /**
         * Кол-во элементов на странице
         * @default 10
         */
        per_page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ContractorList, Error>({
        path: `/contractors`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Создать нового контрагента в справочнике
     *
     * @tags Contractors
     * @name ContractorsCreate
     * @summary Создать контрагента
     * @request POST:/contractors
     * @secure
     */
    contractorsCreate: (data: CreateContractorRequest, params: RequestParams = {}) =>
      this.request<Contractor, Error>({
        path: `/contractors`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Просмотр детальной информации о контрагенте по его идентификатору
     *
     * @tags Contractors
     * @name ContractorsDetail
     * @summary Просмотр контрагента
     * @request GET:/contractors/{id}
     * @secure
     */
    contractorsDetail: (id: string, params: RequestParams = {}) =>
      this.request<Contractor, Error>({
        path: `/contractors/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Обновить данные контрагента в справочнике
     *
     * @tags Contractors
     * @name ContractorsUpdate
     * @summary Обновление контрагента
     * @request PUT:/contractors/{id}
     * @secure
     */
    contractorsUpdate: (id: string, data: UpdateContractorRequest, params: RequestParams = {}) =>
      this.request<Contractor, Error>({
        path: `/contractors/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Удаление контрагента из справочника
     *
     * @tags Contractors
     * @name ContractorsDelete
     * @summary Удалить контрагента
     * @request DELETE:/contractors/{id}
     * @secure
     */
    contractorsDelete: (id: string, params: RequestParams = {}) =>
      this.request<SimpleResponse, Error>({
        path: `/contractors/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  regions = {
    /**
     * @description Получить постраничный список регионов из справочника
     *
     * @tags Регионы
     * @name RegionsList
     * @summary Список регионов
     * @request GET:/regions
     * @secure
     */
    regionsList: (
      query?: {
        /** Поиск по наименованию или коду региона */
        search?: string;
        /** Фильтр по статусу */
        status?: "active" | "archive";
        /** Поле для сортировки */
        sort_by?: "id" | "title" | "name" | "status" | "order" | "created_at" | "updated_at";
        page?:number;
        per_page?: number;
        /**
         * Порядок сортировки (0 - ASC, 1 - DESC)
         * @min 0
         * @max 1
         */
        sort_desc?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<DirectoryList, Error>({
        path: `/regions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Создать новый регион в справочнике
     *
     * @tags Регионы
     * @name RegionsCreate
     * @summary Создать регион
     * @request POST:/regions
     * @secure
     */
    regionsCreate: (
      data: {
        /**
         * Наименование объекта справочника
         * @example "Регион"
         */
        title: string;
        /**
         * Код объекта справочника
         * @example "region"
         */
        name?: string;
        /**
         * Статус объекта в справочнике
         * @default "active"
         */
        status?: "active" | "archive";
        /** Дополнительные свойства объекта */
        data?: object;
        /**
         * Порядок вывода объекта в справочнике
         * @default 0
         */
        order?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<Directory, Error>({
        path: `/regions`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Порядковый номер определяется положением в списке
     *
     * @tags Регионы
     * @name SortCreate
     * @summary Обновление порядковых номеров регионов в справочнике
     * @request POST:/regions/sort
     * @secure
     */
    sortCreate: (
      data: {
        ids?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<DirectoryPlaneList, Error>({
        path: `/regions/sort`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Просмотр детальной информации о регионе по его идентификатору
     *
     * @tags Регионы
     * @name RegionsDetail
     * @summary Просмотр региона
     * @request GET:/regions/{id}
     * @secure
     */
    regionsDetail: (id: string, params: RequestParams = {}) =>
      this.request<Directory, Error>({
        path: `/regions/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Обновить данные региона в справочнике
     *
     * @tags Регионы
     * @name RegionsUpdate
     * @summary Обновление региона
     * @request PUT:/regions/{id}
     * @secure
     */
    regionsUpdate: (
      id: string,
      data: {
        /**
         * Наименование объекта справочника
         * @example "Регион"
         */
        title?: string;
        /**
         * Код объекта справочника
         * @example "region"
         */
        name?: string;
        /**
         * Статус объекта в справочнике
         * @default "active"
         */
        status?: "active" | "archive";
        /** Дополнительные свойства объекта */
        data?: object;
        /**
         * Порядок вывода объекта в справочнике
         * @default 0
         */
        order?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<Directory, Error>({
        path: `/regions/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Удаление региона из справочника
     *
     * @tags Регионы
     * @name RegionsDelete
     * @summary Удалить регион
     * @request DELETE:/regions/{id}
     * @secure
     */
    regionsDelete: (id: string, params: RequestParams = {}) =>
      this.request<SimpleResponse, Error>({
        path: `/regions/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}



