import { Api as ApiDirectory } from './../ApiDirectory';
import { Api as ApiEpo } from './../ApiEpo';
import { Api as ApiHistory } from './../ApiHistory';
import { Api as DocumentsApi } from './../ApiDocuments';
import { Api, ContentType, QueryParamsType } from './Api';
import { checkTimeRefresh, refreshTokenFun } from './refreshToken';
import { getValueStore } from './store';
import {isRu, localeCode} from "../i18n/config";

function getApiAuth(token?: string) {
  return new Api({
    baseUrl: process.env.REACT_APP_API_RBAC_API_URL + '/api',
    baseApiParams: {
      headers: token ? {
        Authorization: `Bearer ${token}`,
        'Accept-Language': localeCode(),
      } : {},
      credentials: 'same-origin',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    },
    customFetch: async (...fetchParams: Parameters<typeof fetch>) => {
      if (checkTimeRefresh()) {
        await refreshTokenFun();
      }

      (fetchParams[1] as any).headers.Authorization = `Bearer ${getValueStore('token')}`;

      const res = await fetch(fetchParams[0], fetchParams[1]);

      /*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
      if (
        (res.status === 401 || res.status === 403) &&
        !['/auth', '/auth/', '/restore_password'].includes(location.pathname.split('?')[0])
      ) {
        if (location.pathname !== '/auth') {
          /*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
          location.href = location.href = '/auth';
        }
        // console.log("reauth");
      }

      return res;
    },
  });
}

function getApiEpo(token?: string) {
  return new ApiEpo({
    baseUrl: process.env.REACT_APP_API_URL + '/api',
    baseApiParams: {
      headers: token ? {
        Authorization: `Bearer ${token}`,
        'Accept-Language': localeCode(),
      } : {},
      credentials: 'same-origin',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    },
    customFetch: async (...fetchParams: Parameters<typeof fetch>) => {
      if (checkTimeRefresh()) {
        await refreshTokenFun();
      }

      (fetchParams[1] as any).headers.Authorization = `Bearer ${getValueStore('token')}`;

      const res = await fetch(fetchParams[0], fetchParams[1]);

      /*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
      if (
        (res.status === 401 || res.status === 403) &&
        !['/auth', '/auth/', '/restore_password'].includes(location.pathname.split('?')[0])
      ) {
        if (location.pathname !== '/auth') {
          /*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
          location.href = '/auth';
        }
        // console.log("reauth");
      }

      return res;
    },
  });
}

function getApiDirectory(token?: string) {
  return new ApiDirectory({
    baseUrl: process.env.REACT_APP_API_DIRECTORY + '/api',
    baseApiParams: {
      headers: token ? {
        Authorization: `Bearer ${token}`,
        'Accept-Language': localeCode(),
      } : {},
      credentials: 'same-origin',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    },
    customFetch: async (...fetchParams: Parameters<typeof fetch>) => {
      if (checkTimeRefresh()) {
        await refreshTokenFun();
      }

      (fetchParams[1] as any).headers.Authorization = `Bearer ${getValueStore('token')}`;

      const res = await fetch(fetchParams[0], fetchParams[1]);

      /*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
      if (
        (res.status === 401 || res.status === 403) &&
        !['/auth', '/auth/', '/restore_password'].includes(location.pathname.split('?')[0])
      ) {
        if (location.pathname !== '/auth') {
          /*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
          location.href = '/auth';
        }
        // console.log("reauth");
      }

      if ((res.status === 401 || res.status === 403) && location.pathname == '/contragents') {
        location.href = location.href = '/profile';
      }

      return res;
    },
  });
}

function getApiHistory(token?: string) {
  return new ApiHistory({
    baseUrl: process.env.REACT_APP_HISTORY_API_URL + '/api',
    baseApiParams: {
      headers: token ? {
        Authorization: `Bearer ${token}`,
        'Accept-Language': localeCode(),
      } : {},
      // headers: {},
      credentials: 'same-origin',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    },
    customFetch: async (...fetchParams: Parameters<typeof fetch>) => {
      if (checkTimeRefresh()) {
        await refreshTokenFun();
      }

      (fetchParams[1] as any).headers.Authorization = `Bearer ${getValueStore('token')}`;

      const res = await fetch(fetchParams[0], fetchParams[1]);

      /*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
      if (res.status == 401 && !['/auth', '/restore_password'].includes(location.pathname.split('?')[0])) {
        console.log('access_token', getValueStore('token'));

        if (location.pathname != '/auth') {
          /*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
          location.href = process.env.REACT_APP_AUTH_REDIRECT + '?redirect=' + window.location.href;
        }
        // console.log("reauth");
      }

      return res;
    },
  });
}

function getApiDocuments(token?: string) {
  return new DocumentsApi({
    baseUrl: process.env.REACT_APP_API_DOCUMENTS + '/api',
    baseApiParams: {
      headers: token ? {
        Authorization: `Bearer ${token}`,
        'Accept-Language': localeCode(),
      } : {},
      // headers: {},
      credentials: 'same-origin',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    },
    customFetch: async (...fetchParams: Parameters<typeof fetch>) => {
      if (checkTimeRefresh()) {
        await refreshTokenFun();
      }

      (fetchParams[1] as any).headers.Authorization = `Bearer ${getValueStore('token')}`;

      const res = await fetch(fetchParams[0], fetchParams[1]);

      /*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
      if (res.status == 401 && !['/auth', '/restore_password'].includes(location.pathname.split('?')[0])) {
        console.log('access_token', getValueStore('token'));

        if (location.pathname != '/auth') {
          /*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
          location.href = process.env.REACT_APP_AUTH_REDIRECT + '?redirect=' + window.location.href;
        }
        // console.log("reauth");
      }

      return res;
    },
  });
}

export let apiAuth = getApiAuth(getValueStore('token') || undefined);
export let api = getApiEpo(getValueStore('token') || undefined);

export let historyApi = getApiHistory(getValueStore('token') || undefined);

export let directoryApi = getApiDirectory(getValueStore('token') || undefined);

export let documentsApi = getApiDocuments(getValueStore('token') || undefined);

export function resetApi(token?: string) {
  apiAuth = getApiAuth(token);
  api = getApiEpo(token);
  historyApi = getApiHistory(token);
  directoryApi = getApiDirectory(token);
  documentsApi = getApiDocuments(token);
}

export const contentFormatters: Record<ContentType, (input: any) => any> = {
  [ContentType.Json]: (input: any) =>
    input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
  [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
  [ContentType.FormData]: (input: any) => {
    if (input instanceof FormData) {
      return input;
    }

    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`,
      );
      return formData;
    }, new FormData());
  },
  [ContentType.UrlEncoded]: (input: any) => toQueryString(input),
};

function encodeQueryParam(key: string, value: any) {
  const encodedKey = encodeURIComponent(key);
  return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
}

function addQueryParam(query: QueryParamsType, key: string) {
  return encodeQueryParam(key, query[key]);
}

function addArrayQueryParam(query: QueryParamsType, key: string) {
  const value = query[key];
  return value.map((v: any) => encodeQueryParam(key, v)).join('&');
}

function toQueryString(rawQuery?: QueryParamsType): string {
  const query = rawQuery || {};
  const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
  return keys
    .map((key) => (Array.isArray(query[key]) ? addArrayQueryParam(query, key) : addQueryParam(query, key)))
    .join('&');
}
