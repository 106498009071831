/*--Работы для оффлаин режим---*/
export const DB_NAME = 'contracts';
export const DB_VERSION = 1;

export let db: IDBDatabase | null = null;

const openDB = (): Promise<IDBDatabase> => {

  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onerror = (event) => {
      console.error('Error opening IndexedDB:', event);
      reject(event);
    };

    request.onsuccess = (event) => {
      db = request.result;
      resolve(db);
    };
    // Можно дополнять;
    request.onupgradeneeded = (event) => {
      db = request.result;
      if (!db.objectStoreNames.contains('contractStatusesList')) {
        db.createObjectStore('contractStatusesList', { keyPath: 'id', autoIncrement: true });
      }
      if (!db.objectStoreNames.contains('contractsProjectList')) {
        db.createObjectStore('contractsProjectList', { keyPath: 'id', autoIncrement: true });
      }
      if (!db.objectStoreNames.contains('contractsList')) {
        db.createObjectStore('contractsList', { keyPath: 'id', autoIncrement: true });
      }
    };
  });
};

// Универсальная функция для обработк дата;
export const addDataToIndexedDB = async (storeName: string, data: any) => {
  try {
    if (!db) {
      await openDB();
    }
    const transaction = db!.transaction([storeName], 'readwrite');
    const store = transaction.objectStore(storeName);
    store.clear();
    data.forEach((item: any) => store.add(item));
  } catch (e) {
    console.log('addDataToIndexedDB', e);
    deleteDB();
  }
}

// Универсальная функция;
export const getDataFromIndexedDB = async (storeName: string): Promise<any[]> => {
  if (!db) {
    await openDB();
  }
  return new Promise((resolve, reject) => {
    const transaction = db!.transaction([storeName], 'readonly');
    const store = transaction.objectStore(storeName);
    const request = store.getAll();

    request.onsuccess = () => {
      resolve(request.result);
    };

    request.onerror = (event) => {
      console.error(`Error fetching data from store "${storeName}"`, event);
      reject(event);
    };
  });
};

export const clearStore = async (storeName: string) => {
  if (!db) {
    await openDB();
  }
  const transaction = db!.transaction([storeName], 'readwrite');
  const store = transaction.objectStore(storeName);
  store.clear();
};

export const deleteDB = () => {
  indexedDB.deleteDatabase(DB_NAME);
}
