import { RecordStatus, TableRecord } from '../canvas_table/components/TableRecord';
import { allowMoveApproveBudget, allowSendApproveBudget } from './allowEditCell';
import { getResultRows } from './getResultRows';
import { getWData } from './getWData';
import { getPassportCache } from './loadData';
import { syncData } from './syncData';

export function refreshRowStatusNoApprove(record: TableRecord) {
  console.log('refreshRowStatusNoApprove', record);
  record.cns_allow_send_approve = true;

  if (record.cns_row_type == 'nomenclature') {
    const group = getWData().rows2.find((row) => row.cns_group_id == record.cns_group_id);
    if (group) {
      refreshRowStatusNoApprove(group);
    }
  }

  if (record.cns_row_type == 'work_type') {
    // Проверка на возможность отправить вид работ на согласование
    const items = getWData().rows3.filter(
      (row) =>
        row.cns_group_id == record.cns_group_id &&
        [RecordStatus.BUDGET_ORIENTED_APPROVED, RecordStatus.BUDGET_PLAN_REJECTED].includes(row.cns_status),
    );
    if (items.length) {
      record.cns_status = RecordStatus.BUDGET_ORIENTED_APPROVED;
      record.cns_allow_send_approve = true;
    }

    // Проверка на возможность согласовать вид работ
    const items2 = getWData().rows3.filter(
      (row) =>
        row.cns_group_id == record.cns_group_id && [RecordStatus.BUDGET_PLAN_UNDER_REVIEW].includes(row.cns_status),
    );
    if (items2.length) {
      record.cns_status = RecordStatus.BUDGET_PLAN_UNDER_REVIEW;
    }

    const section = getWData().rows.find((row) => row.cns_id == record.cns_section_id);
    if (section) {
      refreshRowStatusNoApprove(section);
    }
  }

  if (record.cns_row_type == 'section') {
    // Проверка на возможность отправить раздел на согласование
    const items = getWData().rows2.filter(
      (row) => row.cns_section_id == record.cns_section_id && row.cns_allow_send_approve,
    );
    if (items.length) {
      record.cns_status = RecordStatus.BUDGET_ORIENTED_APPROVED;
      record.cns_allow_send_approve = true;
    }

    // Проверка на возможность согласовать вид работ
    const items2 = getWData().rows2.filter(
      (row) =>
        row.cns_section_id == record.cns_section_id && [RecordStatus.BUDGET_PLAN_UNDER_REVIEW].includes(row.cns_status),
    );
    if (items2.length) {
      record.cns_status = RecordStatus.BUDGET_PLAN_UNDER_REVIEW;
    }
  }
}

/**
 * Функиця обновления статуса строк вниз по дереву вложенности.
 * @param record
 * @param action
 */
export function refreshRowStatusDown(record: TableRecord, action: 'send_approve' | 'approve' | 'reject' | 'unlock') {
  console.log('refreshRowStatusDown', record, action);
  let items: TableRecord[] = [];
  if (record.cns_row_type == 'work_type') {
    items = getWData().rows3.filter((row) => row.cns_group_id == record.cns_group_id);
  }
  if (record.cns_row_type == 'section') {
    items = getWData().rows2.filter((row) => row.cns_section_id == record.cns_section_id);
  }
  if (record.cns_row_type == 'project') {
    items = getWData().rows;
  }
  switch (action) {
    case 'send_approve':
      record.cns_status = RecordStatus.BUDGET_PLAN_UNDER_REVIEW;
      record.cns_allow_send_approve = false;
      record.cns_has_sended_approve = true;

      for (let item of items.filter((item) => item.cns_allow_send_approve)) {
        item.cns_status = RecordStatus.BUDGET_PLAN_UNDER_REVIEW;
        item.cns_allow_send_approve = false;
        record.cns_has_sended_approve = true;
        refreshRowStatusDown(item, action);
      }
      break;
    case 'approve':
      record.cns_status = RecordStatus.BUDGET_PLAN_APPROVED;
      record.cns_allow_send_approve = false;

      for (let item of items.filter((item) => item.cns_status == RecordStatus.BUDGET_PLAN_UNDER_REVIEW)) {
        item.cns_status = RecordStatus.BUDGET_PLAN_APPROVED;
        item.cns_allow_send_approve = false;
        refreshRowStatusDown(item, action);
      }
      break;
    case 'reject':
      record.cns_status = RecordStatus.BUDGET_PLAN_REJECTED;
      record.cns_allow_send_approve = true;

      for (let item of items.filter((item) => item.cns_status == RecordStatus.BUDGET_PLAN_UNDER_REVIEW)) {
        item.cns_status = RecordStatus.BUDGET_PLAN_REJECTED;
        item.cns_allow_send_approve = true;
        refreshRowStatusDown(item, action);
      }
      break;
    case 'unlock':
      record.cns_status = RecordStatus.BUDGET_PLAN_REJECTED;
      record.cns_allow_send_approve = true;

      items
        .filter((row) => row.cns_status != RecordStatus.BUDGET_PLAN_APPROVED)
        .forEach((item) => {
          item.cns_status = RecordStatus.BUDGET_PLAN_REJECTED;
          item.cns_allow_send_approve = true;
          refreshRowStatusDown(item, action);
        });
      break;
  }
}

export function refreshRowAllowSendBudgetUp(record: TableRecord) {
  console.log('refreshRowAllowSendBudgetUp', record);

  if (record.cns_row_type == 'nomenclature') {
    record.cns_allow_send_approve = allowSendApproveBudget(record);

    const group = getWData().rows2.find((row) => row.cns_group_id == record.cns_group_id);
    if (group) {
      group.cns_allow_send_approve = !!getWData().rows3.find(
        (item) => item.cns_group_id == record.cns_group_id && allowSendApproveBudget(item),
      );

      const section = getWData().rows.find((row) => row.cns_section_id == group.cns_section_id);
      if (section) {
        section.cns_allow_send_approve = !!getWData().rows3.find(
          (item) => item.cns_section_id == group.cns_section_id && allowSendApproveBudget(item),
        );
      }
    }
  }

  if (record.cns_row_type == 'work_type') {
    record.cns_allow_send_approve = allowSendApproveBudget(record);

    const section = getWData().rows.find((row) => row.cns_section_id == record.cns_section_id);
    if (section) {
      section.cns_allow_send_approve = !!getWData().rows2.find(
        (item) => item.cns_section_id == record.cns_section_id && allowSendApproveBudget(item),
      );
    }
  }
}

export function refreshStatusRecords() {
  console.log('refreshStatusRecords');

  for (let group of getWData().rows2) {
    const items = getWData().rows3.filter((row) => row.cns_group_id == group.cns_group_id);
    const allowSendApprove = items.find((item) => allowSendApproveBudget(item));
    const canActionApprove = items.find((item) => item.cns_status == RecordStatus.BUDGET_PLAN_UNDER_REVIEW);
    const hasApproved = items.find((item) => item.cns_status == RecordStatus.BUDGET_PLAN_APPROVED);

    group.cns_allow_send_approve = !!allowSendApprove;

    group.cns_status = RecordStatus.BUDGET_ORIENTED_APPROVED;
    if (hasApproved) {
      group.cns_status = RecordStatus.BUDGET_PLAN_APPROVED;
    }
    if (canActionApprove) {
      group.cns_status = RecordStatus.BUDGET_PLAN_UNDER_REVIEW;
    }
  }

  for (let section of getWData().rows) {
    const items = getWData().rows2.filter((row) => row.cns_section_id == section.cns_section_id);
    const allowSendApprove = !!items.find((item) => allowSendApproveBudget(item));
    const canActionApprove = !!items.find((item) => item.cns_status == RecordStatus.BUDGET_PLAN_UNDER_REVIEW);
    const hasApproved = items.find((item) => item.cns_status == RecordStatus.BUDGET_PLAN_APPROVED);

    section.cns_allow_send_approve = allowSendApprove;

    if (getPassportCache().guideline_budget_confirmed) {
      section.cns_status = RecordStatus.BUDGET_ORIENTED_APPROVED;
      if (hasApproved) {
        section.cns_status = RecordStatus.BUDGET_PLAN_APPROVED;
      }
      if (canActionApprove) {
        section.cns_status = RecordStatus.BUDGET_PLAN_UNDER_REVIEW;
      }
    }
  }

  getWData().hasAllowMoveOfApprove = !!getWData().rows.find((e) => allowMoveApproveBudget(e));
}

export function sendToApprove(currentRow: TableRecord) {
  console.log('sendToApprove', currentRow);

  currentRow.cns_status = RecordStatus.BUDGET_PLAN_UNDER_REVIEW;
  currentRow.cns_allow_send_approve = false;

  refreshRowStatusDown(currentRow, 'send_approve');
  refreshStatusRecords();

  getWData().resultRows = getResultRows();
  getWData().grid!.records = getWData().resultRows;
  getWData().grid!.invalidate();

  syncData.sendToApproveBudgetPlan(currentRow);
}

export function refreshRowStatusApproveUp(item: TableRecord) {
  item.cns_status = RecordStatus.BUDGET_PLAN_APPROVED;

  const groups = getWData().rows2.filter((row) => row.cns_group_id == item.cns_group_id);
  groups.forEach((group) => {
    if (
      getWData().rows3.filter((row) => row.cns_group_id == group.cns_group_id && row.cns_allow_send_approve).length == 0
    ) {
      group.cns_status = RecordStatus.BUDGET_PLAN_APPROVED;
    }
  });

  const section = getWData().rows.find((row) => row.cns_section_id == item.cns_section_id);
  if (
    section &&
    getWData().rows3.filter((row) => row.cns_section_id == section.cns_section_id && row.cns_allow_send_approve)
      .length == 0
  ) {
    section.cns_status = RecordStatus.BUDGET_PLAN_APPROVED;
  }
}

export function approveRecords(ids: string[]) {
  console.log('approveRecords', ids);

  for (const id of ids) {
    const section = getWData().rows.find((row) => row.cns_id == id || row.cns_section_id == id);
    const group = getWData().rows2.find((row) => row.cns_id == id || row.cns_group_id == id);
    const item = getWData().rows3.find((row) => row.cns_id == id || row.cns_nomenclature_id == id);

    if (item) {
      item.cns_status = RecordStatus.BUDGET_PLAN_APPROVED;
      item.cns_allow_send_approve = false;
    } else if (group) {
      group.cns_status = RecordStatus.BUDGET_PLAN_APPROVED;
      group.cns_allow_send_approve = false;
      refreshRowStatusDown(group, 'approve');
    } else if (section) {
      section.cns_status = RecordStatus.BUDGET_PLAN_APPROVED;
      section.cns_allow_send_approve = false;
      refreshRowStatusDown(section, 'approve');
    }
  }

  refreshStatusRecords();
}

export function disapproveRows(ids: string[]) {
  for (const id of ids) {
    const section = getWData().rows.find((row) => row.cns_section_id == id);
    const group = getWData().rows2.find((row) => row.cns_group_id == id);
    const item = getWData().rows3.find((row) => row.cns_id == id);

    if (item) {
      item.cns_status = RecordStatus.BUDGET_PLAN_REJECTED;
      item.cns_allow_send_approve = true;
      refreshRowStatusDown(item, 'reject');
    } else if (group) {
      group.cns_status = RecordStatus.BUDGET_PLAN_REJECTED;
      group.cns_allow_send_approve = true;
      refreshRowStatusDown(group, 'reject');
    } else if (section) {
      section.cns_status = RecordStatus.BUDGET_PLAN_REJECTED;
      section.cns_allow_send_approve = true;
      refreshRowStatusDown(section, 'reject');
    }
  }

  refreshStatusRecords();
}
