import { useQuery } from 'react-query';
import { directoryApi } from '../shared/api_client';
import { getDirectoryRegionsListDb, syncDirectoryRegions } from '../indexedb';
import { message } from 'antd';
import tokenActions, { AccessItem } from './tokenActions';

export interface DirectoryRegionsRequestParams {
  status?: 'active' | 'archive',
  sort_by?: 'id' | 'title' | 'name' | 'status' | 'order' | 'created_at' | 'updated_at',
  page?: number,
  per_page?: number,
  search?: string
}

export const useDirectoryRegions = ({ status, sort_by, page, per_page, search }: DirectoryRegionsRequestParams) => {
  const {
    data: directoryRegionsList,
    error: directoryRegionsListError,
    isLoading: directoryRegionsListLoading,
    refetch,
  } = useQuery({
    queryKey: ['directoryRegionsList', status, search, sort_by, page, per_page],
    queryFn: async () => {
      if (tokenActions.directoryRegions == AccessItem.NONE) {
        return [];
      }
      try {
        const res = await directoryApi.regions.regionsList({
          status: status,
          sort_by: sort_by,
          search: search,
          page: page,
          per_page: 1000000,
        }).then((e) => {
          return e.data;
        });

        await syncDirectoryRegions(res.data ?? []);

        return res.data ?? [];
      } catch (e) {
        message.error('Не удалось получить актуальный список проектов');

        return await getDirectoryRegionsListDb();
      }
    },
    keepPreviousData: true,
  });

  return {
    refetch,
    directoryRegionsList,
    directoryRegionsListError,
    directoryRegionsListLoading,
  };
};
