import tokenActions, { AccessItem } from '../../actions/tokenActions';
import dateFormatter from '../../shared/dateFormat';
import { getEmptyTableRecord, RecordStatus, TableRecord } from '../canvas_table/components/TableRecord';
import { getWData } from './getWData';
import { getPassportCache } from './loadData';
import { localeKeys } from '../../i18n/localeKeys';

enum ModalAddRowOptions {
  SHOW_HISTORY = 'SHOW_HISTORY',
  IMPORT_XLSX = 'IMPORT_XLSX',
  DOWNLOAD_XLSX = 'DOWNLOAD_XLSX',
  ADD_SECTION = 'ADD_SECTION',
  ADD_GROUP = 'ADD_GROUP',
  ADD_NOMENCLATURA = 'ADD_NOMENCLATURA',
  ADD_NOMENCLATURA2 = 'ADD_NOMENCLATURA2',
  ADD_NOMENCLATURA3 = 'ADD_NOMENCLATURA3',
  ADD_NOMENCLATURA3_NEXT = 'ADD_NOMENCLATURA3_NEXT',
  ADD_NOMENCLATURA4_NEXT = 'ADD_NOMENCLATURA4_NEXT',
  ADD_REMO_ROW3 = 'ADD_REMO_ROW3',
  REMOVE_SECTION = 'REMOVE_SECTION',
  REMOVE_GROUP = 'REMOVE_GROUP',
  REMOVE_NOMENCLATURA = 'REMOVE_NOMENCLATURA',
  HISTORY = 'HISTORY',
  RENAME_GROUP = 'RENAME_GROUP',
  RENAME_SECTION = 'RENAME_SECTION',
  UNLOCK_ROW = 'UNLOCK_ROW',
}

export function getResultRows(): TableRecord[] {
  const expandedLevel1 = getWData().expandedLevel1;
  const expandedLevel2 = getWData().expandedLevel2;

  console.time('getResultRows');
  let resultMap: { [x: string]: TableRecord } = {};

  for (let item of getWData().rows) {
    resultMap[`section_${item.cns_number}`] = item;
  }

  for (let lvl1Id of [...expandedLevel1].reverse()) {
    const rowsResult = [...getWData().rows2].filter((e) => {
      return e.cns_parent_id.includes(lvl1Id) || expandedLevel2.includes(`${e.cns_section_id}_${e.cns_parent_id}`);
    });

    for (let row of rowsResult) {
      resultMap[`lel1_${row.cns_number}`] = row;
    }
  }

  for (let lvl2Id of [...expandedLevel2].reverse()) {
    const rowsResult = [...getWData().rows3].filter((e) => {
      return `${e.cns_section_id}_${e.cns_group_id}` == lvl2Id;
    });

    for (let row of rowsResult) {
      resultMap[`lvl2_${row.cns_number}`] = row;


    }
  }

  /* Чистка тех видов работ, которые не должны показываться из-за нарушения вложенности (родитель не виден, а сам вид работ помечен как видимый) */
  let arrayItemsIds = Object.values(resultMap).filter(e => e.cns_row_type == 'work_type').map(e => e.cns_group_id);
  for (let key in resultMap) {
    const item = resultMap[key];

    if (item.cns_row_type == 'work_type' && item.cns_level > 2 && !arrayItemsIds.includes(item.cns_parent_id2) && !item.cns_id.includes('new')) {
      console.log({ key, item });
      delete resultMap[key];
    }
  }

  arrayItemsIds = Object.values(resultMap).filter(e => e.cns_row_type == 'work_type').map(e => e.cns_group_id);
  for (let key in resultMap) {
    const item = resultMap[key];

    if (item.cns_row_type == 'nomenclature' && !arrayItemsIds.includes(item.cns_parent_id2)) {
      console.log({ key, item });
      delete resultMap[key];
    }
  }

  console.timeEnd('getResultRows');

  const result = Object.values(resultMap);
  console.log({ resultMap });

  result.sort((e1, e2) => {
    const [c1, t1, n1] = e1.cns_number.split('.');
    const [c2, t2, n2] = e2.cns_number.split('.');

    if (Number.parseInt(c1) > Number.parseInt(c2)) {
      return 1;
    } else if (Number.parseInt(c1) < Number.parseInt(c2)) {
      return -1;
    } else if (Number.parseInt(t1) > Number.parseInt(t2)) {
      return 1;
    } else if (Number.parseInt(t1) < Number.parseInt(t2)) {
      return -1;
    } else if (Number.parseInt(n1) > Number.parseInt(n2)) {
      return 1;
    } else if (Number.parseInt(n1) < Number.parseInt(n2)) {
      return -1;
    }

    return 0;
  });

  const appendRows = Math.max(Math.round((window.innerHeight - result.length * 50) / 50), 0) + 3;

  for (let i = 0; i < appendRows; i++) {
    result.push({ ...getEmptyTableRecord(), cns_level: -1 });
  }

  return [
    {
      cns_id: '',
      cns_level: 0,
      cns_row_type: 'project',
      cns_parent_id: '-1',
      cns_parent_id2: '-1',
      cns_parent_number: '',
      cns_status: RecordStatus.BUDGET_ORIENTED_APPROVED,
      cns_has_sended_approve: false,
      cns_allow_send_approve: false,
      cns_number: '',
      cns_title: getWData().t(localeKeys.table.columns.total),
      cns_ed_izm: '',
      cns_budget_plan_size: '',
      cns_budget_plan_price: '',
      cns_budget_plan_sum_wat: getWData().resultRowValues.cns_budget_plan_sum_wat,
      cns_budget_plan_far: getWData().resultRowValues.cns_budget_plan_far,
      cns_budget_plan_far2: getWData().resultRowValues.cns_budget_plan_far2,
      cns_budget_fakt_size: getWData().resultRowValues.cns_budget_fakt_size,
      cns_budget_fakt_price: '',
      cns_budget_fakt_sum_wat: getWData().resultRowValues.cns_budget_fakt_sum_wat,
      cns_budget_fakt_far: getWData().resultRowValues.cns_budget_fakt_far,
      cns_budget_fakt_last_change: '',

      cns_contact_date_start: '',
      cns_contact_date_end: '',
      cns_contact_date: '',
      cns_contracts: [],
      cns_contractors: [],
      cns_responsibles: [],

      cns_section_id: '',
      cns_group_id: '',
      cns_plane_date: '',
      cns_plane_size: '',
      cns_plane_date_start: (getPassportCache().start_date || ''),
      cns_plane_date_end: (getPassportCache().end_date || ''),
      cns_nomenclature_id: '',

      cns_invoice_count: '',
      cns_invoice_date: '',
      cns_invoice_size: '' as any,
      cns_invoice_price: '' as any,
      cns_invoice_sum: '' as any,
      cns_invoice_status: '',
      cns_invoices: [],

      cns_otkl_sum: '' as any,
      cns_otkl_percent: '' as any,

      cns_percent_payment: '' as any,
      cns_payment_future: '' as any,

      cns_well_bill_percent: '' as any,
      cns_well_bill_count: '' as any,
      cns_well_bill_size: '',
      cns_well_bill_delivery_date: '',
      cns_well_bill_delivery_sum: '' as any,
      cns_well_bill_doc_number: '',
      cns_way_bills: [],

      cns_bill_count: 0,
      cns_bill_number: '',
      cns_bill_date: '',
      cns_bill_size: '' as any,
      cns_bill_price: '' as any,
      cns_bill_sum: '' as any,
      cns_bills: [],
    },
    ...result,
  ];
}

(window as any).getResultRows = getResultRows;

export function getModalDotsItems(record?: TableRecord) {
  // console.log('getModalDotsItems', record)

  let items = getWData().rows3;

  if (record) {
    if (record.cns_row_type == 'work_type') {
      items = items.filter(e => e.cns_parent_id.includes(record.cns_group_id));
    }
    if (record.cns_row_type == 'section') {
      items = items.filter(e => e.cns_section_id == record.cns_section_id);
    }
  }

  const itemsStatuses = items.map(e => e.cns_status);

  const unlockItems = tokenActions.budgetPlanApprove == AccessItem.ALLOW
  // && itemsStatuses.find(e => e == RecordStatus.BUDGET_PLAN_REJECTED)
  && itemsStatuses.find(e => e == RecordStatus.BUDGET_PLAN_APPROVED)
    ? ModalAddRowOptions.UNLOCK_ROW : undefined;

  const unlockItem = tokenActions.budgetPlanApprove == AccessItem.ALLOW
  && record?.cns_status == RecordStatus.BUDGET_PLAN_APPROVED
    ? ModalAddRowOptions.UNLOCK_ROW : undefined;

  const historyItem = tokenActions.history == AccessItem.ALLOW ? ModalAddRowOptions.SHOW_HISTORY : undefined;

  switch (record?.cns_row_type) {
    case 'project':
      return [
        historyItem,
      ];
    case 'section':
      if (getPassportCache().status != 'active') {
        return [
          ModalAddRowOptions.DOWNLOAD_XLSX,
          historyItem,
        ];
      }

      if (tokenActions.budgetPlan != AccessItem.EDIT) {
        return [
          ModalAddRowOptions.DOWNLOAD_XLSX,
          unlockItems,
          historyItem,
        ];
      }

      const r3_section = getWData().rows3.filter(row => row.cns_section_id == record.cns_section_id);

      if (r3_section.find(row => ([RecordStatus.BUDGET_ORIENTED_APPROVED, RecordStatus.BUDGET_PLAN_REJECTED].includes(row.cns_status))) || !r3_section.length) {
        return [
          ModalAddRowOptions.ADD_SECTION,
          ModalAddRowOptions.REMOVE_SECTION,
          ModalAddRowOptions.RENAME_SECTION,
          ModalAddRowOptions.IMPORT_XLSX,
          ModalAddRowOptions.DOWNLOAD_XLSX,
          ModalAddRowOptions.ADD_GROUP,
          // ModalAddRowOptions.ADD_NOMENCLATURA3,
          unlockItems,
          historyItem,
        ].filter(e => e);
      } else if (r3_section.length && r3_section.filter(row => row.cns_status == RecordStatus.BUDGET_PLAN_UNDER_REVIEW || row.cns_status == RecordStatus.BUDGET_PLAN_APPROVED).length == r3_section.length) {
        return [
          ModalAddRowOptions.ADD_SECTION,
          ModalAddRowOptions.REMOVE_SECTION,
          ModalAddRowOptions.RENAME_SECTION,
          ModalAddRowOptions.DOWNLOAD_XLSX,
          unlockItems,
          historyItem,
        ];
      }

      return [
        ModalAddRowOptions.DOWNLOAD_XLSX,
        unlockItems,
        historyItem,
      ];
    case 'work_type':
      if (getPassportCache().status != 'active') {
        return [
          historyItem,
          // ModalAddRowOptions.DOWNLOAD_XLSX,
        ];
      }

      const r3_group = getWData().rows3.filter(row => row.cns_parent_id.includes(record.cns_group_id));

      if ((r3_group.find(row => ([RecordStatus.BUDGET_ORIENTED_APPROVED, RecordStatus.BUDGET_PLAN_REJECTED].includes(row.cns_status)))
          || !r3_group.length)
        && tokenActions.budgetPlan == AccessItem.EDIT
      ) {
        return [
          ModalAddRowOptions.ADD_NOMENCLATURA,
          ModalAddRowOptions.ADD_NOMENCLATURA2,
          ModalAddRowOptions.ADD_NOMENCLATURA3,
          process.env.REACT_APP_DEEP_WORK_TYPE == 'true' ? ModalAddRowOptions.ADD_GROUP : undefined,
          ModalAddRowOptions.REMOVE_GROUP,
          ModalAddRowOptions.RENAME_GROUP,
          unlockItems,
          historyItem,
        ].filter(e => e);
      } else if (r3_group.length
        && r3_group.filter(row => row.cns_status == RecordStatus.BUDGET_PLAN_UNDER_REVIEW || row.cns_status == RecordStatus.BUDGET_PLAN_APPROVED).length == r3_group.length
      ) {
        return [
          // ModalAddRowOptions.DOWNLOAD_XLSX,
          unlockItems,
          historyItem,
        ];
      } else if (r3_group.length) {
        return [
          // ModalAddRowOptions.DOWNLOAD_XLSX,
          unlockItems,
          historyItem,
        ];
      }


      return [];
    case 'nomenclature':
      if (getPassportCache().status != 'active') {
        return [
          historyItem,
        ];
      }

      if (tokenActions.budgetPlan != AccessItem.EDIT) {
        return [
          unlockItem,
          historyItem,
        ];
      }

      if ([RecordStatus.BUDGET_PLAN_REJECTED, RecordStatus.BUDGET_ORIENTED_APPROVED].includes(record.cns_status ?? RecordStatus.BUDGET_PLAN_UNDER_REVIEW))
        return [
          ModalAddRowOptions.REMOVE_NOMENCLATURA,
          ModalAddRowOptions.ADD_NOMENCLATURA3_NEXT,
          ModalAddRowOptions.ADD_NOMENCLATURA4_NEXT,
          unlockItem,
          historyItem,
        ];
  }

  return [
    unlockItem,
    historyItem,
  ];
}
