import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { getEmptyTableRecord, TableRecord, TableRecordContragent } from '../TableRecord';
import { Button, Collapse, CollapseProps, Modal, notification } from 'antd';
import { getWData } from '../../../table_extends/getWData';
import dateFormat, { numberFormatter } from '../../../../shared/dateFormat';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { stopEvent } from '../../../../shared/stopEvent';
import DownloadFile from '../../../../shared/DownloadFile';
import { api } from '../../../../shared/api_client';
import { getLoadDataInfo, getPassportCache } from '../../../table_extends/loadData';
import tokenActions, { AccessItem } from '../../../../actions/tokenActions';
import { DocumentType, updateDocumentDb } from '../../../../indexedb';
import { ProjectError } from '../../../../ApiEpo';
import ModalErrorConfirm from './modal_error_confirm';
import { getErrorFieldPrefics, TypeModalEnum } from '../utils/getErrorFieldPrefics';
import { ErrorTypeEnum } from '../utils/error_type';

const ModalViewContract: FunctionComponent<{
    record: TableRecord,
    isOpen: boolean,
    onClose: () => void,
    onRemoveItem: (itemId: string) => void,
    allowRemove: boolean,
    type: TypeModalEnum,
    errors: ProjectError[],
}> = (props) => {
    const [refresh, setRefresh] = useState(0);
    // console.log('ModalViewContract')
    let title = getPassportCache().title;

    const preficsErrorField = getErrorFieldPrefics(props.type);

    const allContractLines = useMemo(() => {
        return getWData().rows3.reduce((acc, record) => {
            acc.push(...record.cns_contracts.map(e => ({ ...e, record: record })));
            return acc;
        }, [] as (TableRecordContragent & { record: TableRecord })[]);
    }, [props.record.cns_contracts]);

    const items: CollapseProps['items'] = (props.record.cns_contractors ?? [])
        .filter(contragent => props.record.cns_contracts.filter(e => e.contragent_id === contragent.value).length > 0)
        .map((contragent) => {

            const contracts = props.record.cns_contracts
                .filter(e => e.contragent_id === contragent.value)
                .map(contract => {
                    return ({
                        ...contract,
                        line: props.record,
                    });
                });

            const contractsSizes = contracts.reduce((acc, contract) => acc + Number(contract.size ?? 0), 0);
            const contractsSum = contracts.reduce((acc, contract) => acc + Number(contract.sum_wat ?? 0), 0);
            const contractsPrices = contractsSum / (contractsSizes || 1);

            const errorsByContragent = props.errors.filter(e => props.type == TypeModalEnum.contracts && contracts.map(e => e.id).includes(e.error?.contract?.id ?? ''));
            const errorsByContragentNoClosed = errorsByContragent.find(e => !e.closed_at);

            const contractsItems: CollapseProps['items'] = contracts.map(contract => {
                const allLinesContract = allContractLines.filter(line => line.document_id == contract.document_id);

                let errors = props.errors.filter(e => props.type == TypeModalEnum.contracts && (e.error as any)?.contract?.id == contract.id);
                errors.sort((e1, e2) => e1.type == 'contract_over_error' ? 1 : -1);
                const errorsByContract = errors.filter(e => !e.closed_at);
                const errorsByContractClosed = errors.filter(e => e.closed_at);

                const isErrorSize = errors.find(e => [ErrorTypeEnum.CONTRACT_OVER_ERROR, ErrorTypeEnum.INVOICE_OVER_ERROR, ErrorTypeEnum.WAYBILL_OVER_ERROR, ErrorTypeEnum.BILL_OVER_ERROR].includes(e.type as ErrorTypeEnum));
                const isErrorWithDates = errors.find(e => e.type == ErrorTypeEnum.CONTRACT_WORK_START_DATE_ERROR || e.type == ErrorTypeEnum.CONTRACT_WORK_END_DATE_ERROR);

                let hasStatusWrapClass;

                contracts.some((it) => {
                    //  console.log('contracts',it);
                    let errorsWrap = errorsByContract.find(e => e.nomenclature_id == it.line.cns_nomenclature_id)
                        || errorsByContractClosed.find(e => e.nomenclature_id == it.line.cns_nomenclature_id);
                    if (errorsWrap) {
                        if (it.status !== 'signed') {
                            hasStatusWrapClass = errorsWrap.closed_at ? 'has_collapse_approval_by_spm1 has_collapse_warning' : 'has_collapse_approval_by_spm1 has_collapse_error';
                        } else {
                            hasStatusWrapClass = errorsWrap.closed_at ? 'has_collapse_warning' : 'has_collapse_error';
                        }

                        return true;
                    } else if (it.status !== 'signed') {
                        hasStatusWrapClass = 'has_collapse_approval_by_spm1';
                        return true;
                    }
                    return false;
                });

                return {
                    className: hasStatusWrapClass,
                    key: `contract_${contract.id}`,
                    label: <div id={`contracts_wrap_${contract.id}`} className={'modal_view_contracts__record'}>
                        <div className={'modal_view_contracts__record_title'}>
                            <span className={'title_contract'}
                                  style={{ textDecoration: contract.error_action ? 'line-through' : '' }}>Контракт № {contract.doc_number} от {dateFormat(contract.doc_date)}</span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {numberFormatter(contract.size)} {props.record.cns_ed_izm} x {numberFormatter(contract.price)} ₽
                            = {numberFormatter(contract.sum_wat)} ₽
                        </div>
                        <div className={'modal_view_contracts__record_title2'}>
                            <span>с {dateFormat(contract.date_start)} до {dateFormat(contract.date_end)}</span>
                            {props.allowRemove && props.type == 'contracts' && <DeleteOutlined
                                className={'modal_view_contracts__record_delete'}
                                onClick={(e) => {
                                    props.onRemoveItem(contract.id);
                                    stopEvent(e);
                                }} />}
                        </div>
                    </div>,
                    children: <div>
                        {allLinesContract.map(line => {
                            let errors = errorsByContract.filter(e => e.nomenclature_id == line.record.cns_nomenclature_id);
                            if (!errors.length) {
                                errors = errorsByContractClosed.filter(e => e.nomenclature_id == line.record.cns_nomenclature_id);
                            }
                            const hasErrorSize = errors.filter(e => e.error?.fields?.includes(`${preficsErrorField}volume`)).length;
                            const hasErrorPrice = errors.filter(e => e.error?.fields?.includes(`${preficsErrorField}price`)).length;
                            const hasErrorSum = errors.filter(e => e.error?.fields?.includes(`${preficsErrorField}amount`)).length;
                            const classNameError = !!errors[0]?.closed_at ? 'has_warning' : 'has_error';
                            // on_approval_by_spm

                            return <div className={'modal_view_contracts__record_content'}>
                                <div className={hasErrorSize ? classNameError : ''}>{line.record.cns_title}</div>
                                <div><span
                                    className={hasErrorSize ? classNameError : ''}>{line.size}</span> {line.record.cns_ed_izm} × <span
                                    className={hasErrorPrice ? classNameError : ''}>{line.price}</span> ₽
                                </div>
                                <div> = <span className={hasErrorSum ? classNameError : ''}>{line.sum_wat}</span> ₽
                                </div>
                            </div>;
                        })}
                        <div className={'modal_view_contracts__record_files'}>
                            <div><DownloadFile
                                file={`${process.env.REACT_APP_API_URL}/api/passport/contracts/${contract.id}/files-archive`}
                                title={'Скачать документы'}
                                name={`Документы для контракта ${contract.doc_number} от ${dateFormat(contract.doc_date)}.zip`}
                                prefics={<><DownloadOutlined /></>} />
                            </div>
                        </div>
                        {(isErrorSize || isErrorWithDates) &&
                            <ModalErrorConfirm error={(isErrorSize ?? isErrorWithDates)!}
                                               hasErrorSizeWithDates={!!isErrorSize && !!isErrorWithDates}
                                               onAction={(type) => {
                                               }} />
                        }
                    </div>,
                };
            });

            const contractByContragent = props.record.cns_contracts.filter(e => contragent.value == e.contragent_id);

            return ({
                className: `has_collapse_approval_by_spm1 ${errorsByContragent.length ? (errorsByContragentNoClosed ? 'has_collapse_error' : 'has_collapse_warning') : ''} contragent_${contragent.value}`,
                key: `contragent_${contragent.value}`,
                label: <div id={`contracts_wrap_${contragent.id}`} className={'modal_view_contracts__record'}>
                    <div className={'modal_view_contracts__record_title'}>
                        <span className={'title_contract'}>{contragent.label}</span>
                        &nbsp;&nbsp;&nbsp;
                        <span>{numberFormatter(contractsSizes)} {props.record.cns_ed_izm} x {numberFormatter(contractsPrices)} ₽ = {numberFormatter(contractsSum)} ₽</span>
                    </div>
                </div>,
                children: <>
                    <Collapse
                        key={`collapse_contracts`}
                        ghost
                              defaultActiveKey={(props.record.cns_contracts.length == 1 && contractByContragent.length == 1) ? [`contract_${contractByContragent[0].id}`] : undefined}
                              items={contractsItems} />
                </>,
            });
        });

    function updatedSync() {
        //    console.log('updatedSync', {...props});
        setRefresh(Math.random());
    }

    (window as any)[`modalViewContract_${props.type}`] = {
        items,
        updatedSync,
        ...props,
    };

    useEffect(() => {
        // console.log('modalViewContract', props)

        if (!(window as any).resolveUpdate) {
            (window as any).resolveUpdate = [];
        }

        (window as any).resolveUpdate.push(updatedSync);

        return () => {
            (window as any).resolveUpdate = (window as any).resolveUpdate.filter((e: any) => e != updatedSync);
        };

    }, []);

    const countContragentIds = props.record.cns_contracts.map(e => e.contragent_id).reduce((uniqContragentIds, contragentId) => uniqContragentIds.includes(contragentId) ? uniqContragentIds : [...uniqContragentIds, contragentId], ['']).filter(e => e);

    return <Modal title={title} open={props.isOpen} footer={null} closable={true} onClose={props.onClose}
                  onCancel={props.onClose} rootClassName={'modal_view_contracts'}
                  width={600}
    >
        {/*{JSON.stringify(props.errors)}*/}
        <div className={'modal_view_contracts__title'}>{props.record.cns_title}</div>
        <div className={'modal_view_contracts__info'}>
            <span>Бюджет план: <span>{numberFormatter(props.record.cns_budget_plan_size)} × {numberFormatter(props.record.cns_budget_plan_price)} = {numberFormatter(props.record.cns_budget_plan_sum_wat)} </span> </span>
            <div className={'modal_view_contracts__info_right'}>
                <span>{numberFormatter(props.record.cns_budget_plan_far)} $ / sf (м²)</span> &nbsp; &nbsp; &nbsp;
                <span>{numberFormatter(props.record.cns_budget_plan_far2)} %</span>
            </div>
        </div>

        <Collapse
            defaultActiveKey={countContragentIds.length == 1 ? [`contragent_${countContragentIds[0]}`] : undefined}
            ghost items={items} key={`collapse_contragents_${props.record.cns_id}`} />
    </Modal>;
};

export default ModalViewContract;

export function WrapModalViewContract(props: {
    isOpen: boolean,
    type: TypeModalEnum,
    onClose?: () => void,
    record?: TableRecord,
    errors?: ProjectError[]
}) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        if (props.isOpen) {
            setIsModalOpen(true);
        }

        if (!props.isOpen && isModalOpen) {
            setIsModalOpen(false);
        }
    }, [props.isOpen]);

    function onClose() {
        console.log('onClose');
        if (getWData().cellOpen) {
            switch (props.type) {
                case 'contracts':
                    getWData().cellOpen!.isModalContragent = false;
                    break;
                case 'bills':
                    getWData().cellOpen!.isModalBill = false;
                    break;
                case 'invoices':
                    getWData().cellOpen!.isModalInvoice = false;
                    break;
                case 'way_bills':
                    getWData().cellOpen!.isModalWayBill = false;
                    break;
            }
            getWData().setRefresh(Math.random());
        }

        props.onClose?.();
    }

    async function deleteItem(type: TypeModalEnum, id: string) {
        setIsDeleting(true);
        try {
            if (type == 'contracts') {
                if (id.includes('.')) {
                    await updateDocumentDb(getWData().rows3, getPassportCache().id ?? '', id, DocumentType.contract, undefined, true);
                } else {
                    await api.passport.projectsSectionsTypesNomenclaturesContractsDelete(
                        getPassportCache().id ?? '',
                        getWData().cellOpen!.record!.cns_section_id,
                        getWData().cellOpen!.record!.cns_group_id,
                        getWData().cellOpen!.record!.cns_nomenclature_id ?? '',
                        id,
                    );
                }
            } else if (type == 'invoices') {
                if (id.includes('.')) {
                    await updateDocumentDb(getWData().rows3, getPassportCache().id ?? '', id, DocumentType.invoice, undefined, true);
                } else {
                    await api.passport.projectsSectionsTypesNomenclaturesInvoicesDelete(
                        getPassportCache().id ?? '',
                        getWData().cellOpen!.record!.cns_section_id,
                        getWData().cellOpen!.record!.cns_group_id,
                        getWData().cellOpen!.record!.cns_nomenclature_id ?? '',
                        id,
                    );
                }
            } else if (type == 'way_bills') {
                if (id.includes('.')) {
                    await updateDocumentDb(getWData().rows3, getPassportCache().id ?? '', id, DocumentType.waybill, undefined, true);
                } else {
                    await api.passport.projectsSectionsTypesNomenclaturesWaybillsDelete(
                        getPassportCache().id ?? '',
                        getWData().cellOpen!.record!.cns_section_id,
                        getWData().cellOpen!.record!.cns_group_id,
                        getWData().cellOpen!.record!.cns_nomenclature_id ?? '',
                        id,
                    );
                }
            } else if (type == 'bills') {
                if (id.includes('.')) {
                    await updateDocumentDb(getWData().rows3, getPassportCache().id ?? '', id, DocumentType.bill, undefined, true);
                } else {
                    await api.passport.projectsSectionsTypesNomenclaturesBillsDelete(
                        getPassportCache().id ?? '',
                        getWData().cellOpen!.record!.cns_section_id,
                        getWData().cellOpen!.record!.cns_group_id,
                        getWData().cellOpen!.record!.cns_nomenclature_id ?? '',
                        id,
                    );
                }
            }


            await getWData().loadDataPassport({ showLoaded: false });

            getWData().cellOpen!.record = getWData().rows3.find(e => e.cns_nomenclature_id == getWData().cellOpen!.record!.cns_nomenclature_id) ?? getEmptyTableRecord();
            getWData().setRefresh(Math.random());
        } catch (e) {
            console.error((e as any)?.error);
            notification.error({ message: (e as any)?.error?.message ?? 'Не удалось удалить запись' });
        }
        setIsDeleting(false);
    }

    const record2 = props.record ?? getWData().cellOpen?.record ?? getEmptyTableRecord();

    const errors2 = getLoadDataInfo().errors.filter(e => {
        if (e.nomenclature_id != record2.cns_nomenclature_id) return false;

        if (props.type == TypeModalEnum.contracts) {
            return e.type?.includes('contract');
        } else if (props.type == TypeModalEnum.invoices) {
            return e.type?.includes('invoice');
        } else if (props.type == TypeModalEnum.way_bills) {
            return e.type?.includes('waybill');
        } else if (props.type == TypeModalEnum.bills) {
            return e.type?.includes('bill');
        }
    });


    return <ModalViewContract isOpen={isModalOpen}
                              record={record2}
                              onClose={onClose}
                              type={props.type}
                              allowRemove={tokenActions.deletDocument == AccessItem.ALLOW}
                              errors={props.errors ?? errors2}
                              onRemoveItem={(itemId) => {

                                  Modal.confirm({
                                      closable: true,
                                      title: 'Удалить запись?',
                                      icon: <></>,
                                      footer: <div style={{
                                          display: 'flex',
                                          justifyContent: 'flex-start',
                                          paddingTop: '20px',
                                          gridGap: '1rem',
                                      }}>
                                          <Button size={'small'} data-test={'cancel_remove_record'} onClick={() => {
                                              Modal.destroyAll();
                                          }} style={{ marginRight: 'auto' }}>Отмена</Button>
                                          <Button size={'small'} type={'primary'} data-test={'do_remove_record'}
                                                  loading={isDeleting}
                                                  disabled={isDeleting}
                                                  onClick={async () => {
                                                      // add [data-test="do_remove_record"] attribute disabled
                                                      document.querySelector('[data-test="do_remove_record"]')?.setAttribute('disabled', 'true');
                                                      await deleteItem(props.type, itemId);
                                                      getWData().setRefresh(Math.random());
                                                      document.querySelector('[data-test="do_remove_record"]')?.removeAttribute('disabled');

                                                      Modal.destroyAll();
                                                  }}>Удалить</Button>
                                      </div>,
                                  });

                                  // console.log({itemId})
                              }}
    />;
}
