import {Dropdown, Menu, message, Pagination, Table, TableColumnsType} from "antd";
import React, {FunctionComponent, useState} from "react";
import {InvoiceGroupedListItem, InvoiceHelperValue} from "../../ApiEpo";
import {ProjectListStatus} from "../../actions/useProject";
import {useInvoicesListGrouped} from "../../actions/useInvoicesListGrouped";
import {MenuProps} from "antd/lib";
import {useInvoicesStatus} from "../../actions/useInvoicesStatus";
import {useQueryClient} from "react-query";
import ModalInvoicesStatus from "./ModalInvoicesStatus";
import ModalReasonRejected from "./ModalReasonRejected";
import {downloadInvoicesDocuments} from "../../actions/downloadInvoicesDocuments";
import {selectCheckBox} from "./FinController";
import {debounce} from "lodash";
import tokenActions, {AccessItem} from "../../actions/tokenActions";
import {useTranslation} from "react-i18next";
import {localeKeys} from "../../i18n/localeKeys";

interface FCTableProps {
    header?: string[]
    columns: { title: string, translate: string }[];
    status: ProjectListStatus;
    invoiceGrouped: boolean;
    refreshData: boolean;
    project: { section_id: string, project_id: string } | undefined;
    allStatus: InvoiceHelperValue[];
    searchValue: string;
    selectCheckBox: (val: selectCheckBox[]) => void;
    checkBoxList: selectCheckBox[];
}

interface INomenclatureTable {
    key: React.Key;
    project_title: string,
    measure_unit: string,
    volume: string,
    price: string,
    amount: string,
    project_id: string
    section_id: string
}

export interface InvoiceItem {
    title: string;
    dataIndex: string;
    key: string;
    project: InvoiceGroupedListItem;
    invoice_number: string;
    invoice_date: string;
    price: number | undefined;
    status_label: string;
    actions: string;
    amount: number;
    volume: number;
    idProject?: string;
    status_value?: string
}

type MenuItem = Required<MenuProps>['items'][number];

export const FCTableInvoicesList: FunctionComponent<FCTableProps> = ({...props}) => {
    const queryClient = useQueryClient();
    const [perPage, setPerPage] = useState<number>(10)
    const [page, setPage] = useState<number>(1)
    const [modalData, setModalData] = useState<InvoiceItem>()

    const [isOpenReject, setIsOpenReject] = useState<boolean>(false)
    const [rejerectId, setRejerectId] = useState<string>('')

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const {
        invoicesListGrouped,
        invoicesListGroupedError,
        invoicesListGroupedLoading
    } = useInvoicesListGrouped({
        status: props.status as ProjectListStatus,
        project_id: props.project?.project_id ?? '',
        section_id: props.project?.section_id ?? '',
        page: page,
        per_page: perPage,
        search: props.searchValue
    })

    const {t} = useTranslation();

    const onSuccess = (data: any) => {
        message.success('Статус обновлен');
        queryClient.invalidateQueries(['invoicesListGrouped']);
        queryClient.invalidateQueries(['invoiceStatusesList']);
    }


    const onError = (data: any) => {
        message.error(`Ошибка ${data}`);
        queryClient.invalidateQueries(['invoicesListGrouped']);
        queryClient.invalidateQueries(['invoiceStatusesList']);
    }

    const {mutate: updateStatus} = useInvoicesStatus(onSuccess, onError)

    const itemMenu = (data: any): MenuItem[] => {
        return [
            {
                key: 'sub1',
                label: t(localeKeys.finController.columns.toPay),
                children: [
                    {
                        key: '1-1',
                        label: <div onClick={() => {
                            setModalData(data)
                            setIsOpen(true)
                        }}>{t(localeKeys.finController.columns.deployScan)}</div>,
                    },
                ]
            }
        ]

    }
    const dropdownStatus = (record: any) => {
        // console.log('asdfasdf', record)
        let newStatus: string;
        const filterStatus = props.allStatus
        const listItemStatus = [
            {
                key: `dropdown-title__disable`,
                label: (<div>
                    {t(localeKeys.finController.columns.moveToStatus)}
                </div>),
                disabled: true,
            }
        ]
        switch (record.status_value) {
            case 'on_work':
                newStatus = "to_pay"
                break;
            case 'to_pay':
                newStatus = "paid"
                break;
        }
        if (record.status_value === 'to_pay') {
            const items = itemMenu(record)
            listItemStatus.push(
                {
                    key: `approved_sgc`,
                    label: <Menu items={items}/>,
                    disabled: false
                }
            )
        } else if (record.status_value !== 'paid') {
            listItemStatus.push({
                key: `${record.text}-newStatus`,
                label: (<div onClick={() => {
                    updateStatus({ids: record.idProject, status: newStatus})
                }}>
                    {`${filterStatus.find((item) => item.value === newStatus)?.text}`}
                </div>),
                disabled: false,
            })
        }
        if (record.status_value !== 'rejected') {
            listItemStatus.push({
                key: `${record.text}-rejected`,
                label: (<div onClick={() => {
                    setIsOpenReject(true)
                    setRejerectId(record.idProject)
                }
                }>
                   {`${filterStatus.find((item) => item.value === 'rejected')?.text}`}
                </div>),
                disabled: false,
            })
        }
        return listItemStatus

    }
    // console.log('123123', tokenActions.invoices, AccessItem.EDIT)
    const invoiceColumns = [
        {title: <span>{t(localeKeys.finController.columns.contragent)}</span>, dataIndex: 'title', key: 'title'},
        {title: t(localeKeys.finController.columns.accountNumber), dataIndex: 'invoice_number', key: 'invoice_number'},
        {title: t(localeKeys.finController.columns.invoiceDate), dataIndex: 'invoice_date', key: 'invoice_date'},
        {title: t(localeKeys.finController.columns.sum), dataIndex: 'amount', key: 'amount'},
        {
            title: t(localeKeys.finController.columns.status),
            dataIndex: 'status_label',
            key: 'status_label',
            render: (text: string) => <div className={'fin_controller_screen__status'}>{text}</div>
        },
        {
            title: t(localeKeys.finController.columns.actions),
            dataIndex: 'actions',
            key: 'actions',
            width: 100,
            render: (_: any, record: any) => {
                const items = [...dropdownStatus(record).filter((item) => item?.key !== record.status_label)]
                return (<div className={'fin_controller_screen__actions'}>
                    {props.status !== "rejected" && tokenActions.invoices === AccessItem.EDIT ?
                        <Dropdown menu={{items}} placement="bottomLeft" arrow
                                  overlayClassName={'fin_controller_screen__change_status'}>
                            <img className={`tree-contragent-img`} src="/layout/arrow.svg" alt=""/>
                        </Dropdown> : null
                    }
                    <img onClick={() => {
                        debounceDownloadDocuments(record.idProject)
                    }} className={`tree-contragent-img`} src="/layout/download.svg" alt=""/>
                </div>)
            },
        },
    ]

    const downloadDocuments = async (id: string) => {
        downloadInvoicesDocuments(id, 'documents')
    }

    const debounceDownloadDocuments = debounce(downloadDocuments, 800)

    const invoicesTable = (item: {
        dataIndex: string;
        key: string;
        title: string,
        project: any
    }, index: number, abc: any, aaa: any) => {
        const columnsNomenclature: TableColumnsType<INomenclatureTable> = [
            {title: t(localeKeys.finController.columns.name), dataIndex: 'project_title', key: 'project_title'},
            {title: t(localeKeys.finController.columns.unit), dataIndex: 'measure_unit', key: 'measure_unit'},
            {title: t(localeKeys.finController.columns.volume), dataIndex: 'volume', key: 'volume'},
            {title: t(localeKeys.finController.columns.price), dataIndex: 'price', key: 'price'},
            {title: t(localeKeys.finController.columns.sumWithVAT), dataIndex: 'amount', key: 'amount'},
        ];
        let nomenclatures: INomenclatureTable[] = [];
        if (item) {
            nomenclatures = [...item.project?.nomenclatures.map((item: any, index: any) => {
                return {
                    key: `nomenclatureList-${index}`,
                    project_title: item.project_title ?? '',
                    measure_unit: item.measure_unit ?? 0,
                    volume: item.volume ?? 0,
                    price: item.price ?? 0,
                    amount: item.amount ?? 0,
                    project_id: item.project_id ?? '',
                    section_id: item.section_id ?? '',
                }
            })]
        }
        return (
            <div className="FC-table-nomenclature">
                <Table
                    columns={columnsNomenclature}
                    dataSource={nomenclatures}
                    pagination={false}
                />
            </div>);
    }

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: InvoiceItem[]) => {
            const newListCheckbox = () => {
                const projectId = `${props.project?.project_id}-${props.project?.section_id}`
                const checkBoxProject: selectCheckBox[] = props.checkBoxList.filter(item => item.projectId !== projectId && item.checkBoxList.length !== 0 && item.selectedRowKeys.length !== 0)
                if (selectedRows.length > 0 && selectedRowKeys.length > 0) {
                    return [...checkBoxProject,
                        {
                            projectId: projectId,
                            checkBoxList: selectedRows,
                            selectedRowKeys: selectedRowKeys
                        }
                    ]

                } else {
                    return [...checkBoxProject
                    ]
                }
            }
            props.selectCheckBox(newListCheckbox())
        },
    }
    const itemInvoiceTable = (item: InvoiceGroupedListItem, index: number): InvoiceItem => {
        return ({
            title: item.contractor_name ?? t(localeKeys.finController.columns.organization),
            dataIndex: 'title',
            key: `invoice-${index}-${item.id}`,
            amount: item.amount ?? 0,
            project: item,
            volume: item.volume ?? 0,
            invoice_number: item.invoice_number ?? '',
            invoice_date: item.invoice_date ?? '',
            price: item.price ?? 0,
            status_label: item.status_label ?? '',
            actions: t(localeKeys.finController.columns.actions),
            idProject: item.id ?? '',
            status_value: item.status ?? '',
        })
    }


    const getRowSelect = () => {
        if (props.checkBoxList) {
            const item = props.checkBoxList?.find((item) => {
                return item.projectId === `${props.project?.project_id}-${props.project?.section_id}`
            })
            if (item) {
                return item.selectedRowKeys
            }
        }
        return []
    }
    return (
        <div className="fin-controller_table">
            <div className='fin-controller_table__header'>
            </div>
            {invoicesListGrouped?.data && !invoicesListGroupedError && !invoicesListGroupedLoading ?
                invoicesListGrouped?.data.length > 0 ?
                    <>
                        <Table
                            columns={invoiceColumns}
                            dataSource={[...invoicesListGrouped.data.map((item, index) => itemInvoiceTable(item, index))]}
                            expandable={{
                                expandedRowRender: invoicesTable,
                                defaultExpandedRowKeys: ['0'],
                                expandIconColumnIndex: 0,
                            }}
                            rowSelection={{
                                type: 'checkbox',
                                ...rowSelection,
                                selectedRowKeys: getRowSelect()
                            }}
                            pagination={false}
                        />
                        {invoicesListGrouped?.meta ?
                            <Pagination
                                className="fin-controller_table__pagination"
                                defaultCurrent={1}
                                total={invoicesListGrouped?.meta?.total}
                                pageSize={perPage}
                                onChange={setPage}
                            /> : null
                        }
                    </>
                    : <div
                        className={'fin_controller_screen__empty'}>{t(localeKeys.finController.columns.noData)}</div> : <>{t(localeKeys.shared.loading)}</>
            }
            <ModalInvoicesStatus
                isOpen={isOpen}
                modalData={modalData}
                onCancel={setIsOpen}
                invoicesGrouped={props.invoiceGrouped}
                onClose={() => {
                    setIsOpen(false)
                }}
            />
            <ModalReasonRejected
                isOpen={isOpenReject}
                modalData={rejerectId}
                onCancel={setIsOpenReject}
                onClose={() => {
                    setIsOpenReject(false)
                }}
                onRejerect={updateStatus}
            />

        </div>
    )
}
