import React, { useEffect, useState } from 'react';
import {ModalFormRegionProps} from "../../../shared/directory/types";
import {Button, Input, Modal, Form, message} from 'antd';
import {useTranslation} from "react-i18next";
import type { FormProps } from 'antd';
import {localeKeys} from "../../../i18n/localeKeys";
import { directoryApi } from "../../../shared/api_client";


const ModalFormRegion: React.FC<ModalFormRegionProps> = ({ ...props }) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const [btnLoading, setBtnLoading] = useState<boolean>(false)

    type FieldType = {
        title?: string;
    };

    const onFinish = async (values: FieldType) => {
        setBtnLoading(true);
        try {
            if(props.record) {
                await directoryApi.regions.regionsUpdate(props.record.id,{
                    title: values?.title ?? '',
                    status: "active"
                });
                message.success(`Успешно сохранен`)
            } else {
                await directoryApi.regions.regionsCreate({
                    title: values?.title ?? '',
                    status: "active"
                });
                message.success(`Успешно добавлен`)
            }

            setBtnLoading(false);
            props.onSuccess({formData: values as any, record: props.record  });
            props.onClose();
            form.resetFields();
        }catch (error) {
            setBtnLoading(false);
            const e = error as any
            if (e?.error?.message) {
                console.log(e);
                message.error(e?.error?.message);
            } else {
                message.error(`Error approved`);
            }
        }
    };

    const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleOk = () => {
        form.submit();
    };

    const handleCancel = () => {
        props.onCancel(false);
    };

  return (
      <>
          <Modal
              className="custom_modal_form"
              title={props.record ? t('directoryRegions.form.titleEdit') : t('directoryRegions.form.titleAdd')}
              open={props.isOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              loading={btnLoading}
              okText={props.record ? t('directoryRegions.form.btnSave') : t('directoryRegions.form.btnAdd')}
          >
              <Form
                  form={form}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  initialValues={{
                      title: (props.record ? props.record.title : ''),
                  }}
              >
                  <Form.Item<FieldType>
                      label={t(localeKeys.directoryRegions.name)}
                      name="title"
                      rules={[{ required: true, message: t('validation.requiredField') }]}
                  >
                      <Input />
                  </Form.Item>
              </Form>
          </Modal>
      </>
  );
}
export default ModalFormRegion;
