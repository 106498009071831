import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {DTableGridProps} from "./types";
import Table from 'antd/lib/table';
import {GridAction} from "./components/GridAction";
import {localeKeys} from "../../i18n/localeKeys";
import Button from 'antd/lib/button';
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import {useDirectoryRegions} from "../../actions/useDirectoryRegions";
import ModalFormRegion from "../../screens/directory_regions/modalForm/ModalFormRegion";
import {directoryApi} from "../api_client";
import {message, Space, Spin, Flex} from "antd";
import tokenActions, { AccessItem }  from "../../actions/tokenActions";
import { Directory } from '../../ApiDirectory';


export const DirectoryTableGridRegion: React.FC<DTableGridProps> = ({...props}) => {
    const {t} = useTranslation();
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [view, setView] = useState<Directory | null>(null);
    const [isArchiveBtn, setIsArchiveBtn] = useState<boolean>(false);
    const [isDeleteBtn, setIsDeleteBtn] = useState<boolean>(false);
    const [perPage, setPerPage] = useState<number>(10)
    const [page, setPage] = useState<number>(1)

   const {directoryRegionsList, directoryRegionsListLoading, directoryRegionsListError, refetch } = useDirectoryRegions(
       {
           page: page,
           per_page: perPage,
       }
   )
    useEffect(() => {
        if(directoryRegionsList?.length) {
            const regions = directoryRegionsList.map((it,ind) => {
                return {
                    key: it.id,
                    ...it
                }
            });
            // regions.push({
            //     key: 'add-region',
            //     title: '',
            // })

            setDataSource(regions);
            console.log('useEffect, setDataSource', regions);
        }
    }, [directoryRegionsList, directoryRegionsListLoading]);

    const columns = [
        {
            title: t(localeKeys.directoryRegions.name),
            dataIndex: 'title',
            key: 'title',
            render: (text: string, record: any, index: number) => {
              //  console.log(index, dataSource.length, directoryRegionsList?.length);
                const last = directoryRegionsList?.length;
                // Проверяем, если это последняя строка
                // if (index === last) {
                //     return (
                //         <Button type="link" icon={<PlusOutlined/>} onClick={handleAddRegion} size={'small'}>
                //             {t('directoryRegions.addRegion')}
                //         </Button>
                //     );
                // }
                return text;
            },
        },
        {
            title: () => (
               tokenActions.directoryRegions === AccessItem.EDIT &&
                 <Button type="primary"  onClick={handleAddRegion}>
                    {t('directoryRegions.addRegion')}
                </Button>

            ),
            width: 100,
            dataIndex: 'action',
            key: 'action',
            render: (text: string, record: any, index: number) => {
                if (tokenActions.directoryRegions === AccessItem.EDIT && record.key !== 'add-region') {
                    return (<GridAction
                        optionBtn={{disabledEdit: false, loadingArchive: isArchiveBtn, loadingDelete: isDeleteBtn}}
                        onDelete={()=> onDelete(record)}
                        onEdit={()=> onEdit(record)}
                        onArchive={()=> onArchive(record)}
                        record={record}
                        view={view || undefined}
                    />)
                }
               return null;
            },
        },
    ];

    const handleAddRegion = () => {
        setIsOpenModal(true);
        setView(null);
    };
    const onEdit = (record: Directory) => {
        setIsOpenModal(true);
        setView(record);
    }
    const onDelete = async(record: Directory) => {
        if (!record) return;
        setView(record);
        setIsDeleteBtn(true);
        try {
            await directoryApi.regions.regionsDelete(record?.id ?? '');
            setIsDeleteBtn(false);
            message.success(`Данные успешно удалены`);
            onSuccess();
        }catch (error) {
            setView(null);
            setIsDeleteBtn(false);
            const e = error as any
            if (e?.error?.message) {
                console.log(e);
                message.error(e?.error?.message);
            } else {
                message.error(`Error approved`);
            }
        }
    }

    const onArchive = async (record: Directory) => {
        if (!record) return;
        setView(record);
        setIsArchiveBtn(true);
        try {
            await directoryApi.regions.regionsUpdate(record?.id ?? '',{
                title: record?.title,
                status: record?.status === "active" ? "archive" : "active"
            });
            setIsArchiveBtn(false);
            message.success(`Архивирование завершено`);
            onSuccess();
        }catch (error) {
            setView(null);
            setIsArchiveBtn(false);
            const e = error as any
            if (e?.error?.message) {
                console.log(e);
                message.error(e?.error?.message);
            } else {
                message.error(`Error approved`);
            }
        }
    }
    const onSuccess = (record?: Directory) => {
        refetch();
        setView(null);
    }

    const onChange = (record: any) => {
        if (record) {
            console.log(record);
            setPage(record.current);
        }
    }

    if (directoryRegionsList && directoryRegionsList?.length > 0 && !directoryRegionsListError && !directoryRegionsListLoading) {
        return (
            <div className={'directory_table_grid'}>
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    loading={props?.loading ?? false}
                    pagination={false}
                    onChange={onChange}
                />
                {isOpenModal ?  <ModalFormRegion
                    record={view}
                    isOpen={isOpenModal}
                    onCancel={setIsOpenModal}
                    onClose={()=> setIsOpenModal(false)}
                    onSuccess={onSuccess}
                /> : ''}

            </div>

        );
    } else {
        return (
            <div className={'cn-empty-content'}>
                {directoryRegionsListLoading ?  <div className={'loading_content'}><Spin size={'large'} /></div> :  t(localeKeys.contragentsListScreen.noData)}
            </div>
        )
    }
};
