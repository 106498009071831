import tokenActions, { AccessItem } from '../../../actions/tokenActions';
import { RecordStatus, TableRecord } from '../../canvas_table/components/TableRecord';
import { getTableColumnKey } from '../getTableColumnKey';
import { getWData } from '../getWData';
import { getPassportCache } from '../loadData';

export function checkOpenModalBill(col: number, row: number, currentRow: TableRecord) {
  const columnKey = getTableColumnKey(col, getWData().body);
  const columns: (keyof TableRecord)[] = [
    'cns_bill_count',
    'cns_bill_number',
    'cns_bill_date',
    'cns_bill_size',
    'cns_bill_price',
    'cns_bill_sum',
  ];
  return (
    (getPassportCache().status == 'active' &&
      columns.includes(columnKey) &&
      (tokenActions.bill === AccessItem.EDIT || tokenActions.bill === AccessItem.VIEW) &&
      currentRow?.cns_row_type == 'nomenclature' &&
      currentRow?.cns_status === RecordStatus.BUDGET_PLAN_APPROVED) ??
    false
  );
}
