import {Moment} from "moment";
import {getPassportCache} from "../../table_extends/loadData";
import { BaseOptionType } from 'antd/es/select/index';
import { DefaultOptionType } from 'rc-select/lib/Select';

export enum RecordStatus {
    BUDGET_ORIENTED_APPROVED = "BUDGET_ORIENTED_APPROVED",
    BUDGET_PLAN_UNDER_REVIEW = "BUDGET_PLAN_UNDER_REVIEW",
    BUDGET_PLAN_REJECTED = "BUDGET_PLAN_REJECTED",
    BUDGET_PLAN_APPROVED = "BUDGET_PLAN_APPROVED"
}

export enum RecordContractStatus {
  SIGNET = "signed",
}

export type TableRecord = {
    /** @property {number} cns_level - Уровень вложенности */
    cns_level: number;
    /** @property {string} cns_row_type - Тип строки (проект / раздел / вид работ / номенклатура / ничего) */
    cns_row_type: 'project' | 'section' | 'work_type' | 'nomenclature' | '';
    /** @property {string} cns_id - ID строки (составной) */
    cns_id: string;
    /** @property {string} cns_number - Номер строки */
    cns_number: string;
    /**
     * @deprecated
     * @property {string} cns_parent_id - ID родителя (старый вариант с построением полного пути по ID)
     */
    cns_parent_id: string;
    /** @property {string} cns_parent_id2 - Новый вариант ID родителя (напрямую с маппинга данных апи) */
    cns_parent_id2: string;
    /** @property {string} cns_parent_number - Номер строки родителя */
    cns_parent_number: string;
    /** @property {string} cns_section_id - ID раздела */
    cns_section_id: string;
    /** @property {string} cns_group_id - ID группы / вида работ */
    cns_group_id: string;
    /** @property {string} cns_nomenclature_id - ID номенклатуры */
    cns_nomenclature_id: string | null;
    /** @property {string} cns_status - Статус строки */
    cns_status: RecordStatus;
    /** @property {string} cns_has_sended_approve - Есть отправленные на согласования строки ниже (проверить?) */
    cns_has_sended_approve: boolean;
    /** @property {string} cns_allow_send_approve - Можно ли отправить эту строку на согласование БП */
    cns_allow_send_approve: boolean;
    /** @property {string} cns_title - Наименование */
    cns_title: string | null;
    /** @property {string} cns_title2 - Доп часть ячейки */
    cns_title2?: string;
    /** @property {string} cns_ed_izm - Сумма */
    cns_ed_izm: string;

    /**
     * Бюджет план
     * - Объем
     * - Цена за ед. с НДС руб.
     * - Сумма с НДС руб.
     * - ₽/М²
     * - %
     */
    /** @property {string} cns_budget_plan_size - Объем */
    cns_budget_plan_size: string;
    /** @property {string} cns_budget_plan_price - Цена за ед. с НДС, ₽. */
    cns_budget_plan_price: string;
    /** @property {string} cns_budget_plan_sum_wat - Сумма с НДС, руб. */
    cns_budget_plan_sum_wat: string;
    /** @property {string} cns_budget_plan_sum_wat2 - доп часть ячейки */
    cns_budget_plan_sum_wat2?: string;
    /** @property {string} cns_budget_plan_far - FAR, ₽/М² */
    cns_budget_plan_far: string;
    /** @property {string} cns_budget_plan_far2 - Фар % */
    cns_budget_plan_far2: string;

    /**
     * Сумма по контрактам
     * - Объем
     * - Цена за ед. с НДС руб.
     * - Сумма с НДС руб.
     * - ₽/М²
     */
    /** @property {string} cns_budget_plan_size - Объем */
    cns_budget_fakt_size: string;
    /** @property {string} cns_budget_fakt_size - Цена за ед. с НДС руб. */
    cns_budget_fakt_price: string;
    /** @property {string} cns_budget_fakt_sum_wat - Сумма с НДС руб. */
    cns_budget_fakt_sum_wat: string;
    /** @property {string} cns_budget_fakt_far - Фар ₽/М² */
    cns_budget_fakt_far: string;
    /** @property {string} cns_budget_fakt_last_change - Дата последнего изменения */
    cns_budget_fakt_last_change: string;

    /**
     * График
     * - Дата старта
     * - Дата завершения
     */
    /** @property {string} cns_plane_date - Дата старта */
    cns_plane_date: string;
    /** @property {string} cns_plane_size - Объем */
    cns_plane_size: string;
    /** @property {string} cns_plane_date_start - Дата старта */
    cns_plane_date_start: string;
    /** @property {string} cns_plane_date_end - Дата окончания */
    cns_plane_date_end: string;

    /**
     * Контракты
     * - Дата
     * - Дата начала работ
     * - Дата завершения работ
     * - Подрядчик
     * - Контракты
     * - Ответственный ID
     * - Ответственный имя
     */
    /** @property {string} cns_contact_date - Плановая дата контракта */
    cns_contact_date: string;
    /** @property {string} cns_contact_date - Дата начала контракта */
    cns_contact_date_start: string;
    /** @property {string} cns_contact_date - Дата завершения контракта */
    cns_contact_date_end: string;
    /** @property {string} cns_responsibles - Ответственные за заключение */
    cns_responsibles: { full_name: string, id: string }[];
    /** @property {string} cns_contractors - Подрядчик */
    cns_contractors: DefaultOptionType[];
    /** @property {string} cns_contracts - Контракты */
    cns_contracts: TableRecordContragent[];

    /**
     * Занесение счетов на оплату
     * - Номер счета
     * - Дата счета
     * - Объем к оплате
     * - Цена за ед. с НДС, руб. по счету
     * - Сумма счета с НДС, руб.
     * - Статус согласования
     */
    /** @property {string} cns_invoice_count - Номер счета */
    cns_invoice_count: string
    /** @property {string} cns_invoice_date - Дата счета */
    cns_invoice_date: string;
    /** @property {number} cns_invoice_size - Объем по контракту */
    cns_invoice_size: number;
    /** @property {number} cns_invoice_price - Цена за ед. с НДС, ₽ */
    cns_invoice_price: number;
    /** @property {number} cns_invoice_sum - Сумма счета с НДС, ₽. */
    cns_invoice_sum: number;
    /** @property {string} cns_invoice_status - Статус счета */
    cns_invoice_status: string;
    cns_invoices: TableRecordContragent[];

    /**
     * блока «Отклонение от Бюджет Плана
     * - Сумма с НДС, ₽
     * - %.
     */
    /** @property {number} cns_otkl_sum - Сумма с НДС, ₽ */
    cns_otkl_sum: number;
    /** @property {number} cns_otkl_percent - %. */
    cns_otkl_percent: number;

    /**
     * % от Суммы Контракта / Бюджета
     * - Объем
     * - Сумма с НДС, руб.
     * - % оплачено
     * - % будет оплачено
     */
    /** @property {number} cns_percent_payment - Оплачено */
    cns_percent_payment: number;
    /** @property {number} cns_payment_future - будет оплачено */
    cns_payment_future: number;

    /**
     * Поставлено и выполнено на объекте
     * - % выполнено/поставлено
     * - Объем выполнения/поставки
     * - Дата поставки
     * - Сумма с НДС, ₽
     * - Номер документа
     */
    /** @property {number} cns_well_bill_percent - % выполнено/поставлено */
    cns_well_bill_percent: number;
    /** @property {string} cns_well_bill_size - Объем выполнения/поставки */
    cns_well_bill_size: string;
    /** @property {number} cns_well_bill_count - Количество ТТН */
    cns_well_bill_count: number;
    /** @property {string} cns_well_bill_delivery_date - Дата поставки */
    cns_well_bill_delivery_date: string;
    /** @property {number} cns_well_bill_delivery_sum - Сумма с НДС, ₽ */
    cns_well_bill_delivery_sum: number;
    /** @property {string} cns_well_bill_doc_number - Документ номер */
    cns_well_bill_doc_number: string;
    /** @property {TableRecordContragent[]} cns_way_bills - Объекты "Поставлено и выполненно" */
    cns_way_bills: TableRecordContragent[];

    /**
     * Cчет-фактуры
     * - Номер Счет-фактуры
     * - Дата Счет-фактуры
     * - Объем
     * - Цена за ед. с НДС, руб.
     * - Сумма с НДС, руб.
     */
    /** @property {string} cns_bill_count - Количество Счет-фактур */
    cns_bill_count: number;
    /** @property {string} cns_bill_number - Номер Счет-фактуры */
    cns_bill_number: string
    /** @property {string} cns_bill_date - Дата Счет-фактуры */
    cns_bill_date: string;
    /** @property {string} cns_bill_size - Объем */
    cns_bill_size: number;
    /** @property {string} cns_bill_size - Цена за ед. с НДС, ₽ */
    cns_bill_price: number;
    /**  @property {string} cns_bill_sum - Сумма с НДС, ₽. */
    cns_bill_sum: number;
    /** @property {string} cns_bills - Данные по счет-фактурам для модалки */
    cns_bills: TableRecordContragent[];
};

export function getEmptyTableRecord(): TableRecord {
    return {
        cns_id: "",
        cns_level: 0,
        cns_row_type: '',
        cns_number: "",
        cns_status: RecordStatus.BUDGET_ORIENTED_APPROVED,
        cns_has_sended_approve: false,
        cns_allow_send_approve: false,
        cns_parent_id: "",
        cns_parent_id2: "",
        cns_parent_number: "",
        cns_section_id: "",
        cns_group_id: "",
        cns_nomenclature_id: "",
        cns_title: "",
        cns_ed_izm: "",
        cns_budget_plan_size: "",
        cns_budget_plan_price: "",
        cns_budget_plan_sum_wat: "0",
        cns_budget_plan_far: "0",
        cns_budget_plan_far2: "0",
        cns_budget_fakt_size: "",
        cns_budget_fakt_price: "",
        cns_budget_fakt_sum_wat: "",
        cns_budget_fakt_far: "",
        cns_budget_fakt_last_change: "",
        cns_plane_date: '',
        cns_plane_size: '',
        cns_plane_date_start: '',
        cns_plane_date_end: '',

        cns_contact_date: "",
        cns_contact_date_start: "",
        cns_contact_date_end: "",
        cns_contracts: [],
        cns_contractors: [],
        cns_responsibles: [],

        cns_invoice_count: "",
        cns_invoice_date: "",
        cns_invoice_size: '' as any,
        cns_invoice_price: '' as any,
        cns_invoice_sum: '' as any,
        cns_invoice_status: '',
        cns_invoices: [],

        cns_otkl_sum: 0,
        cns_otkl_percent: 0,

        cns_percent_payment: 0,
        cns_payment_future: 0,

        cns_well_bill_count: '' as any,
        cns_well_bill_percent: '' as any,
        cns_well_bill_size: '',
        cns_well_bill_delivery_date: '',
        cns_well_bill_delivery_sum: 0,
        cns_well_bill_doc_number: '',
        cns_way_bills: [],

        cns_bill_count: 0,
        cns_bill_number: '',
        cns_bill_date: '',
        cns_bill_size: '' as any,
        cns_bill_price: '' as any,
        cns_bill_sum: '' as any,
        cns_bills: [],
    };
}

export type TableRecordContragent = {
    id: string;
    /** @property {string} document_id - Общий ID для нескольких контрактов */
    document_id: string
    project_contract_data_id: string | null;
    parent_id: string | null;
    parent_contract_id: string | null;
    contragent: string;
    contragent_id: string;
    doc_number: string;
    doc_date: string;
    date_start: string;
    date_end: string;
    price: string;
    size: string;
    status?: string;
    sum_wat: string;
    documents: {
      id: string
      url: string
      name: string
      path: string
      invoice_id?: string
    }[];
    files: number,
    error_action?: boolean,
};

export function getEmptyTableRecordContragent() {
    return {
        id: '',
        project_contract_data_id: '',
        parent_id: '',
        contragent_id: '',
        doc_number: '',
        doc_date: '',
        price: '',
        size: '',
        sum_wat: '',
        documents: [],
        files: 0
    };
}

export type TableRecordContragentForm = {
    id: string;
    document_id: string;
    project_contract_data_id: string | null;
    parent_id: string | null;
    parent_contract_id: string | null;
    contragent: string;
    contragent_id: string;
    doc_date: Moment;
    date_start: Moment;
    date_end: Moment;
    doc_number: string;
    price: string;
    size: string;
    sum_wat: string;
    documents: (string | null | any)[];
    files: number,
};

export interface TableRecordUpdIO {
    level: number
    nomenclature_id: string
    nomenclature_type: number
    parent_id: string
    project_id: string
    row_number: string
    row_type: 'section' | 'work_type' | 'nomenclature' | 'project' | ''
    section_id: string
    type_id: string
    unit?: string
    guideline_amount?: number
    plan_budget?: number[]
    plan_budget_deviation?: number[]
    fact_budget?: number[]
    fact_payments?: number[]
    contract_data?: string[] | any[]
    payment_invoices?: string[] | any[]
    delivered_completed?: string[] | any[]
    bills?: string[] | any[]
    graph?: string[] | any[]
}

export interface TableRecordChangeStatus {
    nomenclature_ids?: string[]
    section_ids?: string[]
    type_ids?: string[]
    project_id: string
    section_id: string
    type_id: string
    type: string | 'project'
    status: 'rejected' | 'resolved' | 'on_approval' | number
}

export interface ApiInvoice {
  id: string
  price: number,
  amount: number,
  volume: number,
  status: string
  contract_id: string
  document_id: string,
  invoice_date: string,
  contractor_id: string,
  invoice_number: string,
  contractor_name: string,
  project_nomenclature_id: string,
  files: {
    id: string
    url: string
    name: string
    path: string
    invoice_id: string
  }[],
}

export interface ApiWayBills {
  id: string
  price: number,
  amount: number,
  volume: number,
  status: string,
  contract_id: string,
  document_id: string,
  waybill_date: string,
  contract_date: string,
  contractor_id: string,
  waybill_number: string,
  contract_number: string,
  contractor_name: string,
  project_nomenclature_id: string,
  files: {
    id: string
    url: string
    name: string
    path: string
    waybill_id: string
  }[],
}

export interface ApiBills {
  id: string
  price: number,
  amount: number,
  volume: number,
  status: string,
  contract_id: string,
  document_id: string,
  bill_date: string,
  contract_date: string,
  contractor_id: string,
  bill_number: string,
  contract_number: string,
  contractor_name: string,
  project_nomenclature_id: string,
  files: {
    id: string
    url: string
    name: string
    path: string
    bill_id: string
  }[],
}
