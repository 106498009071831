import React from 'react';

interface CNTreeTitleProps {
    title?: string,
    count?: number
}
export const CNTreeTitle: React.FC<CNTreeTitleProps> = ({ ...props }) => {
    return (
        <span className={'ant-tree-wrap-title'}>
           <span className="ant-tree-title">{props.title ?? ''}</span>
           <span className="ant-tree-count">
             {props.count ?? ''}
           </span>
        </span>
    );
};
