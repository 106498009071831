import React, { useEffect, useState } from 'react';
import { Button, Input, message, Modal, Select } from 'antd';
import { useInvoicesStatus } from '../../actions/useInvoicesStatus';
import { InvoiceItem } from './FCTableInvoicesList';
import { InvoiceHelperValue } from '../../ApiEpo';
import ModalAddContractUpload from '../canvas_table/components/modals/modal_add_contracts_upload';
import tokenActions, { AccessItem } from '../../actions/tokenActions';
import { emptyIModalAddContract, IFormAddContractsMany, IFormFile } from '../../Models/ContragentForm';
import { useQueryClient } from 'react-query';

interface IModalReasonRejected {
    isOpen: boolean;
    onCancel: (val: boolean) => void;
    onClose: () => void
    onRejerect: (data: any)=>void
    modalData: string
}

const ModalReasonRejected: React.FC<IModalReasonRejected> = ({ ...props }) => {
    const [inputValue, setInputValue] = useState<string>('')

    const handleOk = () => {
        props.onRejerect({ids: props.modalData, status: 'rejected', reason: inputValue  })
        setInputValue('');
        props.onClose()
    };

    const handleCancel = () => {
        props.onCancel(false);
    };



    return (
        <>
            <Modal title="Укажите причину откланения" open={props.isOpen} onOk={handleOk} onCancel={handleCancel}>
               <Input
                   maxLength={80}
                   value={inputValue}
                   onChange={(e)=>{
                   setInputValue(e.target.value)
               }} />
            </Modal>
        </>
    );
};

export default ModalReasonRejected;
