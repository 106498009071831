import React, {useState} from 'react';
import { Space, Button, Popconfirm, Upload } from 'antd';
import {CNActionProps} from "../type";
import {IssuesCloseOutlined, StopOutlined, UploadOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {localeKeys} from "../../../i18n/localeKeys";
import type { UploadProps, UploadFile } from 'antd';
import {getValueStore} from "../../../shared/store";

export const CNAction: React.FC<CNActionProps> = (
    {
        btnLoading,
        onReject,
        onApprove,
        onDownload,
        onUpload,
        contract,
        hideReject = false,
        hideDownload = false,
        hideApprove = false,
        hideUpload = false,
    }) => {
    const {t} = useTranslation();
    const [filesCounts, setFilesCounts] = useState<number>(0)
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const propUpload: UploadProps = {
        multiple: true,
        maxCount: 10,
        action: `${process.env.REACT_APP_API_DOCUMENTS}/api/files/upload`,
        headers: {Authorization: `Bearer ${getValueStore('token')}`},
        beforeUpload(file, fileList) {
            if (fileList.length > 0) {
                setFileList([]);
            }
            setFilesCounts(fileList.length)
        },
        onChange({ file, fileList }) {
            setFileList(fileList);
            if (file.status !== 'uploading') {
                if (file.status === 'done') {
                    const listIds = fileList.filter((it)=>it.response).map((it)=>it.response.id);
                    if(listIds.length === filesCounts) {
                        onUpload(listIds, contract);
                        setFilesCounts(0);
                    }
                }
            }
        },
        fileList
    };

    return (
        <div className={'action-card'}>
            {!hideDownload ?
                <Button
                    loading={btnLoading.downloadBtn}
                    icon={<UploadOutlined />}
                    type="link"
                    onClick={onDownload}>{t(localeKeys.contractNegotiation.downloadBtn)}
                </Button> : ''
            }
            <Space style={{ gap: 20 }}>
              {!hideReject ?
                    <Button
                        onClick={onReject}
                        loading={btnLoading.rejectBtn}
                        className={'btn-reject'}
                        icon={<StopOutlined />}
                        type="link">{t(localeKeys.contractNegotiation.rejectBtn)}
                    </Button> : ''
                }
                {!hideApprove ?
                    <Popconfirm
                        title={t(localeKeys.contractNegotiation.popConfirmApprove.title)}
                        description={t(localeKeys.contractNegotiation.popConfirmApprove.description)}
                        onConfirm={onApprove}
                    >
                        <Button loading={btnLoading.approveBtn} className={'btn-approve'} icon={<IssuesCloseOutlined/>}
                                type="link">{t(localeKeys.contractNegotiation.approveBtn)}</Button>
                    </Popconfirm> : ''
                }
                {!hideUpload ?
                    <Upload {...propUpload}>
                        <Button
                            loading={btnLoading.uploadBtn}
                            className={'btn-approve'}
                            icon={<UploadOutlined/>}
                            type="link">{t(localeKeys.contractNegotiation.uploadDoc)}
                        </Button>
                    </Upload>
                   : ''
                }
            </Space>
        </div>
    );
};
