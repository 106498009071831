import { useQuery } from "react-query";
import { api } from "../shared/api_client";
import {addDataToIndexedDB, getDataFromIndexedDB} from "../screens/contract_negotiation/db/db";
import {ContractHelperValueList, ContractProjectsList} from "../ApiEpo";

export type ContractsProjectStatusType =
  | "on_work"
  | "rejected"
  | "on_approval_by_spm"
  | "on_approval_by_dk"
  | "signing"
  | "signed";

export const useContractsProject = (status: ContractsProjectStatusType | undefined) => {
  const {
    data: contractsProjectList,
    error: contractsProjectListError,
    isLoading: contractsProjectListLoading,
    refetch: refetchContractsProject
  } = useQuery({
    queryKey: ['contractsProjectList', status],
    queryFn: () => api.contracts.projectsList({ status: status }).then(async (e) => {
      const response = e.data;
      if(response) await addDataToIndexedDB('contractsProjectList',response.data);
      return response; //ContractProjectsList
    }).catch(async (error: any): Promise<ContractProjectsList> => {
      if (!navigator.onLine) {
        const offlineData = await getDataFromIndexedDB('contractsProjectList');
        return { data: offlineData };
      }
      if (error.response) {
        throw error;
      } else {
        throw error;
      }
    }),
  })

  return {
    contractsProjectList,
    contractsProjectListError,
    contractsProjectListLoading,
    refetchContractsProject
  };
}


