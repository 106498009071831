import { CellDefine } from 'cheetah-grid/list-grid/layout-map/api';
import { TableRecord } from '../../canvas_table/components/TableRecord';
import {
  allowBtnTitle1,
  allowSendApproveBudget,
  allowViewOfSendBudget,
} from '../allowEditCell';
import { getBgCellMainTitle2 } from '../getCellBg';
import { getModalDotsItems } from '../getResultRows';
import { allowViewDots2 } from '../getTableColumnKey';
import { getWData } from '../getWData';
import { getPassportCache } from '../loadData';
import { themeTable } from '../table_theme';
import { getBodyCellStyle } from './body_cell_style';
import { IconsSizes } from './icons_sizes';
import { TableMode } from './table_body';

const tableBodyMainColumns: { [x: string]: CellDefine<TableRecord> } = {
  cns_number: {
    field: 'cns_number',
    style: (record: TableRecord) => ({
      ...(getBodyCellStyle(record, { field: 'cns_number' }) as any),
      padding: [0, 0, 0, 10],
      font: themeTable.font,
    }),
  },
  cns_title: {
    field: (record: TableRecord) => {
      return `   ${record.cns_title || ''}`;
    },
    style: (record: TableRecord) => {
      const isHover = IconsSizes.btn_send_approve + 3;
      const hasBtn = allowBtnTitle1(record);
      let paddingLeft = 5 - 10 - (hasBtn ? isHover : 0);

      if (record.cns_row_type == 'work_type') {
        paddingLeft = 5 - 10 - (hasBtn ? isHover : 0) + 20 * (record.cns_level - 1);
      }
      if (record.cns_row_type == 'nomenclature') {
        paddingLeft = 5 - 10 - (hasBtn ? isHover : 0) + 20 * (record.cns_level - 1);
      }

      return {
        ...(getBodyCellStyle(record, {
          allowEdit: record.cns_row_type == 'nomenclature',
          field: 'cns_title',
        }) as any),
        padding: [0, 0, 0, paddingLeft],
        borderColor:
          record.cns_row_type != ''
            ? [themeTable.borderColor, getBgCellMainTitle2(record), themeTable.borderColor, themeTable.borderColor]
            : [],
        textOverflow: 'ellipsis',
      };
    },
    width: 485,
    icon: (record: TableRecord) => {
      const icons = [];
      const isHover = IconsSizes.btn_send_approve + 5;
      const hasBtn = allowBtnTitle1(record);
      let hasGroups = false;

      if (record.cns_row_type == 'section') {
        hasGroups = !!getWData().rows2.find((e) => e.cns_section_id == record.cns_section_id);
      }

      if (
        (record.cns_row_type == 'section' || record.cns_row_type == 'work_type') &&
        (record.cns_row_type == 'section' ? hasGroups : true)
      ) {
        const isPlus =
          record.cns_row_type == 'section'
            ? !getWData().expandedLevel1.includes(record.cns_id)
            : !getWData().expandedLevel2.includes(`${record.cns_section_id}_${record.cns_group_id}`);

        if (isPlus) {
          icons.push({
            src: '/canvas_table/icon_plus.svg',
            offsetLeft: 5 + (hasBtn ? isHover : +2),
          });
        } else {
          icons.push({
            src: '/canvas_table/icon_remove.svg',
            offsetLeft: 5 + (hasBtn ? isHover : +2),
          });
        }
      }

      if (record.cns_row_type == 'section' && !hasGroups) {
        icons.push({
          src: '/canvas_table/icon_empty.svg',
          offsetLeft: 5 + (hasBtn ? isHover : +2),
        });
      }

      if (record.cns_row_type == 'project') {
        icons.push({
          src: '/canvas_table/icon_remove.svg',
          offsetLeft: 5 + (hasBtn ? isHover : +2),
        });
      }

      return icons;
    },
  },
  cns_title2: {
    field: () => {
      return ``;
    },
    style: (record: TableRecord) => {
      return {
        ...(getBodyCellStyle(record, {
          allowEdit: false,
          field: 'cns_title2',
        }) as any),
        bgColor: getBgCellMainTitle2(record),
        borderColor:
          record.cns_row_type != ''
            ? [themeTable.borderColor, themeTable.borderColor, themeTable.borderColor, getBgCellMainTitle2(record)]
            : [],
      };
    },
    width: 75,
    icon: (record: TableRecord) => {
      const icons = [];
      const isHover = allowViewDots2(record) ? IconsSizes.btn_send_approve : 0;
      const allowSend = allowSendApproveBudget(record);
      const allowViewOfSend = allowViewOfSendBudget(record);

      if (allowSend && !allowViewOfSend) {
        if (record.cns_row_type == 'project') {
          icons.push({
            src: '/canvas_table/btn_send_next.svg',
          });
        } else if (record.cns_row_type == 'work_type') {
          icons.push({
            src: '/canvas_table/btn_send_next.svg',
          });
        } else if (record.cns_row_type == 'nomenclature') {
          icons.push({
            src: '/canvas_table/btn_send_next.svg',
          });
        } else {
          icons.push({
            src: '/canvas_table/btn_send_next.svg',
          });
        }
      } else if (allowViewOfSend) {
        if (record.cns_row_type == 'project') {
          icons.push({
            src: '/canvas_table/btn_send_next.svg',
          });
        } else if (record.cns_row_type == 'work_type') {
          icons.push({
            src: '/canvas_table/btn_send_next.svg',
          });
        } else if (record.cns_row_type == 'nomenclature') {
          icons.push({
            src: '/canvas_table/btn_send_next.svg',
          });
        } else {
          icons.push({
            src: '/canvas_table/btn_send_next.svg',
          });
        }
      } else {
        icons.push({
          src: '/canvas_table/icon_empty.svg',
        });
      }

      if (!getModalDotsItems(record).filter((record) => record).length) {
        icons.push({ src: '/canvas_table/icon_empty.svg' });
      } else {
        let pathDots = '/canvas_table/dots_1.svg';
        if (isHover) {
          pathDots = '/canvas_table/dots_2.svg';
        }

        if (record.cns_row_type != '') {
          icons.push({
            src: pathDots,
            offsetLeft: 10 + (allowSend || allowViewOfSend ? -5 : 0),
          });
        }
      }

      return icons;
    },
  },
  cns_title22: {
    field: () => {
      return ``;
    },
    style: (record: TableRecord) => {
      return {
        ...(getBodyCellStyle(record, {
          allowEdit: false,
          field: 'cns_title2',
        }) as any),
        bgColor: getBgCellMainTitle2(record),
        borderColor:
          record.cns_row_type != ''
            ? [themeTable.borderColor, themeTable.borderColor, themeTable.borderColor, getBgCellMainTitle2(record)]
            : [],
      };
    },
    width: 45,
    icon: (record: TableRecord) => {
      const icons = [];
      const isHover = allowViewDots2(record) ? IconsSizes.btn_send_approve : 0;
      let pathDots = '/canvas_table/dots_1.svg';
      if (isHover) {
        pathDots = '/canvas_table/dots_2.svg';
      }

      if (record.cns_row_type != '') {
        icons.push({
          src: pathDots,
        });
      }

      return icons;
    },
  },
  cns_ed_izm: {
    field: 'cns_ed_izm',
    style: (record) => ({
      ...(getBodyCellStyle(record, {
        allowEdit: record.cns_row_type == 'nomenclature',
        bold: false,
        field: 'cns_ed_izm',
      }) as any),
      textAlign: 'center',
    }),
  },
};

export const tableBodyMain = (mode: TableMode): CellDefine<TableRecord>[] => {
  // console.log('tableBodyMain')
  const hasSendToApprove = !getWData().rows.find((e) => allowViewOfSendBudget(e));

  switch (mode) {
    case TableMode.EDIT:
      return [
        tableBodyMainColumns.cns_number,
        tableBodyMainColumns.cns_title,
        hasSendToApprove ? tableBodyMainColumns.cns_title22 : tableBodyMainColumns.cns_title2,
        tableBodyMainColumns.cns_ed_izm,
      ];
    case TableMode.VIEW:
      return [
        tableBodyMainColumns.cns_number,
        tableBodyMainColumns.cns_title,
        hasSendToApprove ? tableBodyMainColumns.cns_title22 : tableBodyMainColumns.cns_title2,
        tableBodyMainColumns.cns_ed_izm,
      ];
    default:
      return [
        tableBodyMainColumns.cns_number,
        tableBodyMainColumns.cns_title,
        hasSendToApprove ? tableBodyMainColumns.cns_title22 : tableBodyMainColumns.cns_title2,
        tableBodyMainColumns.cns_ed_izm,
      ];
  }
};
