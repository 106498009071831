import { useQuery } from 'react-query';
import { api } from '../shared/api_client';

export function useContractors(search = '') {
  const {
    data: contracts,
    error: contractsError,
    isLoading: contractsLoading,
  } = useQuery({
    queryKey: ['contractors', `contractors_${search}`],
    queryFn: () => api.helpers.contractorsList({search}).then((e) => e.data ?? []),
  });

  return {
    contracts,
    contractsError,
    contractsLoading,
  };
}
