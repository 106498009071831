import React, {FunctionComponent} from "react";
import {getErrorFieldPrefics, getTypeError, TypeModalEnum} from "../utils/getErrorFieldPrefics";
import {dateFormatter, numberFormatter} from "../../../../shared/dateFormat";
import {Button} from "antd";
import ModalErrorConfirm from "./modal_error_confirm";
import {ProjectError} from "../../../../ApiEpo";
import {getLoadDataInfo} from "../../../table_extends/loadData";
import { useTranslation } from 'react-i18next';
import { localeKeys } from '../../../../i18n/localeKeys';

const ModalErrorLines: FunctionComponent<{error: ProjectError, hasErrorSizeWithDates: boolean, typeError: TypeModalEnum}> = (props) => {
    const {t} = useTranslation();

    const error = props.error;
    const loadData = getLoadDataInfo();

    const preficsErrorField = getErrorFieldPrefics(props.typeError);
    let typeLabel = t(localeKeys.errors.lines.contract)

    const id = (error?.error as any)?.contract?.id;
    const contract = loadData.rowsTable.rows3.map(e => e.cns_contracts.map(e2 => ({
        ...e2,
        line: e,
    }))).flat().find(e2 => e2.id == id);
    if (contract) {
        const lines: {
            id: string,
            title: string,
            size: string,
            price: string,
            sum: string,
            ed_izm: string
        }[] = loadData.rowsTable.rows3
            .filter(e => e.cns_contracts.map(e2 => e2.document_id).includes(contract.document_id))
            .map(e => {
                if (props.typeError == 'contracts') {
                    typeLabel = t(localeKeys.errors.lines.contract)
                    return {
                        id: e.cns_id,
                        title: e.cns_title ?? '',
                        size: e.cns_budget_fakt_size,
                        price: e.cns_budget_fakt_price,
                        sum: e.cns_budget_fakt_sum_wat,
                        ed_izm: e.cns_ed_izm,
                    }
                }

                if (props.typeError == 'invoices') {
                    typeLabel = t(localeKeys.errors.lines.invoice)
                    return {
                        id: e.cns_id,
                        title: e.cns_title ?? '',
                        size: `${e.cns_invoice_size}`,
                        price: `${e.cns_invoice_price}`,
                        sum: `${e.cns_invoice_sum}`,
                        ed_izm: e.cns_ed_izm,
                    }
                }

                if (props.typeError == 'way_bills') {
                    typeLabel = t(localeKeys.errors.lines.waybill)
                    return {
                        id: e.cns_id,
                        title: e.cns_title ?? '',
                        size: `${e.cns_well_bill_size}`,
                        price: `${e.cns_well_bill_delivery_sum / Number(e.cns_well_bill_size)}`,
                        sum: `${e.cns_well_bill_delivery_sum}`,
                        ed_izm: e.cns_ed_izm,
                    }
                }

                if (props.typeError == 'bills') {
                    typeLabel = t(localeKeys.errors.lines.bill)
                    return {
                        id: e.cns_id,
                        title: e.cns_title ?? '',
                        size: `${e.cns_bill_size}`,
                        price: `${e.cns_bill_price}`,
                        sum: `${e.cns_bill_sum}`,
                        ed_izm: e.cns_ed_izm,
                    }
                }

                return {id: '', title: '', size: '', price: '', sum: '', ed_izm: ''}
            }).filter(e => e.id && Number(e.size));

        const hasErrorSize = error.error?.fields?.includes(`${preficsErrorField}volume`);
        const hasErrorPrice = error.error?.fields?.includes(`${preficsErrorField}price`);
        const hasErrorSum = error.error?.fields?.includes(`${preficsErrorField}amount`);

        const countErrors = props.hasErrorSizeWithDates ? 2 : 1; //props.errors.filter(e => !e.closed_at).length;
        const countErrorsClosed = 0 // props.errors.filter(e => !!e.closed_at).length;

        const isClosed = !!error.closed_at;

        return <>
            <div key={`modal-error-view__error_title_${contract.id}`}>
                <span className={'modal-error-view__error_title'}>{contract.contragent}</span>
                {!!countErrors && <span
                    className={'modal-error-view__error_count'}>
                        {countErrors}
                    </span>}
                {!!countErrorsClosed && <span
                    className={'modal-error-view__error_count closed'}>
                        {countErrorsClosed}
                    </span>}
            </div>
            <div className={'modal-error-view__error_contract'}
                 key={`modal-error-view__error_contract_${contract.id}`}
            >
                <div
                    className={'modal-error-view__error_contract_title'}>
                    <span>{typeLabel}, №{contract.doc_number} </span>
                    <span>от {dateFormatter(contract.doc_date ?? '')}</span>
                </div>
                <span>{contract.size} {contract.line.cns_ed_izm}</span>
                <span> × {numberFormatter(contract.price)}</span>
                <span>   = {numberFormatter(contract.sum_wat)} </span>
            </div>
            <div key={`modal-error-view__error_contract_lines_${contract.id}`}>
                {lines.map(line => {



                    return <div
                        className={`modal-error-view__error_contract_line  ${isClosed ? 'closed' : ''}`}
                        key={`modal-error-view__error_contract_line_${line.id}`}
                    >
                        <div
                            key={`modal-error-view__error_contract_line_title_${line.id}`}> {line.title} </div>
                        <span className={hasErrorSize ? 'has_error' : ''}
                              key={`modal-error-view__error_contract_line_size_${line.id}`}
                        >{line.size} {line.ed_izm}</span>
                        <span className={hasErrorPrice ? 'has_error' : ''}
                              key={`modal-error-view__error_contract_line_price_${line.id}`}
                        >× {numberFormatter(line.price)}</span>
                        <span className={hasErrorSum ? 'has_error' : ''}
                              key={`modal-error-view__error_contract_line_sum_${line.id}`}
                        >= {numberFormatter(line.sum)}</span>

                    </div>;
                })}
                <Button size={'small'} type={'link'} className={'modal-error-view__error_download'}> <img
                    src={'/layout/download.svg'} alt={'Download'}/> {t(localeKeys.errors.lines.downloadDocuments)} </Button>
            </div>
        </>;
    }

    return <div key={`modal-error-view__error_${error?.id}`}></div>;

}

export default ModalErrorLines;
