import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, InputRef } from 'antd';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { addNomecklatura } from './modal_add_row';

const ModalManyLines: FunctionComponent<{ closeModalDots: () => void }> = (props) => {
    const [count, setCount] = useState(1);
    const ref = useRef<InputRef>(null);
    const [loading, setLoading] = useState(false);

    function doCreate() {
        addNomecklatura({ demoRow3: 0, manyRow3: Number(count) });
        setLoading(true);
    }

    (window as any).callbackAddMany = function () {
        setLoading(false);
        (document.querySelector('.ant-modal-close-x') as any)?.click();
    };

    useEffect(() => {
        ref.current?.focus();
    }, []);

    return (
        <>
            <div className={'line-counter'}>
                <Button size={'small'} onClick={() => (count > 1 ? setCount(count - 1) : null)} disabled={loading}>
                    <MinusOutlined />{' '}
                </Button>
                <Input
                    disabled={loading}
                    ref={ref}
                    autoFocus
                    value={count}
                    onChange={(e) => {
                        let number = Number(e.target.value ?? '1');
                        if (number < 1) number = 1;
                        if (Number.isNaN(number)) {
                            return;
                        }
                        setCount(Math.min(number, 300));
                    }}
                />
                <Button disabled={loading} size={'small'} onClick={() => (count < 300 ? setCount(count + 1) : null)}>
                    <PlusOutlined />{' '}
                </Button>
            </div>
            <Button type={'primary'} onClick={doCreate} disabled={loading}>
                Добавить
            </Button>
        </>
    );
};

export default ModalManyLines;
