import { LoadingOutlined } from '@ant-design/icons';
import { FunctionComponent, useState } from 'react';
import { getValueStore } from './store';
import {message} from "antd";

export async function doDownloadFile(file: string, name: string) {
    const res = await fetch(file, { headers: { Authorization: 'Bearer ' + getValueStore('token') } });

    if (res.status !== 200) {
        message.error('Не удалось скачать файл')
        return
    }

    const blob = await res.blob();
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    // @ts-ignore
    a.download = name;
    a.click();
    URL.revokeObjectURL(url);
}

export async function fetchFiles(file: string, name: string, postData: { ids: string[] } ) {
  const res = await fetch(file, {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + getValueStore('token'),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(postData),
  });

  const blob = await res.blob();
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = name;
  a.click();
  URL.revokeObjectURL(url);
}

const DownloadFile: FunctionComponent<{ file: string; name: string, title?: string, prefics?: any }> = ({ file, name, title, prefics }) => {
    const [loading, setLoading] = useState(false);

    async function load() {
        try {
            setLoading(true);
            await doDownloadFile(file, name);
        } catch (e) {
            console.log(e);
            message.error('Не удалось загрузить файл');
        }
        setLoading(false);

    }

    return (
        <div className={'DownloadFile'} onClick={load}>
            {prefics} {title ?? name} {loading && <LoadingOutlined />}
        </div>
    );
};

export default DownloadFile;
