import React, {FunctionComponent, useEffect, useState} from 'react';
import {localeKeys} from "../../i18n/localeKeys";
import {FilterOutlined} from "@ant-design/icons";
import LayoutComponent from "../../shared/layout_component";
import {useTranslation} from "react-i18next";
import {DirectoryTableGridRegion} from "../../shared/directory/DirectoryTableGridRegion";


const DirectoryRegions: FunctionComponent = () => {
    const {t} = useTranslation();
    return (
        <LayoutComponent title={t(localeKeys.directoryRegions.title)} className={`directory_list_screen`} onAction={() => {}}>
            <div className={'directory_list_wrap'}>
               <DirectoryTableGridRegion />
            </div>
        </LayoutComponent>
    );
};

export default DirectoryRegions;
