import tokenActions, { AccessItem } from '../../../actions/tokenActions';
import { RecordStatus, TableRecord } from '../../canvas_table/components/TableRecord';
import { getTableColumnKey } from '../getTableColumnKey';
import { getWData } from '../getWData';
import { getPassportCache } from '../loadData';

export function checkOpenModalWellBill(col: number, row: number, currentRow: TableRecord) {
  const columnKey = getTableColumnKey(col, getWData().body);
  const columns: (keyof TableRecord)[] = ['cns_well_bill_doc_number', 'cns_well_bill_percent', 'cns_well_bill_size', 'cns_well_bill_delivery_sum', 'cns_well_bill_delivery_date'];
  return (
    (getPassportCache().status == 'active' &&
      columns.includes(columnKey) &&
      (tokenActions.wellBill === AccessItem.EDIT || tokenActions.wellBill === AccessItem.VIEW) &&
      currentRow?.cns_row_type == 'nomenclature' &&
      currentRow?.cns_status === RecordStatus.BUDGET_PLAN_APPROVED) ??
    false
  );
}
