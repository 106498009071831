import { useQuery } from "react-query";
import { api } from "../shared/api_client";
import {ContractsProjectStatusType} from "./useContractsProject";
import {addDataToIndexedDB, getDataFromIndexedDB} from "../screens/contract_negotiation/db/db";
import { ContractsGroupedList} from "../ApiEpo";


export const useContractsList = ({ status, project_id, section_id, page, per_page, search }: { status?: ContractsProjectStatusType, project_id: string, section_id: string, page?: number, per_page?: number, search?: string }) => {
  const {
    data: contractsList,
    error: contractsListError,
    isLoading: contractsListLoading,
    refetch
  } = useQuery({
    queryKey: ['contractsList', status, search, project_id, section_id, page, per_page],
    queryFn: () => api.contracts.contractsList({
      status: status,
      project_id: project_id,
      section_id: section_id,
      page: page,
      per_page: per_page,
      search: search
    }).then( async (e) => {
      const response = e.data;
      if(response) await addDataToIndexedDB('contractsList', response.data);
      return response;
    }).catch(async (error: any): Promise<ContractsGroupedList> => {
      if (!navigator.onLine) {
        const offlineData = await getDataFromIndexedDB('contractsList');
        return { data: offlineData, meta: undefined };
      }
      if (error.response) {
        throw error;
      } else {
        throw error;
      }
    }),
    enabled: project_id !== '' && section_id !== '',
    keepPreviousData: true
  })

  return {
    refetch,
    contractsList,
    contractsListError,
    contractsListLoading,
  };
}
