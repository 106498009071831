import moment from 'moment';

export const DateFormat = 'MMM DD, yy';
export const DateFormatFull = 'MMM DD, yy HH:mm';


export function dateFormatter(date: string, props: { placeholder: string, format?: string } = { placeholder: '-' }) {
  // return `"${date}"`

  if (!date || !date.length) return props.placeholder;

  if (date.includes('.') || date.includes(',')) return date;

  if (date.includes('T')) {
    const moment1 = moment().locale('en');
    const [y, m, d] = date
      .split('T')[0]
      .split('-')
      .map((e) => Number(e));
    const [h, min, s] = date
      .replace('.000000Z', '')
      .split('T')[1]
      .split(':')
      .map((e) => Number(e));
    moment1.set({ date: d, month: m - 1, y: y, h: h, minute: min, second: s });
    /* 123 */
    return moment1.format(DateFormat);
  }

  if (date.includes(' ')) {
    const moment1 = moment();
    const [y, m, d] = date
      .split(' ')[0]
      .split('-')
      .map((e) => Number(e));
    const [h, min, s] = date
      .replace('.000000Z', '')
      .split(' ')[1]
      .split(':')
      .map((e) => Number(e));
    moment1.set({ date: d, month: m - 1, y: y, h: h, minute: min, second: s });

    return moment1.format(DateFormat);
  }

  return moment(date).format(props.format ?? DateFormat);
}

export function dateTimeFormatter(date: string) {
  if (!date) return '-';

  if (date.includes('T')) {
    const moment1 = moment();
    const [y, m, d] = date
      .split('T')[0]
      .split('-')
      .map((e) => Number(e));
    const [h, min, s] = date
      .replace('.000000Z', '')
      .split('T')[1]
      .split(':')
      .map((e) => Number(e));
    moment1.set({ date: d, month: m - 1, y: y, h: h, minute: min, second: s });

    return moment1.format(DateFormatFull);
  }

  return moment(date).format(DateFormatFull);
}

export default dateFormatter;

export const DateFormatter = {
  beforeMount(el: Node, binding: any, vnode: any) {
    el.textContent = dateFormatter(el.textContent ?? '');
  },
};

export function getDateISO(date: Date | string) {
  if (date.toString().includes('.')) {
    const [y, m, d] = date
      .toString()
      .split('T')[0]
      .split('-')
      .map((e) => Number(e));
    const moment1 = moment();
    moment1.set({ date: d, month: m - 1, y: y, h: 0, minute: 0, second: 0 });
    return moment1.toDate().toISOString();
  }

  if (typeof date == 'string') return date;

  const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  return new Date(date.getTime() - tzoffset).toISOString();
}

export function getDate2ISO(date: Date | string) {
  if (!date) return date;
  if (date.toString().includes('.')) {
    const [y, m, d] = date
      .toString()
      .split('T')[0]
      .split('-')
      .map((e) => Number(e));
    const moment1 = moment();
    moment1.set({ date: d, month: m - 1, y: y, h: 0, minute: 0, second: 0 });
    return moment1.toDate().toISOString().split('T')[0];
  }

  if (typeof date == 'string') return date.split('T')[0];

  const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  return new Date(date.getTime() - tzoffset).toISOString().split('T')[0];
}

export function getDateISOTZ(date: Date | string) {
  console.log({ date });
  if (date.toString().includes('.')) {
    const [y, m, d] = date
      .toString()
      .split('T')[0]
      .split('-')
      .map((e) => Number(e));
    const moment1 = moment();
    moment1.set({ date: d, month: m - 1, y: y, h: 0, minute: 0, second: 0 });
    return moment1.toDate().toISOString();
  }

  if (typeof date == 'string') return date;

  const [y, m, d] = [date.getFullYear(), date.getMonth(), date.getDate()];
  const moment1 = moment();
  moment1.set({ date: d, month: m, y: y, h: 0, minute: 0, second: 0 });

  const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  return new Date(moment1.toDate().getTime() - tzoffset).toISOString();
}

export function getDateISOTZ_Local(date: Date) {
  const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  return new Date(date.getTime() - tzoffset).toISOString().split('.')[0];
}

export function numberFormatter(
  price: number | string,
  extra: { isNol?: string; isOneDigit?: boolean; isTwoDigit?: boolean; suffix?: string; isInt?: boolean } = { isNol: '0', isOneDigit: false, isTwoDigit: false },
) {
  if (price === '' || price === undefined || price === null) return '';

  let price2 = Number.parseFloat((price ?? '').toString());

  if (price2 == 0) {
    return extra.isNol || '0';
  }

  if (Number.isNaN(price2)) {
    return extra.isNol || '0';
  }

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: extra.isOneDigit ? 1 : ( extra.isTwoDigit ? 2 : 3),
  });

  let res = USDollar.format(price2).replaceAll(',', ' ');

  if (extra.isInt) {
    res = res.split('.')[0];
  }

  return res + (extra.suffix ? extra.suffix : '');
}

export function getDateFromISO(date: string) {
  const [y, m, d] = date
    .toString()
    .split('T')[0]
    .split('-')
    .map((e) => Number(e));
  const moment1 = moment();
  moment1.set({ date: d, month: m - 1, y: y, h: 0, minute: 0, second: 0 });
  return moment1.toDate();
}

export function parseDate(date?: string) {
  if (!date) return '';

  if (date.includes('T') || date.includes('-')) {
    const [y, m, d] = date
      .toString()
      .split('T')[0]
      .split('-')
      .map((e) => Number(e));
    const moment1 = moment();
    moment1.set({ date: d, month: m - 1, y: y, h: 0, minute: 0, second: 0 });
    return moment1.format('YYYY-MM-DD');
  }

  if (date.includes(',')) {
    const m = moment;
    const moment1 = moment(date);
    moment1.set({ h: 0, minute: 0, second: 0 });
    return moment1.format('YYYY-MM-DD');
  }

  if (date.includes('.')) {
    const [d, m, y] = date
      .toString()
      .split('.')
      .map((e) => Number(e));
    const moment1 = moment();
    moment1.set({ date: d, month: m - 1, y: y, h: 0, minute: 0, second: 0 });
    return moment1.format('YYYY-MM-DD');
  }

  return ''
}
