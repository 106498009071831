import {useState} from "react";
import {db, doRunContragent, doRunDocuments, requestDatabase} from "../indexedb";

export function useDb() {
  const [isInit, setIsInit] = useState(false);
  const id = window.location.pathname.split('/passport/').pop();

  async function initDb() {
    if (db !== undefined) {
      return;
    }

    setIsInit(true);
    await requestDatabase(id ?? '');
    await doRunContragent()
    await doRunDocuments()
  }

  return {
    initDb,
    isInit,
  }
}
