import moment from 'moment';
import tokenActions, { AccessItem } from '../../actions/tokenActions';
import {RecordContractStatus, RecordStatus, TableRecord} from '../canvas_table/components/TableRecord';
import { allowEditNomeclatura } from './allowEditCell';
import { getWData } from './getWData';
import { themeTable } from './table_theme';
import {DateFormat} from "../../shared/dateFormat";

export function getBgCellBudgetSumWat(record: TableRecord) {
  if (record.cns_row_type == '') return themeTable.bgColor;

  if (record.cns_row_type == 'project') return themeTable.bgColor2;
  if (record.cns_row_type == 'section') return themeTable.bgColor;
  if (record.cns_row_type == 'work_type') return themeTable.bgColor2;
  if (record.cns_row_type == 'nomenclature') return themeTable.bgColor3;
}

export function getBgCellMainTitle2(record: TableRecord) {
  if (record.cns_row_type == '') return themeTable.transparent;
  if (record.cns_row_type === 'project') return themeTable.bgColor2;
  if (record.cns_row_type == 'section') return themeTable.bgColor;
  if (record.cns_row_type == 'work_type') return themeTable.bgColor2;
  if (record.cns_row_type == 'nomenclature')
    return tokenActions.budgetPlan == AccessItem.EDIT && allowEditNomeclatura(record)
      ? themeTable.bgColorEdit
      : themeTable.bgColor2;
}

export function getBgCellContracts(record: TableRecord) {
  if (
    record.cns_row_type == 'nomenclature' &&
    record.cns_status == RecordStatus.BUDGET_PLAN_APPROVED &&
    record.cns_contact_date &&
    tokenActions.contracts != AccessItem.NONE
  ) {
    if (Number(record.cns_budget_fakt_size ?? '0') >= Number(record.cns_budget_plan_size ?? '0')) {
      if (moment(record.cns_budget_fakt_last_change).isAfter(moment(record.cns_contact_date, DateFormat))) {
        return themeTable.bgColorWarning;
      }
      return themeTable.bgColorSuccess;
    }

    const dateVal = moment(record.cns_contact_date, DateFormat);
    if (
      dateVal <= moment() &&
      Number(record.cns_budget_fakt_size ?? '0') < Number(record.cns_budget_plan_size ?? '0')
    ) {
      return themeTable.bgColorError;
    }
    if (
      dateVal.add(-getWData().dedlainDays, 'days') < moment() &&
      Number(record.cns_budget_fakt_size ?? '0') < Number(record.cns_budget_plan_size ?? '0')
    ) {
      return moment().second() % 2 == 1 ? themeTable.bgColorError : themeTable.bgColorEdit;
    }
  }

  return undefined;
}


export function getBgCellContractsStatus(record: TableRecord) {
  return record.cns_contracts.length && record.cns_contracts.some((it)=>it.status !== RecordContractStatus.SIGNET);
}
