import { HeaderStyleOption } from 'cheetah-grid/ts-types';
import { RecordStatus, TableRecord } from '../../canvas_table/components/TableRecord';
import { getWData } from '../getWData';
import { getPassportCache } from '../loadData';
import { themeTable } from '../table_theme';

export function getColumnHeaderStyle(column: keyof TableRecord): HeaderStyleOption {
  let res: HeaderStyleOption = {
    bgColor: themeTable.bgColorBase,
    font: themeTable.fontHeader,
    textBaseline: 'top',
    padding: 8,
    color: themeTable.fontColor,
    borderColor: [
      themeTable.transparent,
      themeTable.borderColor,
      themeTable.borderColor,
      themeTable.transparent,
    ] as any,
  };

  // if (
  //   !getWData().rows3.find((e) => e.cns_status == RecordStatus.BUDGET_PLAN_APPROVED) &&
  //   [
  //     'cns_budget_fakt_size',
  //     'cns_budget_fakt_price',
  //     'cns_budget_fakt_sum_wat',
  //     'cns_budget_fakt_far',
  //     'cns_contact_date',
  //     'cns_contact_contragent',
  //     'cns_plane_date',
  //     'cns_plane_date_start',
  //     'cns_plane_date_end',
  //   ].includes(column)
  // ) {
  //   res.color = themeTable.disabledColumnColor;
  //   res.bgColor = themeTable.disabledColumnBg;
  //   res.borderColor = 'transparent';
  // }

  if (getWData().excludedColumns.includes(column)) {
    res.color = themeTable.disabledColumnColor;
    res.bgColor = themeTable.disabledColumnBg;
    res.borderColor = 'transparent';
  }

  return res;
}

export function getColumnHeaderStyle2(column: keyof TableRecord): HeaderStyleOption {
  let res: HeaderStyleOption = {
    bgColor: themeTable.bgColorBase,
    font: themeTable.fontHeader2Regular,
    textAlign: 'center',
    multiline: true,
    lineHeight: '18',
    textBaseline: 'top',
    padding: 8,
    borderColor: themeTable.borderColor,
  };

  // if (
  //   !getWData().rows3.find((e) => e.cns_status == RecordStatus.BUDGET_PLAN_APPROVED) &&
  //   [
  //     'cns_budget_fakt_size',
  //     'cns_budget_fakt_price',
  //     'cns_budget_fakt_sum_wat',
  //     'cns_budget_fakt_far',
  //     'cns_contact_date',
  //     'cns_contact_contragent',
  //     'cns_plane_date',
  //     'cns_plane_date_start',
  //     'cns_plane_date_end',
  //   ].includes(column)
  // ) {
  //   (res as any).color = themeTable.disabledColumnColor;
  //   (res as any).bgColor = themeTable.disabledColumnBg;
  //   (res as any).borderColor = 'transparent';
  // }

  if (getWData().excludedColumns.includes(column)) {
    (res as any).color = themeTable.disabledColumnColor;
    (res as any).bgColor = themeTable.disabledColumnBg;
    (res as any).borderColor = 'transparent';
  }

  return res;
}
